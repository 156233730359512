// import attachIcon from "images/icon-attach.svg";
// import playButtonIcon from "images/play-button-arrowhead.svg";
// import ReactPlayer from "react-player/lazy";
// import { getFileIcon, removeFileExtension } from "../Utils/General";
// import LazyImage from "../Utils/LazyImage/LazyImage";
// import styles from "./PostMedia.module.css";
// import { convertHEICToImageURL } from "components/Utils/convertHEICToImageURL";

// const PostMedia = ({
// 	post,
// 	mediaList,
// 	onItemClicked,
// 	chat = false,
// 	viewer = false,
// 	multipartViewer = false,
// 	childPostsCount = 0,
// 	inShareModal = false,
// 	isSharePost = false,
// }) => {
// 	const isDoc = (media) => {
// 		console.log("🚀 ~ media >>", media);
// 		if (
// 			media.previewType === "IMAGE" ||
// 			media.previewType === "VIDEO" ||
// 			media.mediaType === "IMAGE" ||
// 			media.mediaType === "VIDEO" ||
// 			media.type === "IMAGE" ||
// 			media.type === "VIDEO" ||
// 			media.type === "heic"
// 		) {
// 			return false;
// 		}

// 		return true;
// 	};

// 	const getImage = (media) => {
// 		let src;
// 		let fileData;
// 		if (chat) {
// 			src = media.customName;
// 			fileData = getFileIcon(media, media.originalName);
// 		} else {
// 			//! DRY !!! NEED TO BE REFACTORED >> EXACTLY THE SAME CODE.
// 			if (multipartViewer) {
// 				if (media.type !== "IMAGE" && media.type !== "VIDEO") {
// 					fileData = getFileIcon(
// 						media.multipartFile,
// 						media.multipartFile.name
// 					);
// 				} else {
// 					src = URL.createObjectURL(media.multipartFile);
// 				}
// 			} else {
// 				if (media.newFile) {
// 					//! ====== DRY >> ALREADY WRITTEN ABOVE ===========
// 					if (media.type !== "IMAGE" && media.type !== "VIDEO") {
// 						fileData = getFileIcon(
// 							media.multipartFile,
// 							media.multipartFile.name
// 						);
// 					} else {
// 						src = URL.createObjectURL(media.multipartFile);
// 					}
// 					//! ===============================================
// 				} else {
// 					// if (media.type === "heic") {
// 					// 	src = await convertHEICToImageURL(media.url);
// 					// } else {
// 					// 	src = media.url;
// 					// 	fileData = getFileIcon(media, media.originalName);
// 					// }
// 					src = media.url;
// 					fileData = getFileIcon(media, media.originalName);
// 				}
// 			}
// 		}

// 		return (
// 			<>
// 				{(media.previewType === "IMAGE" ||
// 					media.mediaType === "IMAGE" ||
// 					media.type === "IMAGE") && (
// 					<LazyImage
// 						image={<img src={src} alt="post media" />}
// 						inlineStyle={
// 							post?.postType === "PROFILE_PIC"
// 								? styles.profile_pic
// 								: undefined
// 						}
// 					/>
// 				)}
// 				{(media.previewType === "VIDEO" ||
// 					media.mediaType === "VIDEO" ||
// 					media.type === "VIDEO") && (
// 					<div className={styles.video_container}>
// 						<ReactPlayer
// 							width={"100%"}
// 							height={"100%"}
// 							url={src}
// 							playing={false}
// 						/>
// 						<div className={styles.video_play_icon}>
// 							<img
// 								className={styles.play_icon}
// 								src={playButtonIcon}
// 								alt={"play"}
// 							/>
// 						</div>
// 					</div>
// 				)}
// 				{isDoc(media) && (
// 					<img
// 						src={fileData.icon}
// 						alt={media.originalName}
// 						className={`${styles.file_icon} ${
// 							chat ? styles.chat : undefined
// 						}`}
// 					/>
// 				)}
// 			</>
// 		);
// 	};

// 	const displayFile = (file) => {
// 		if (isDoc(file)) {
// 			if (chat) {
// 				return (
// 					<div className={styles.file_container}>
// 						<div className={styles.icon_container}>
// 							{getImage(mediaList[0])}
// 						</div>
// 						<div className={styles.file_details}>
// 							<div className={styles.file_title}>
// 								{removeFileExtension(mediaList[0].originalName)}
// 							</div>
// 							<div className={styles.file_extension_container}>
// 								<img src={attachIcon} alt="attach icon" />
// 								<span>{mediaList[0].extension} file</span>
// 							</div>
// 						</div>
// 					</div>
// 				);
// 			} else {
// 				if (multipartViewer) {
// 					return (
// 						<div className={styles.doc_container}>
// 							{getImage(file)}
// 							<p>{file.multipartFile.name}</p>
// 						</div>
// 					);
// 				} else {
// 					return (
// 						<div className={styles.doc_container}>
// 							{getImage(file)}
// 							<p>{file.originalName}</p>
// 						</div>
// 					);
// 				}
// 			}
// 		} else {
// 			return getImage(file);
// 		}
// 	};

// 	const mediaArch = () => {
// 		return (
// 			<>
// 				{mediaList.length === 1 && (
// 					<div
// 						className={`${
// 							isDoc(mediaList[0])
// 								? styles.post_doc
// 								: styles.post_media
// 						} ${viewer ? styles.preview : undefined} ${
// 							chat ? styles.chat : undefined
// 						} ${inShareModal ? styles.inShareModal : undefined}`}
// 						onClick={() => onItemClicked && onItemClicked(0)}
// 					>
// 						{displayFile(mediaList[0])}
// 					</div>
// 				)}

// 				{mediaList.length === 2 && (
// 					<>
// 						<div
// 							className={`${styles.post_media_2} ${
// 								viewer ? styles.preview : undefined
// 							} ${
// 								inShareModal ? styles.inShareModal : undefined
// 							} ${isSharePost ? styles.isSharePost : undefined}`}
// 							onClick={() => onItemClicked && onItemClicked(0)}
// 						>
// 							{displayFile(mediaList[0])}
// 						</div>

// 						<div
// 							className={`${styles.post_media_2} ${
// 								viewer ? styles.preview : undefined
// 							} ${
// 								inShareModal ? styles.inShareModal : undefined
// 							} ${isSharePost ? styles.isSharePost : undefined}`}
// 							onClick={() => onItemClicked && onItemClicked(1)}
// 						>
// 							{displayFile(mediaList[1])}
// 						</div>
// 					</>
// 				)}

// 				{mediaList.length === 3 && (
// 					<>
// 						<div
// 							className={`${styles.post_media_3_1} ${
// 								viewer ? styles.preview : undefined
// 							} ${
// 								inShareModal ? styles.inShareModal : undefined
// 							} ${isSharePost ? styles.isSharePost : undefined}`}
// 							onClick={() => onItemClicked && onItemClicked(0)}
// 						>
// 							{displayFile(mediaList[0])}
// 						</div>

// 						<div
// 							className={`${styles.post_media_3_2} ${
// 								viewer ? styles.preview : undefined
// 							} ${
// 								inShareModal ? styles.inShareModal : undefined
// 							} ${isSharePost ? styles.isSharePost : undefined}`}
// 						>
// 							<div
// 								className={`${styles.post_media_3_2_1} ${
// 									viewer ? styles.preview : undefined
// 								} ${
// 									inShareModal
// 										? styles.inShareModal
// 										: undefined
// 								} ${
// 									isSharePost ? styles.isSharePost : undefined
// 								}`}
// 								onClick={() =>
// 									onItemClicked && onItemClicked(1)
// 								}
// 							>
// 								{displayFile(mediaList[1])}
// 							</div>
// 							<div
// 								className={`${styles.post_media_3_2_1} ${
// 									viewer ? styles.preview : undefined
// 								} ${
// 									inShareModal
// 										? styles.inShareModal
// 										: undefined
// 								} ${
// 									isSharePost ? styles.isSharePost : undefined
// 								}`}
// 								onClick={() =>
// 									onItemClicked && onItemClicked(2)
// 								}
// 							>
// 								{displayFile(mediaList[2])}
// 							</div>
// 						</div>
// 					</>
// 				)}

// 				{mediaList.length === 4 && (
// 					<div
// 						className={`${styles.post_media_4} ${
// 							viewer ? styles.preview : undefined
// 						} ${inShareModal ? styles.inShareModal : undefined} ${
// 							isSharePost ? styles.isSharePost : undefined
// 						}`}
// 					>
// 						<div
// 							className={`${styles.post_media_4_1} ${
// 								viewer ? styles.preview : undefined
// 							} ${
// 								inShareModal ? styles.inShareModal : undefined
// 							} ${isSharePost ? styles.isSharePost : undefined}`}
// 							onClick={() => onItemClicked && onItemClicked(0)}
// 						>
// 							{displayFile(mediaList[0])}
// 						</div>
// 						<div
// 							className={`${styles.post_media_4_1} ${
// 								viewer ? styles.preview : undefined
// 							} ${
// 								inShareModal ? styles.inShareModal : undefined
// 							} ${isSharePost ? styles.isSharePost : undefined}`}
// 							onClick={() => onItemClicked && onItemClicked(1)}
// 						>
// 							{displayFile(mediaList[1])}
// 						</div>
// 						<div
// 							className={`${styles.post_media_4_1} ${
// 								viewer ? styles.preview : undefined
// 							} ${
// 								inShareModal ? styles.inShareModal : undefined
// 							} ${isSharePost ? styles.isSharePost : undefined}`}
// 							onClick={() => onItemClicked && onItemClicked(2)}
// 						>
// 							{displayFile(mediaList[2])}
// 						</div>
// 						<div
// 							className={`${styles.post_media_4_1} ${
// 								viewer ? styles.preview : undefined
// 							} ${
// 								inShareModal ? styles.inShareModal : undefined
// 							} ${isSharePost ? styles.isSharePost : undefined}`}
// 							onClick={() => onItemClicked && onItemClicked(3)}
// 						>
// 							{displayFile(mediaList[3])}
// 						</div>
// 					</div>
// 				)}

// 				{mediaList.length >= 5 && (
// 					<div
// 						className={`${styles.post_media_5} ${
// 							viewer ? styles.preview : undefined
// 						} ${inShareModal ? styles.inShareModal : undefined} ${
// 							isSharePost ? styles.isSharePost : undefined
// 						}`}
// 					>
// 						<div
// 							className={`${styles.post_media_5_1} ${
// 								viewer ? styles.preview : undefined
// 							} ${
// 								inShareModal ? styles.inShareModal : undefined
// 							} ${isSharePost ? styles.isSharePost : undefined}`}
// 							onClick={() => onItemClicked && onItemClicked(0)}
// 						>
// 							{displayFile(mediaList[0])}
// 						</div>
// 						<div
// 							className={`${styles.post_media_5_1} ${
// 								viewer ? styles.preview : undefined
// 							} ${
// 								inShareModal ? styles.inShareModal : undefined
// 							} ${isSharePost ? styles.isSharePost : undefined}`}
// 							onClick={() => onItemClicked && onItemClicked(1)}
// 						>
// 							{displayFile(mediaList[1])}
// 						</div>
// 						<div
// 							className={`${styles.post_media_5_2} ${
// 								viewer ? styles.preview : undefined
// 							} ${styles.post_media_5_2_1} ${
// 								inShareModal ? styles.inShareModal : undefined
// 							} ${isSharePost ? styles.isSharePost : undefined}`}
// 							onClick={() => onItemClicked && onItemClicked(2)}
// 						>
// 							{displayFile(mediaList[2])}
// 						</div>
// 						<div
// 							className={`${styles.post_media_5_2} ${
// 								viewer ? styles.preview : undefined
// 							} ${styles.post_media_5_2_2} ${
// 								inShareModal ? styles.inShareModal : undefined
// 							} ${isSharePost ? styles.isSharePost : undefined}`}
// 							onClick={() => onItemClicked && onItemClicked(3)}
// 						>
// 							{displayFile(mediaList[3])}
// 						</div>
// 						<div
// 							className={`${styles.post_media_5_2} ${
// 								viewer ? styles.preview : undefined
// 							} ${styles.post_media_5_2_3} ${
// 								inShareModal ? styles.inShareModal : undefined
// 							} ${isSharePost ? styles.isSharePost : undefined}`}
// 							onClick={() => onItemClicked && onItemClicked(4)}
// 						>
// 							{displayFile(mediaList[4])}
// 							{(mediaList.length > 5 || childPostsCount > 5) && (
// 								<div
// 									className={`${styles.media_5_more} ${
// 										viewer ? styles.preview : undefined
// 									}`}
// 								>
// 									<div className={styles.shades} />
// 									<span>{`+${
// 										childPostsCount > 5
// 											? childPostsCount - 5
// 											: mediaList.length - 5
// 									}`}</span>
// 								</div>
// 							)}
// 						</div>
// 					</div>
// 				)}
// 			</>
// 		);
// 	};

// 	return (
// 		<div
// 			className={`${styles.post_media_container} ${
// 				viewer ? styles.preview : undefined
// 			} ${chat ? styles.chat : undefined}`}
// 		>
// 			{mediaArch()}
// 		</div>
// 	);
// };

// export default PostMedia;

import { useState, useEffect } from "react";
import attachIcon from "images/icon-attach.svg";
import playButtonIcon from "images/play-button-arrowhead.svg";
import ReactPlayer from "react-player/lazy";
import { getFileIcon, removeFileExtension } from "../Utils/General";
import LazyImage from "../Utils/LazyImage/LazyImage";
import styles from "./PostMedia.module.css";
import convertHEICToImageURL from "components/Utils/convertHEICToImageURL";

const PostMedia = ({
	post,
	mediaList,
	onItemClicked,
	chat = false,
	viewer = false,
	multipartViewer = false,
	childPostsCount = 0,
	inShareModal = false,
	isSharePost = false,
}) => {
	const [loadedMedia, setLoadedMedia] = useState([]);

	useEffect(() => {
		const loadMedia = async () => {
			const loadedMediaItems = await Promise.all(
				mediaList.map(async (media) => {
					let src;
					let fileData;
					if (chat) {
						src = media.customName;
						fileData = getFileIcon(media, media.originalName);
					} else {
						if (multipartViewer) {
							if (
								media.type !== "IMAGE" &&
								media.type !== "VIDEO"
							) {
								fileData = getFileIcon(
									media.multipartFile,
									media.multipartFile.name
								);
							} else {
								src = URL.createObjectURL(media.multipartFile);
							}
						} else {
							if (media.newFile) {
								if (
									media.type !== "IMAGE" &&
									media.type !== "VIDEO"
								) {
									fileData = getFileIcon(
										media.multipartFile,
										media.multipartFile.name
									);
								} else {
									src = URL.createObjectURL(
										media.multipartFile
									);
								}
							} else {
								if (media.type === "heic") {
									src = await convertHEICToImageURL(
										media.url
									);
								} else {
									src = media.url;
									fileData = getFileIcon(
										media,
										media.originalName
									);
								}
							}
						}
					}
					return { ...media, src, fileData };
				})
			);
			setLoadedMedia(loadedMediaItems);
		};

		loadMedia();
	}, [mediaList, chat, multipartViewer]);

	const isDoc = (media) => {
		if (
			media.previewType === "IMAGE" ||
			media.previewType === "VIDEO" ||
			media.mediaType === "IMAGE" ||
			media.mediaType === "VIDEO" ||
			media.type === "IMAGE" ||
			media.type === "VIDEO" ||
			media.type === "heic"
		) {
			return false;
		}
		return true;
	};

	const renderImage = (media) => (
		<>
			{(media.previewType === "IMAGE" ||
				media.mediaType === "IMAGE" ||
				media.type === "IMAGE" ||
				media.type === "heic") && (
				<LazyImage
					image={<img src={media.src} alt="post media" />}
					inlineStyle={
						post?.postType === "PROFILE_PIC"
							? styles.profile_pic
							: undefined
					}
				/>
			)}
			{(media.previewType === "VIDEO" ||
				media.mediaType === "VIDEO" ||
				media.type === "VIDEO") && (
				<div className={styles.video_container}>
					<ReactPlayer
						width={"100%"}
						height={"100%"}
						url={media.src}
						playing={false}
					/>
					<div className={styles.video_play_icon}>
						<img
							className={styles.play_icon}
							src={playButtonIcon}
							alt={"play"}
						/>
					</div>
				</div>
			)}
			{isDoc(media) && (
				<img
					src={media.fileData.icon}
					alt={media.originalName}
					className={`${styles.file_icon} ${
						chat ? styles.chat : undefined
					}`}
				/>
			)}
		</>
	);

	const displayFile = (file) => {
		if (isDoc(file)) {
			if (chat) {
				return (
					<div className={styles.file_container}>
						<div className={styles.icon_container}>
							{renderImage(loadedMedia[0])}
						</div>
						<div className={styles.file_details}>
							<div className={styles.file_title}>
								{removeFileExtension(
									loadedMedia[0].originalName
								)}
							</div>
							<div className={styles.file_extension_container}>
								<img src={attachIcon} alt="attach icon" />
								<span>{loadedMedia[0].extension} file</span>
							</div>
						</div>
					</div>
				);
			} else {
				if (multipartViewer) {
					return (
						<div className={styles.doc_container}>
							{renderImage(file)}
							<p>{file.multipartFile.name}</p>
						</div>
					);
				} else {
					return (
						<div className={styles.doc_container}>
							{renderImage(file)}
							<p>{file.originalName}</p>
						</div>
					);
				}
			}
		} else {
			return renderImage(file);
		}
	};

	const mediaArch = () => {
		return (
			<>
				{loadedMedia.length === 1 && (
					<div
						className={`${
							isDoc(loadedMedia[0])
								? styles.post_doc
								: styles.post_media
						} ${viewer ? styles.preview : undefined} ${
							chat ? styles.chat : undefined
						} ${inShareModal ? styles.inShareModal : undefined}`}
						onClick={() => onItemClicked && onItemClicked(0)}
					>
						{displayFile(loadedMedia[0])}
					</div>
				)}

				{loadedMedia.length === 2 && (
					<>
						<div
							className={`${styles.post_media_2} ${
								viewer ? styles.preview : undefined
							} ${
								inShareModal ? styles.inShareModal : undefined
							} ${isSharePost ? styles.isSharePost : undefined}`}
							onClick={() => onItemClicked && onItemClicked(0)}
						>
							{displayFile(loadedMedia[0])}
						</div>

						<div
							className={`${styles.post_media_2} ${
								viewer ? styles.preview : undefined
							} ${
								inShareModal ? styles.inShareModal : undefined
							} ${isSharePost ? styles.isSharePost : undefined}`}
							onClick={() => onItemClicked && onItemClicked(1)}
						>
							{displayFile(loadedMedia[1])}
						</div>
					</>
				)}

				{loadedMedia.length === 3 && (
					<>
						<div
							className={`${styles.post_media_3_1} ${
								viewer ? styles.preview : undefined
							} ${
								inShareModal ? styles.inShareModal : undefined
							} ${isSharePost ? styles.isSharePost : undefined}`}
							onClick={() => onItemClicked && onItemClicked(0)}
						>
							{displayFile(loadedMedia[0])}
						</div>

						<div
							className={`${styles.post_media_3_2} ${
								viewer ? styles.preview : undefined
							} ${
								inShareModal ? styles.inShareModal : undefined
							} ${isSharePost ? styles.isSharePost : undefined}`}
						>
							<div
								className={`${styles.post_media_3_2_1} ${
									viewer ? styles.preview : undefined
								} ${
									inShareModal
										? styles.inShareModal
										: undefined
								} ${
									isSharePost ? styles.isSharePost : undefined
								}`}
								onClick={() =>
									onItemClicked && onItemClicked(1)
								}
							>
								{displayFile(loadedMedia[1])}
							</div>
							<div
								className={`${styles.post_media_3_2_1} ${
									viewer ? styles.preview : undefined
								} ${
									inShareModal
										? styles.inShareModal
										: undefined
								} ${
									isSharePost ? styles.isSharePost : undefined
								}`}
								onClick={() =>
									onItemClicked && onItemClicked(2)
								}
							>
								{displayFile(loadedMedia[2])}
							</div>
						</div>
					</>
				)}

				{loadedMedia.length === 4 && (
					<div
						className={`${styles.post_media_4} ${
							viewer ? styles.preview : undefined
						} ${inShareModal ? styles.inShareModal : undefined} ${
							isSharePost ? styles.isSharePost : undefined
						}`}
					>
						<div
							className={`${styles.post_media_4_1} ${
								viewer ? styles.preview : undefined
							} ${
								inShareModal ? styles.inShareModal : undefined
							} ${isSharePost ? styles.isSharePost : undefined}`}
							onClick={() => onItemClicked && onItemClicked(0)}
						>
							{displayFile(loadedMedia[0])}
						</div>
						<div
							className={`${styles.post_media_4_1} ${
								viewer ? styles.preview : undefined
							} ${
								inShareModal ? styles.inShareModal : undefined
							} ${isSharePost ? styles.isSharePost : undefined}`}
							onClick={() => onItemClicked && onItemClicked(1)}
						>
							{displayFile(loadedMedia[1])}
						</div>
						<div
							className={`${styles.post_media_4_1} ${
								viewer ? styles.preview : undefined
							} ${
								inShareModal ? styles.inShareModal : undefined
							} ${isSharePost ? styles.isSharePost : undefined}`}
							onClick={() => onItemClicked && onItemClicked(2)}
						>
							{displayFile(loadedMedia[2])}
						</div>
						<div
							className={`${styles.post_media_4_1} ${
								viewer ? styles.preview : undefined
							} ${
								inShareModal ? styles.inShareModal : undefined
							} ${isSharePost ? styles.isSharePost : undefined}`}
							onClick={() => onItemClicked && onItemClicked(3)}
						>
							{displayFile(loadedMedia[3])}
						</div>
					</div>
				)}

				{loadedMedia.length >= 5 && (
					<div
						className={`${styles.post_media_5} ${
							viewer ? styles.preview : undefined
						} ${inShareModal ? styles.inShareModal : undefined} ${
							isSharePost ? styles.isSharePost : undefined
						}`}
					>
						<div
							className={`${styles.post_media_5_1} ${
								viewer ? styles.preview : undefined
							} ${
								inShareModal ? styles.inShareModal : undefined
							} ${isSharePost ? styles.isSharePost : undefined}`}
							onClick={() => onItemClicked && onItemClicked(0)}
						>
							{displayFile(loadedMedia[0])}
						</div>
						<div
							className={`${styles.post_media_5_1} ${
								viewer ? styles.preview : undefined
							} ${
								inShareModal ? styles.inShareModal : undefined
							} ${isSharePost ? styles.isSharePost : undefined}`}
							onClick={() => onItemClicked && onItemClicked(1)}
						>
							{displayFile(loadedMedia[1])}
						</div>
						<div
							className={`${styles.post_media_5_2} ${
								viewer ? styles.preview : undefined
							} ${styles.post_media_5_2_1} ${
								inShareModal ? styles.inShareModal : undefined
							} ${isSharePost ? styles.isSharePost : undefined}`}
							onClick={() => onItemClicked && onItemClicked(2)}
						>
							{displayFile(loadedMedia[2])}
						</div>
						<div
							className={`${styles.post_media_5_2} ${
								viewer ? styles.preview : undefined
							} ${styles.post_media_5_2_2} ${
								inShareModal ? styles.inShareModal : undefined
							} ${isSharePost ? styles.isSharePost : undefined}`}
							onClick={() => onItemClicked && onItemClicked(3)}
						>
							{displayFile(loadedMedia[3])}
						</div>
						<div
							className={`${styles.post_media_5_2} ${
								viewer ? styles.preview : undefined
							} ${styles.post_media_5_2_3} ${
								inShareModal ? styles.inShareModal : undefined
							} ${isSharePost ? styles.isSharePost : undefined}`}
							onClick={() => onItemClicked && onItemClicked(4)}
						>
							{displayFile(loadedMedia[4])}
							{(loadedMedia.length > 5 ||
								childPostsCount > 5) && (
								<div
									className={`${styles.media_5_more} ${
										viewer ? styles.preview : undefined
									}`}
								>
									<div className={styles.shades} />
									<span>{`+${
										childPostsCount > 5
											? childPostsCount - 5
											: loadedMedia.length - 5
									}`}</span>
								</div>
							)}
						</div>
					</div>
				)}
			</>
		);
	};

	return (
		<div
			className={`${styles.post_media_container} ${
				viewer ? styles.preview : undefined
			} ${chat ? styles.chat : undefined}`}
		>
			{loadedMedia.length === mediaList.length && mediaArch()}
		</div>
	);
};

export default PostMedia;
