export const peopleInitialFilters = {
	// cityIds: [],
	friends: false,
	inMyCircle: false,
	inTheirCircle: false,
	languages: [],
	// latitude: 0,
	// longitude: 0,
	locationIdForSort: 0,
	roles: [],
	skills: [],
	software: [],
};

export const companiesInitialFilters = {
	// cityIds: [],
	inMyCircle: false,
	inTheirCircle: false,
	locationIdForSort: 0,
	agency: false,
	industryIds: [],
	specialties: [],
	// latitude: 0,
	// longitude: 0,
};

//posts and gallery share same filters
export const postsInitialFilters = {
	// cityIds: [],
	friends: false,
	inMyCircle: false,
	inTheirCircle: false,
	locationIdForSort: 0,
	includeMedia: false,
	// latitude: 0,
	// longitude: 0,
	// fromDate: 0,
	// toDate: 0,
};

export const jobsInitialFilters = {
	locationId: 0,
	jobType: 0,
	// title: "",
	q: "", //replacing 'title'
	time: "NONE",
	skillIds: [],
	frequency: "",
	castingCallRequest: {
		date: null,
		ethnicityId: "",
		gender: 2,
		languageIds: [],
		maxAge: "",
		minAge: "",
	},
	compensation: "",
	currencyId: "",
	freelanceRequest: {
		endDate: null,
		experience: "",
		showBasedOnCalendar: false,
		startDate: null,
		workingLocation: "OFFICE",
	},
	jobRequest: {
		experience: "",
		longTermJobTypeId: [],
		workingLocation: "OFFICE",
	},
};

export const productsInitialFilters = {
	colors: [],
	friends: false,
	inMyCircle: false,
	inTheirCircle: false,
	locationIds: [],
	materials: [],
	maxHeight: "",
	maxLength: "",
	maxWeight: "",
	maxWidth: "",
	minHeight: "",
	minLength: "",
	minWeight: "",
	minWidth: "",
	onlyUncategorizedProducts: false,
	ownerIds: [],
	serviceTags: [],
	widthUnit: "METER",
	heightUnit: "METER",
	lengthUnit: "METER",
	weightUnit: "GRAM",
};

export const servicesInitialFilters = {
	gaawkCategories: [],
	friends: false,
	inMyCircle: false,
	inTheirCircle: false,
	includeMedia: false,
	includeProducts: false,
	locationIds: [],
	q: "",
};

export const agenciesInitialFilters = {
	locationIds: [],
	types: [],
};
