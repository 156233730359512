import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CompanyItem from "../Companies/CompanyItem";
import { components } from "react-select";
import PrimaryButton from "../Utils/Button/PrimaryButton";
import SecondaryButton from "../Utils/Button/SecondaryButton";
import ProfilePic from "../Home/ProfilePic";
import KeyPeopleItem from "../Profile/ProfileEdit/KeyPeople/KeyPeopleItem";
import styles from "./CompanyManage.module.css";
import profileApi from "../../api/profile";
import useApi from "../../hooks/useApi";

import {
	peopleFormatter,
	unduplicateIds,
} from "components/Utils/SubComs/Inputs/SearchableInput/response-formatter";
import InfiniteSearchInput from "components/Utils/SubComs/Inputs/InfiniteSearchInput/InfiniteSearchInput";
import { profileKeys } from "queryKeys/profile-key-factory";
import useSearchUsers from "hooks/useSearchUsers";

const CompanyTransfer = ({ state }) => {
	const navigate = useNavigate();
	const transferCompanyApi = useApi(profileApi.transferCompany);

	const { uuid: profileId } = useSelector((state) => {
		return state.user.profileInfo;
	});

	const companyProfile = state;

	const [confirmPrompt, setConfirmPrompt] = useState(false);
	const [selectedUser, setSelectedUser] = useState(undefined);

	const handleConfirmNewOwner = () => {
		transferCompanyApi.request(companyProfile.uuid, selectedUser.value);
	};

	useEffect(() => {
		if (
			!transferCompanyApi.loading &&
			transferCompanyApi.responseCode === 200
		) {
			navigate("/company", { replace: true });
		}
	}, [navigate, transferCompanyApi.loading, transferCompanyApi.responseCode]);

	//* ====== FETCH USERS =====

	const fetchUsers = useSearchUsers();

	//* ========================

	return (
		<div className={styles.container}>
			<div className={styles.company_item}>
				<CompanyItem
					item={companyProfile}
					subdata={`${companyProfile.locationDTO.name}, ${companyProfile.locationDTO.countryDTO.name}`}
				/>
			</div>

			{confirmPrompt ? (
				<div className={styles.confirm_prompt_container}>
					<KeyPeopleItem
						title={selectedUser.label}
						subtitle={selectedUser.tagLine}
						image={selectedUser?.profileImage?.image}
					/>
					<div className={styles.fullwidth_info}>
						As a company can have only one owner on Gaawk, this
						person will be the new owner of the company. You will no
						longer have the owner’s privilege of this company.
					</div>
					<PrimaryButton
						text={"confirm transfer"}
						className={styles.transfer_btn}
						onClick={handleConfirmNewOwner}
					/>
					<SecondaryButton
						text={"cancel"}
						className={styles.cancel_transfer_btn}
						onClick={() => setConfirmPrompt(false)}
					/>
				</div>
			) : (
				<div className={styles.transfer_section}>
					<p className={styles.owner_info}>
						You are the owner of this company on Gaawk.
						<br />
						Please select a person you’d like to be the new owner of
						this company. There can be only one owner for each
						company.
					</p>

					<InfiniteSearchInput
						label={"Select new owner"}
						required={true}
						queryName={profileKeys.search}
						onChange={(user) => {
							setSelectedUser(user);
							setConfirmPrompt(true);
						}}
						unDuplicateFn={(passedData) =>
							unduplicateIds(profileId, passedData, "value")
						}
						queryFn={fetchUsers}
						itemsPerPage={10}
						formatter={peopleFormatter}
						option={{
							Option: (props) => {
								const user = props.data;

								return (
									<components.Option {...props}>
										<div className={styles.member_item}>
											<ProfilePic
												thumbnail={
													user?.profileImage?.image
												}
												w={36}
												h={36}
												type={user.type}
												name={user.label}
												createMode={true}
												subdata={user.tagLine}
											/>
										</div>
									</components.Option>
								);
							},
						}}
					/>
				</div>
			)}
		</div>
	);
};

export default CompanyTransfer;
