import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HomeIcon from "images/navbar-icons/homepage.svg";
import HomeIconActive from "images/navbar-icons/homepage-active.svg";
import ChatIcon from "images/navbar-icons/chat.svg";
import ChatIconActive from "images/navbar-icons/chat-active.svg";
import SearchIcon from "images/navbar-icons/search.svg";
import SearchIconActive from "images/navbar-icons/search-active.svg";
import MoreIcon from "images/navbar-icons/more.svg";
import MoreIconActive from "images/navbar-icons/more-active.svg";
import "./bottomNavBar.css";
import { useSelector } from "react-redux";
// import LazyImage from "../../LazyImage/LazyImage";
import ProfilePic from "../../../Home/ProfilePic";
// import useCompanyId from "../../../../hooks/useCompanyId";
import useWindowSize from "../../../../hooks/useWindowSize";
import useCurrentUser from "hooks/useCurrentUser";

const BottomNavBar = ({ fluid = false, inConvo = false }) => {
	const navigate = useNavigate();
	// const companyId = useCompanyId();
	const { pathname } = useLocation();

	// >> bottom navbar component is always there on any page

	// AS OPPOSED TO FETCHING ONLY when on /profile route

	const [chatCount, setChatCount] = useState(0);

	const { width } = useWindowSize();
	const isMobile = width < 748;

	const chatTotalCount = useSelector((state) => state.ui.chatTotalCount);

	const isHome = pathname === "/";
	const isChat = pathname === "/chat";
	const isSearch = pathname === "/search";
	const isMore = pathname === "/more";

	const currentUser = useCurrentUser();

	const thumbnail = currentUser?.profileImage?.framedImageURL
		? currentUser.profileImage.framedImageURL
		: currentUser?.profileImage?.originalImageURL
		? currentUser.profileImage.originalImageURL
		: undefined;

	const fixedMenuClass = `search-bottom-bar-60000 ${fluid ? "fluid" : ""} ${
		isMobile && inConvo ? "hide" : ""
		// isMobile && !showConvoList && inConvo ? "hide" : ""
	}`;

	useEffect(() => {
		if (chatTotalCount > 0) setChatCount(chatTotalCount);
	}, [chatTotalCount]);

	return (
		<div className={fluid ? "bottom_wrapper" : ""}>
			<div className={fixedMenuClass}>
				<div className="search-bottom-bar-wrapper-60000">
					<button onClick={() => navigate("/")}>
						<img
							src={isHome ? HomeIconActive : HomeIcon}
							alt="Home"
						/>
					</button>

					<button onClick={() => navigate("/chat")}>
						<img
							src={isChat ? ChatIconActive : ChatIcon}
							alt="chat"
						/>
						{chatCount > 0 && (
							<span className="badge">
								<label>
									{chatCount > 9 ? `+9` : chatCount}
								</label>
							</span>
						)}
					</button>

					<button onClick={() => navigate("/search")}>
						<img
							src={isSearch ? SearchIconActive : SearchIcon}
							alt="Search"
						/>
					</button>

					<button onClick={() => navigate("/more")}>
						<img
							src={isMore ? MoreIconActive : MoreIcon}
							alt="More"
						/>
					</button>

					<button
						className={`profile ${
							currentUser.type === "COMPANY"
								? "company"
								: undefined
						}`}
						onClick={() => navigate("/profile")}
					>
						<ProfilePic
							w={26}
							h={26}
							type={currentUser.type}
							thumbnail={thumbnail}
							name={currentUser.firstName || currentUser.name}
							enableName={false}
							noMarginRight={true}
						/>
						{/* {thumbnail && <LazyImage image={<img src={thumbnail} alt="profile" />} inlineStyle={"profile"} />} */}
					</button>
				</div>
			</div>
		</div>
	);
};

export default BottomNavBar;
