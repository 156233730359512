import { useRef } from "react";

import Options from "images/three-dots-black-5-x-20.svg";
import OptionsWhite from "images/three-dots-white-5-x-20.svg";

// import bookmark from "images/bookmark-outline.svg";
import deleteIcon from "images/delete-icon-black-20-x-20.svg";
import downloadIcon from "images/download-black.svg";
import editIcon from "images/edit-icon-20-x-20-black.svg";
import linkIcon from "images/link.svg";
import shareIcon from "images/share-option.svg";
// import invisibleIcon from "images/invisible.svg";
// import infoIcon from "images/information.svg";
// import warningIcon from "images/exclamation-mark.svg";
import reportIcon from "images/report.svg";

// import followIcon from "images/follow-icon.svg";
import Popover from "../Utils/Popover/Popover";
import styles from "./PostActions.module.scss";
import { useNavigate } from "react-router-dom";
import routes from "components/Routing/routing-keys";
// import "react-day-picker/dist/style.css";

const PostActions = ({
	whiteButton = false,
	isOwner,
	isPublic,
	onEdit,
	onDelete,
	onCopy,
	onShare,
	onReport,
	postType,
	onDownload,
	isFullScreen = false,
}) => {
	const popoverRef = useRef(null);
	const navigate = useNavigate();

	return (
		<Popover
			allow={["left"]}
			// crossAxis={15}
			ref={popoverRef}
			render={
				<div className="popover_container">
					{isOwner && (
						<button
							className="popover_item"
							tabIndex={0}
							onClick={(e) => {
								e.stopPropagation();
								postType !== "PROFILE_PIC" &&
								postType !== "COVER_PIC"
									? onEdit()
									: navigate(
											routes.profile(
												postType === "PROFILE_PIC"
													? "profilePic"
													: "coverPic"
											)
									  );
								popoverRef.current.closePopover();
							}}
						>
							<span>
								<img src={editIcon} alt={"edit"} />
							</span>
							<span>
								Edit{" "}
								{postType === "PROFILE_PIC"
									? "profile picture"
									: postType === "COVER_PIC"
									? "cover picture"
									: "post"}
							</span>
						</button>
					)}

					{onDownload && (
						<button
							tabIndex={0}
							className="popover_item"
							onClick={(e) => {
								e.stopPropagation();
								onDownload();
								popoverRef.current.closePopover();
							}}
						>
							<span>
								<img src={downloadIcon} alt={"download"} />
							</span>
							<span>Download</span>
						</button>
					)}

					<button
						className="popover_item"
						tabIndex={0}
						onClick={(e) => {
							e.stopPropagation();
							onCopy();
							popoverRef.current.closePopover();
						}}
					>
						<span>
							<img src={linkIcon} alt={"link"} />
						</span>
						<span>Copy link</span>
					</button>

					{isPublic && (
						<button
							className="popover_item"
							tabIndex={0}
							onClick={(e) => {
								e.stopPropagation();
								onShare();
								popoverRef.current.closePopover();
							}}
						>
							<span>
								<img src={shareIcon} alt={"share"} />
							</span>
							<span>Repost</span>
						</button>
					)}

					{!isOwner && (
						<button
							className="popover_item"
							tabIndex={0}
							onClick={(e) => {
								e.stopPropagation();
								onReport();
								popoverRef.current.closePopover();
							}}
						>
							<span>
								<img src={reportIcon} alt={"report"} />
							</span>
							<span>Report post</span>
						</button>
					)}

					{isOwner && (
						<button
							className="popover_item"
							tabIndex={0}
							onClick={(e) => {
								e.stopPropagation();
								onDelete();
								popoverRef.current.closePopover();
							}}
						>
							<>
								<span>
									<img src={deleteIcon} alt={"delete"} />
								</span>
								<span>
									{postType !== "PROFILE_PIC" &&
									postType !== "COVER_PIC"
										? "Delete"
										: "Remove post from feed"}
								</span>
							</>
						</button>
					)}
				</div>
			}
		>
			<button
				className={styles.option_btn}
				onClick={(e) => e.stopPropagation()}
			>
				<img src={whiteButton ? OptionsWhite : Options} alt="options" />
			</button>
		</Popover>
	);
};

export default PostActions;
