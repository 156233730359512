import { router } from "AppRoutes";
import routes from "components/Routing/routing-keys";
import useDirectMessage from "hooks/useDirectMessage";
import { useDispatch } from "react-redux";
import { updateProfileTab } from "store/slices/activeTab";
import { updatePostModal } from "store/slices/ui";

const useSpecialAction = () => {
	const dispatch = useDispatch();
	const { handleDirectMessage } = useDirectMessage();

	const handleSpecialAction = (notification) => {
		const { from, payload, action, url } = notification;

		const parsedPayload = JSON.parse(payload);

		switch (true) {
			case action === "ADDED_TO_GROUP" ||
				action === "MADE_ADMIN" ||
				action === "MESSAGE_SENT":
				handleDirectMessage({
					...from,
					conversationId: parsedPayload.conversationId,
				});

				break;

			case action === "START_CREATING_POST":
				dispatch(updatePostModal(true));
				router.navigate(url);
				break;

			case action === "COMPANY_NEW_EXPERIENCE_REQUEST":
				dispatch(updateProfileTab("profile"));
				router.navigate(`${url}?modal=true`);
				break;

			case action === "TALENT_REQUEST_RAISED":
				dispatch(updateProfileTab("talent"));
				router.navigate(routes.profile(`?modal=true`));
				break;

			default:
				break;
		}

		// if (
		// 	action === "ADDED_TO_GROUP" ||
		// 	action === "MADE_ADMIN" ||
		// 	action === "MESSAGE_SENT"
		// ) {
		// 	handleDirectMessage({
		// 		...from,
		// 		conversationId: parsedPayload.conversationId,
		// 	});
		// } else {
		// 	if (action === "START_CREATING_POST") {
		// 		dispatch(updatePostModal(true));
		// 	}

		// 	if (action === "COMPANY_NEW_EXPERIENCE_REQUEST")
		// 		dispatch(updateProfileTab("profile"));

		// 	if (action === "TALENT_REQUEST_RAISED")
		// 		dispatch(updateProfileTab("talents"));

		// 	router.navigate(
		// 		`${url}${
		// 			action === "COMPANY_NEW_EXPERIENCE_REQUEST" ||
		// 			action === "TALENT_REQUEST_RAISED"
		// 				? `?modal=true`
		// 				: ""
		// 		}`
		// 	);
		// }
	};

	return handleSpecialAction;
};

export default useSpecialAction;
