import { joiResolver } from "@hookform/resolvers/joi";
import { useQueryClient } from "@tanstack/react-query";
import routes from "components/Routing/routing-keys";
import PromptModal from "components/Utils/Prompt/PromptModal";
import useMutate from "hooks/useMutate";
import { specialProfileKeys } from "queryKeys/specialProfile-key-factory";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import recordingApi from "../../../../../../api/recording-type";
import specialProfileApi from "../../../../../../api/special-profile";
import voiceApi from "../../../../../../api/voice";
import useApi from "../../../../../../hooks/useApi";
import PrimaryButton from "../../../../../Utils/Button/PrimaryButton";
import CustomSelect from "../../../../../Utils/SubComs/CustomSelect/CustomSelect";
import { defaultFormatter } from "../../../../../Utils/SubComs/Inputs/SearchableInput/response-formatter";
import Tag from "../../../../../Utils/SubComs/Tags/Tag";
import TagContainer from "../../../../../Utils/SubComs/Tags/TagContainer";
import {
	pitch as pitchType,
	vocalAge as vocalAgeType,
	vocalRange as vocalRangeType,
} from "../../special-types";
import vocalDetailsSchema from "./vocal-details-schema";
import styles from "./VocalDetailsEntry.module.css";

const VocalDetailsEntry = ({ profile }) => {
	const navigate = useNavigate();

	if (
		profile.type !== "SINGER_SONGWRITER" &&
		profile.type !== "VOICE_OVER_ARTIST"
	) {
		navigate(-1);
	}

	const { state } = useLocation();

	// const updateVocalDetailsApi = useApi(specialProfileApi.updateVocalDetails);
	const getVoiceCharacteristicsApi = useApi(voiceApi.getVoiceCharacteristics);
	const getRecordingTypesApi = useApi(recordingApi.getRecordingTypes);
	const queryClient = useQueryClient();
	const { userUrl } = useParams();
	const [shouldBlockSave, setShouldBlockSave] = useState(true);

	const {
		pitch,
		recordingTypes,
		vocalAge,
		vocalRange,
		voiceCharacteristics,
	} = profile.vocalDetails || {};

	const [recordingItems, setRecordingItems] = useState([]);
	const [voiceItems, setVoiceItems] = useState([]);

	useEffect(() => {
		getVoiceCharacteristicsApi.request();
		getRecordingTypesApi.request();
	}, []);

	useEffect(() => {
		if (
			!getVoiceCharacteristicsApi.loading &&
			getVoiceCharacteristicsApi.responseCode === 200 &&
			getVoiceCharacteristicsApi.data.length > 0
		) {
			const response = getVoiceCharacteristicsApi.data;
			setVoiceItems(response.map((item) => defaultFormatter(item)));
		}
	}, [getVoiceCharacteristicsApi.data]);

	useEffect(() => {
		if (
			!getRecordingTypesApi.loading &&
			getRecordingTypesApi.responseCode === 200 &&
			getRecordingTypesApi.data.length > 0
		) {
			const response = getRecordingTypesApi.data;
			setRecordingItems(response.map((item) => defaultFormatter(item)));
		}
	}, [getRecordingTypesApi.data]);

	const {
		formState: { errors, isDirty },
		handleSubmit,
		control,
		watch,
		setValue,
	} = useForm({
		resolver: joiResolver(vocalDetailsSchema),
		mode: "onSubmit",
		defaultValues: {
			recordingType: recordingTypes
				? recordingTypes.map((item) => ({
						label: item.name,
						value: item.uuid,
				  }))
				: [],
			voiceCharacteristics: voiceCharacteristics
				? voiceCharacteristics.map((item) => ({
						label: item.name,
						value: item.uuid,
				  }))
				: [],
			vocalAge: vocalAge
				? vocalAgeType.find((item) => item.value === vocalAge)
				: null,
			pitch: pitch
				? pitchType.find((item) => item.value === pitch)
				: null,
			vocalRange: vocalRange
				? vocalRangeType.find((item) => item.value === vocalRange)
				: null,
		},
	});

	// !=================== RECORDING TYPE ======================

	const recordingWatcher = watch("recordingType");

	const selectedRecordList = useMemo(
		() =>
			recordingWatcher.map((record, index) => (
				<Tag
					key={record.value}
					itemName={record.label}
					onRemove={() => handleRemoveRecord(index)}
				/>
			)),
		[recordingWatcher]
	);

	const handleSelectRecord = (record) => {
		setValue("recordingType", [...recordingWatcher, record], {
			shouldDirty: true,
		});
	};

	const handleRemoveRecord = (recordIndex) => {
		const updatedRecords = [...recordingWatcher].filter(
			(_, index) => index !== recordIndex
		);
		setValue("recordingType", updatedRecords, { shouldDirty: true });
	};

	// !=================== VOICE CHARACTERISTICS ======================

	const voiceWatcher = watch("voiceCharacteristics");

	const selectedVoiceList = useMemo(
		() =>
			voiceWatcher.map((voice, index) => (
				<Tag
					key={voice.value}
					itemName={voice.label}
					onRemove={() => handleRemoveVoice(index)}
				/>
			)),
		[voiceWatcher]
	);

	const handleSelectVoice = (voice) => {
		setValue("voiceCharacteristics", [...voiceWatcher, voice], {
			shouldDirty: true,
		});
	};

	const handleRemoveVoice = (voiceIndex) => {
		const updatedVoice = [...voiceWatcher].filter(
			(_, index) => index !== voiceIndex
		);
		setValue("voiceCharacteristics", updatedVoice, { shouldDirty: true });
	};

	// !=========================================

	const {
		action: { mutate: updateDetails, isLoading },
	} = useMutate(
		specialProfileApi.updateVocalDetails,
		() => {
			queryClient.invalidateQueries(specialProfileKeys.detail(userUrl));
			navigate(
				routes.specialProfile(
					userUrl,
					state?.agencyId ? `?agency=${state?.agencyId}` : ""
				)
			);
		},
		undefined,
		() => setShouldBlockSave(true)
	);

	const handleSave = (data) => {
		setShouldBlockSave(false);

		const {
			recordingType,
			voiceCharacteristics,
			vocalAge,
			pitch,
			vocalRange,
		} = data;

		const body = {
			vocalAge: vocalAge ? vocalAge.value : "N_A",
			pitch: pitch ? pitch.value : "N_A",
			vocalRange: vocalRange ? vocalRange.value : "N_A",
			recordingIds: recordingType.map((item) => item.value),
			voiceCharacteristicIds: voiceCharacteristics.map(
				(item) => item.value
			),
		};

		// updateVocalDetailsApi.request(profile.uuid, body);
		updateDetails({ uuid: profile.uuid, body });
	};

	// useEffect(() => {
	// 	if (
	// 		!updateVocalDetailsApi.loading &&
	// 		updateVocalDetailsApi.responseCode === 200 &&
	// 		updateVocalDetailsApi.data
	// 	) {
	// 		setShouldBlockSave(false);
	// 		queryClient.invalidateQueries(specialProfileKeys.detail(userUrl));
	// 	}
	// }, [updateVocalDetailsApi.data]);

	return (
		<form
			className={styles.vocal_form}
			onSubmit={handleSubmit((data) => handleSave(data))}
			noValidate
		>
			<h3>Vocal Details</h3>

			<div className={styles.form_input_wrapper}>
				<label>Recording Types</label>

				<CustomSelect
					value={""}
					options={recordingItems.filter(
						(record) =>
							!recordingWatcher
								.map((item) => item.value)
								.includes(record.value)
					)}
					height="35px"
					onChange={handleSelectRecord}
				/>
			</div>

			{recordingWatcher.length > 0 && (
				<TagContainer
					label="Selected Recording Types"
					items={selectedRecordList}
					customStyle={styles.tag_container}
				/>
			)}

			<div className={styles.form_input_wrapper}>
				<label>Voice Characteristics</label>

				<CustomSelect
					value={""}
					options={voiceItems.filter(
						(item) =>
							!voiceWatcher
								.map((item) => item.value)
								.includes(item.value)
					)}
					height="35px"
					onChange={handleSelectVoice}
				/>
			</div>

			{voiceWatcher.length > 0 && (
				<TagContainer
					label="Selected Voice Characteristics"
					items={selectedVoiceList}
					customStyle={styles.tag_container}
				/>
			)}

			<div className={styles.form_input_wrapper}>
				<label>Vocal Age</label>
				<CustomSelect
					options={vocalAgeType}
					height="35px"
					control={control}
					name="vocalAge"
					isClearable={true}
				/>
			</div>
			<div className={styles.form_input_wrapper}>
				<label>Pitch</label>
				<CustomSelect
					options={pitchType}
					height="35px"
					control={control}
					name="pitch"
					isClearable={true}
				/>
			</div>
			<div className={styles.form_input_wrapper}>
				<label>Vocal Range</label>
				<CustomSelect
					options={vocalRangeType}
					height="35px"
					control={control}
					name="vocalRange"
					isClearable={true}
				/>
			</div>

			<div className={styles.button_container}>
				<PrimaryButton
					className={styles.save_btn}
					text={"save"}
					isLoading={isLoading}
				/>
			</div>

			<PromptModal when={isDirty && shouldBlockSave} />
		</form>
	);
};

export default VocalDetailsEntry;
