import { useQueryClient } from "@tanstack/react-query";
import projectApi from "api/project";
import CompanyItem from "components/Companies/CompanyItem";
import routes from "components/Routing/routing-keys";
import BackButton from "components/Utils/Button/BackButton";
import EditButton from "components/Utils/Button/EditButton";
import { companySizeList, featuresSection } from "components/Utils/General";
import LazyImage from "components/Utils/LazyImage/LazyImage";
import DefaultPage from "components/Utils/PageArchs/DefaultPage/DefaultPage";
import FeaturesSidebar from "components/Utils/PageArchs/FeaturesSidebar/FeaturesSidebar";
import LoadingPage from "components/Utils/SubComs/CustomLoader/LoadingPage";
import TabMenu from "components/Utils/SubComs/TabMenu/TabMenu";
import Tag from "components/Utils/SubComs/Tags/Tag";
import { format } from "date-fns";
import useCurrentUser from "hooks/useCurrentUser";
import useMutate from "hooks/useMutate";
import { ReactComponent as ImageIcon } from "images/gallery.svg";
import { projectKeys } from "queryKeys/projects-key-factory";
import { useEffect, useMemo } from "react";
import {
	useLocation,
	useNavigate,
	useParams,
	useSearchParams,
} from "react-router-dom";
import CollapsibleJobSection from "../EmployerLanding/CollapsibleSection/CollapsibleJobSection";
import useProject from "../EmployerLanding/hooks/useProject";
import CommonConnections from "../JobsComponents/CommonConnections";
import JobDates from "../JobsComponents/JobDates/JobDates";
import JobInfoItem from "../JobsComponents/JobInfoItem/JobInfoItem";
import JobLocation from "../JobsComponents/JobLocation";
import JobStatus from "../JobsComponents/JobStatus/JobStatus";
import ProjectActions from "../JobsComponents/ProjectActions/ProjectActions";
import styles from "./ProjectDetails.module.scss";

const ProjectDetails = () => {
	const { projectId } = useParams();
	const navigate = useNavigate();
	const { data, isFetching } = useProject(projectId);

	const { uuid } = useCurrentUser();

	const {
		active,
		owner: {
			uuid: ownerId,
			bio,
			size,
			// industry //TODO >> not coming from backend at the moment
		} = {},
		owner,
		title,
		tags,
		startDate,
		endDate,
		descriptionText,
		location,
		commonConnections,
		commonConnectionsCount,
		insertionTime,
		image: { file: { customName: projectImage } = {} } = {},
		activeJobsCount,
		passedJobsCount,
		activeFreelanceCount,
		passedFreelanceCount,
		activeCastingCallsCount,
		passedCastingCallsCount,
	} = data || {};

	const isProjectOwner = uuid === ownerId;

	const tagsList = useMemo(
		() => tags?.map((tag) => <Tag key={tag.uuid} itemName={tag.name} />),
		[tags]
	);

	const validateTab = (tab) => {
		const validTabs = ["description", "long_term", "freelance", "casting"];
		return validTabs.includes(tab) ? tab : "description";
	};

	const routingLocation = useLocation();

	const searchParams = new URLSearchParams(routingLocation.search);

	const activeTab = validateTab(searchParams.get("tab") || "description");

	const handleTabChange = (tab) => {
		const newSearchParams = new URLSearchParams(searchParams);
		newSearchParams.set("tab", tab);
		navigate(`${routingLocation.pathname}?${newSearchParams.toString()}`, {
			replace: true,
		});
	};

	useEffect(() => {
		const currentTab = searchParams.get("tab");
		const validTab = validateTab(currentTab);
		if (currentTab !== validTab) {
			handleTabChange(validTab);
		}
	}, [routingLocation]);

	const queryClient = useQueryClient();

	const {
		action: { mutate: toggleStatus },
	} = useMutate(projectApi.updateProjectStatus, () => {
		queryClient.invalidateQueries(projectKeys.detail(projectId));
	});

	const jobCounts = {
		long_term: {
			open: activeJobsCount,
			closed: passedJobsCount,
		},
		freelance: {
			open: activeFreelanceCount,
			closed: passedFreelanceCount,
		},
		casting: {
			open: activeCastingCallsCount,
			closed: passedCastingCallsCount,
		},
	};

	return (
		<DefaultPage
			headerRightContent={
				<>
					<div className={styles.header}>
						<BackButton onBack={() => navigate(-1)} />
						<p>{title}</p>
					</div>
					{!isFetching && isProjectOwner && (
						<ProjectActions
							horizontal={false}
							project={data}
							btnColor={"#FFF"}
						/>
					)}
				</>
			}
			defaultHeader={false}
			leftSideChildren={<FeaturesSidebar active={featuresSection.jobs} />}
			rightSideChildren={
				isFetching ? (
					<LoadingPage />
				) : (
					<div className={styles.container}>
						<JobStatus
							canEdit={isProjectOwner}
							active={active}
							onToggleStatus={() =>
								toggleStatus({
									projectId,
									status: !active,
								})
							}
							isProject={true}
						/>

						<div className={styles.project_card}>
							<div className={styles.img_wrapper}>
								{projectImage ? (
									<LazyImage
										image={
											<img
												src={projectImage}
												alt={`${title}`}
											/>
										}
									/>
								) : (
									<ImageIcon
										width={35}
										height={35}
										fill={"#FFF"}
									/>
								)}
							</div>

							<div className={styles.project_details}>
								<div className={styles.title_wrapper}>
									<h3 className={styles.title}>{title}</h3>
									{isProjectOwner && (
										<EditButton
											onClick={() =>
												navigate(
													routes.projectEdit(
														projectId
													)
												)
											}
										/>
									)}
								</div>
								<div className={styles.project_more_info}>
									{tags.length > 0 && (
										<div className={styles.tags_container}>
											{tagsList}
										</div>
									)}
									<div className={styles.date_container}>
										<p>
											Posted on{" "}
											<span>
												{format(
													new Date(insertionTime),
													"dd/MM/yyyy"
												)}
											</span>
										</p>
									</div>
								</div>
							</div>
						</div>

						<div className={styles.menu}>
							<TabMenu
								menuItems={{
									description: "DESCRIPTION",
									long_term: "JOB OPENINGS",
									freelance: "FREELANCING",
									casting: "CASTING CALLS",
								}}
								selectedTab={activeTab}
								onSelectedTabChanged={handleTabChange}
								customStyle={styles.menu_button}
							/>
						</div>

						{activeTab === "description" ? (
							<div className={styles.project_description_wrapper}>
								<JobInfoItem
									title={"Project Dates"}
									content={
										<JobDates
											startDate={startDate}
											endDate={endDate}
										/>
									}
								/>

								<JobInfoItem
									title={"Project Description"}
									content={<p>{descriptionText}</p>}
								/>

								<JobLocation
									location={location}
									isProject={true}
								/>

								<JobInfoItem
									title={"About the company"}
									content={
										<>
											<CompanyItem
												item={owner}
												subdata={`${owner.locationDTO.name}, ${owner.locationDTO.countryDTO.name}`}
											/>
											<div
												className={styles.company_stats}
											>
												{/* <p>Industry: </p> */}
												<p>
													Company Size:{" "}
													{
														companySizeList.find(
															(item) =>
																item.value ===
																size
														).label
													}
												</p>
											</div>
											<p>{bio}</p>
										</>
									}
								/>

								{commonConnections?.length > 0 && (
									<CommonConnections
										count={commonConnectionsCount}
										data={commonConnections}
										company={owner}
									/>
								)}
							</div>
						) : (
							<div>
								<CollapsibleJobSection
									activeTab={activeTab}
									open
									count={jobCounts[activeTab].open}
									projectId={projectId}
									companyId={ownerId}
								/>

								<CollapsibleJobSection
									activeTab={activeTab}
									open={false}
									count={jobCounts[activeTab].closed}
									projectId={projectId}
									companyId={ownerId}
								/>
							</div>
						)}
					</div>
				)
			}
			rightSideContainerColor={"#FFF"}
		/>
	);
};

export default ProjectDetails;
