import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./ConnectionTab.module.css";
import useApi from "../../../hooks/useApi";
import connectionApi from "../../../api/connection";
import GroupMember from "../../Chat/GroupMember";
import ContactLoader from "../../Utils/SubComs/CustomLoader/ContactLoader";
import followIcon from "images/connection-icons/follow.svg";
import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";
import ProfileTabContainer from "components/Utils/SubComs/ProfileTab/ProfileTabContainer";
import { pluralize } from "components/Utils/General";

const Follow = ({ profile, canEdit, connectionCount }) => {
	const getFollowingApi = useApi(connectionApi.getFollowing);
	const [following, setFollowing] = useState([]);
	const { connection } = profile;
	const navigate = useNavigate();
	const { userUrl } = useParams();

	const fetchFollowing = () => {
		getFollowingApi.request(profile.uuid, 0, 6);
	};

	useEffect(() => {
		// if (profile.uuid) {
		fetchFollowing();
		// }
	}, [profile.uuid, connection?.followers, connection?.following]);

	useEffect(() => {
		if (
			!getFollowingApi.loading &&
			getFollowingApi.responseCode === 200 &&
			getFollowingApi.data &&
			getFollowingApi.data.length > 0
		) {
			setFollowing(getFollowingApi.data);
		}
	}, [getFollowingApi.data]);

	const handleViewAll = (action) => () => {
		sessionStorage.setItem("activeConnectionTab", action);
		navigate(
			userUrl
				? `/profile/${userUrl}/connections`
				: "/profile/connections",
			{
				state: {
					profile,
					canEdit,
				},
			}
		);
	};

	const followingList = following.map((follower) => (
		<div className={styles.contact_item} key={follower.uuid}>
			<GroupMember data={follower} redirect={true} />
		</div>
	));

	if (connectionCount.followers === 0 && connectionCount.following === 0) {
		return null;
	}

	return (
		<ProfileTabContainer>
			<div className={styles.section_title}>
				<div>
					<img
						src={followIcon}
						// className={styles.section_icon}
						alt="Follow"
					/>
					Follow
				</div>
				<SeeMore
					text="See All"
					onClick={handleViewAll("People you follow")}
				/>
			</div>

			{getFollowingApi.loading && <ContactLoader />}

			{connectionCount.following > 0 && !getFollowingApi.loading && (
				<>
					<div className={styles.subtitle}>
						{canEdit
							? "You follow "
							: profile.type === "USER"
							? `${profile.firstName} ${profile.lastName} follows `
							: `${profile.name} follows `}
						<span
							onClick={handleViewAll("People you follow")}
						>{`${connectionCount.following}`}</span>{" "}
						{pluralize(connectionCount.following, "Connection")}.
					</div>

					<div>{followingList}</div>
				</>
			)}

			{/* {connectionCount.followers === 0 && !getFollowersApi.loading && (
                            <div className={`${styles.more_section} ${styles.counter}`}>
                                <div className={`${styles.more_info} ${styles.counter}`}>
                                    You don't follow anyone yet
                                </div>
                            </div>
                        )} */}

			{connectionCount.followers > 0 && (
				<div className={styles.more_section}>
					<div className={styles.more_info}>
						<span
							onClick={handleViewAll("People who follow you")}
						>{`${connectionCount.followers}`}</span>{" "}
						{pluralize(connectionCount.followers, "Connection")}{" "}
						follow{" "}
						{canEdit
							? "you"
							: profile.type === "USER"
							? `${profile.firstName} ${profile.lastName}`
							: `${profile.name}`}
						.
					</div>
					<div
						className={styles.view_all}
						onClick={handleViewAll("People who follow you")}
					>
						View all followers
					</div>
				</div>
			)}
		</ProfileTabContainer>
	);
};

export default Follow;
