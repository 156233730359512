import React from "react";
import styles from "./ConvActions.module.css";

import addIcon from "images/add-icon.svg";
import infoIcon from "images/info-icon.svg";
import muteIcon from "images/notification-icon.svg";
import unmuteIcon from "images/unmute-icon.svg";
import exportIcon from "images/export-icon.svg";
import clearIcon from "images/clear-icon.svg";
// import blockIcon from "images/block-icon.svg";
import reportIcon from "images/report-icon.svg";
import cancelBtn from "images/cancel-btn-red.svg";

const ConvActions = ({
	isGroup = false,
	blocking,
	muted,
	onMute,
	onUnmute,
	onExport,
	onClear,
	onReport,
	onEditGroup,
	onAdd,
	onExit,
	amIAdmin,
}) => {
	return (
		<div
			className={`${styles.option_items} ${
				!isGroup ? styles.p2p : undefined
			}`}
		>
			<div
				className={`${styles.controls} ${
					!isGroup ? styles.p2p : undefined
				}`}
			>
				{isGroup && amIAdmin && (
					<>
						<button className={styles.option_item} onClick={onAdd}>
							<img src={addIcon} alt="add people" />
							<span>Add people to group</span>
						</button>
						<button
							className={styles.option_item}
							onClick={onEditGroup}
						>
							<img src={infoIcon} alt="Update Group Info" />
							<span>Update Group Info</span>
						</button>
					</>
				)}

				{muted ? (
					<button className={styles.option_item} onClick={onUnmute}>
						<img src={unmuteIcon} alt="Unmute conversation" />
						<span>Unmute Notification</span>
					</button>
				) : (
					<button className={styles.option_item} onClick={onMute}>
						<img src={muteIcon} alt="mute conversation" />
						<span>Mute Notification</span>
					</button>
				)}
				<button className={styles.option_item} onClick={onExport}>
					<img src={exportIcon} alt="export conversation" />
					<span>Export Conversation</span>
				</button>
				<button className={styles.option_item} onClick={onClear}>
					<img src={clearIcon} alt="clear conversation" />
					<span>Clear Conversation</span>
				</button>

				{isGroup && (
					<>
						<button className={styles.option_item} onClick={onExit}>
							<img src={cancelBtn} alt="leave group" />
							<span>Leave Group</span>
						</button>
						<button
							className={styles.option_item}
							onClick={onReport}
						>
							<img src={reportIcon} alt="report" />
							<span>{`${
								isGroup ? "Report Group" : "Report"
							}`}</span>
						</button>
					</>
				)}
			</div>

			{/* <div className={styles.controls}>
                {isGroup ? (
                    <div className={styles.option_item} onClick={onExit}>
                        <img src={cancelBtn} alt="leave group" />
                        <span>Leave Group</span>
                    </div>
                ) : blocking ? (
                    <div className={styles.option_item} onClick={onUnblock}>
                        <img src={blockIcon} alt="Unblock connection" />
                        <span>Unblock Connection</span>
                    </div>
                ) : (
                    <div className={styles.option_item} onClick={onBlock}>
                        <img src={blockIcon} alt="block connection" />
                        <span>Block Connection</span>
                    </div>
                )}
                <div className={styles.option_item} onClick={onReport}>
                    <img src={reportIcon} alt="report" />
                    <span>{`${isGroup ? "Report Group" : "Report"}`}</span>
                </div>
            </div> */}
		</div>
	);
};

export default ConvActions;
