import profileApi from "api/profile";
import PrimaryButton from "components/Utils/Button/PrimaryButton";
import { successfulChangeToast } from "components/Utils/General";
import PromptModal from "components/Utils/Prompt/PromptModal";
import MultiSelectInput from "components/Utils/SubComs/Inputs/MultiSelectInput/MultiSelectInput";
import useFetchRoles from "hooks/useFetchRoles";
import useMutate from "hooks/useMutate";
import { rolesKeys } from "queryKeys/roles-key-factory";
import { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { roleUpdated } from "../../../../store/slices/user";
import { defaultFormatter } from "../../../Utils/SubComs/Inputs/SearchableInput/response-formatter";
import styles from "./Role.module.css";

const rolesLimit = 10;
const itemsPerPage = 20;

const Index = () => {
	const dispatch = useDispatch();

	const { roles } = useSelector((state) => state.user.profileInfo);

	const [myRoles, setMyRoles] = useState(() =>
		roles.map((role) => ({
			label: role.roleDTO ? role.roleDTO.name : role.roleName,
			value: role.roleDTO ? role.roleDTO.uuid : role.roleName,
		}))
	);

	const prevRolesList = useRef(myRoles).current;

	const [unsavedChanges, setUnsavedChanges] = useState(false);
	const [shouldBlockSave, setShouldBlockSave] = useState(true);

	const handleRemoveRole = (roleIndex) => {
		setMyRoles((prevState) =>
			prevState.filter((_, index) => index !== roleIndex)
		);
	};

	const handleSelectedRoles = (role) => {
		setMyRoles((prevState) => [...prevState, role]);
	};

	const {
		action: { mutate: updateRoles, isLoading },
	} = useMutate(profileApi.updateRoles, (response) => {
		dispatch(roleUpdated(response.data));
		successfulChangeToast();
		setShouldBlockSave(false);
	});

	const handleSave = () => {
		const rolesToSave = myRoles.map((role) => role.value);
		updateRoles(rolesToSave);
	};

	useEffect(() => {
		if (!shallowEqual(myRoles, prevRolesList)) {
			setUnsavedChanges(true);
		} else {
			setUnsavedChanges(false);
		}
	}, [myRoles]);

	const fetchRoles = useFetchRoles();

	return (
		<>
			<div className={styles.container}>
				<MultiSelectInput
					creatable={true}
					queryName={rolesKeys.search}
					queryFn={fetchRoles}
					data={myRoles}
					itemsPerPage={itemsPerPage}
					formatter={defaultFormatter}
					label="Roles"
					limit={rolesLimit}
					onChange={handleSelectedRoles}
					onRemoveItem={handleRemoveRole}
					infoText={`You can add up to ${rolesLimit} roles.`}
				/>

				<div className={styles.button_wrapper}>
					<PrimaryButton
						onClick={handleSave}
						isLoading={isLoading}
						type="button"
						text={"save"}
						width={130}
					/>
				</div>
			</div>

			<PromptModal when={unsavedChanges && shouldBlockSave} />
		</>
	);
};

export default Index;
