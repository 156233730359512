import { useState, useMemo, useEffect, useRef } from "react";
import specialProfileApi from "../../../../../api/special-profile";
import useApi from "../../../../../hooks/useApi";
import FileViewer from "../../../../Utils/FileViewer/FileViewer";
import ViewerHeader from "../../../../Utils/FileViewer/ViewerHeader";
import FullScreenModal from "../../../../Utils/GaawkModal/FullScreenModal";
import { viewerMapper } from "../../../../Utils/General";
import TabMenu from "../../../../Utils/SubComs/TabMenu/TabMenu";
import GalleryItem from "../../../GalleryTab/GalleryItem";
import styles from "./Media.module.css";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { specialProfileKeys } from "queryKeys/specialProfile-key-factory";
import LoadingSpinner from "components/Utils/SubComs/LoadingSpinner/LoadingSpinner";
import useMutate from "hooks/useMutate";
import GalleryContainer from "components/Utils/SubComs/GalleryContainer/GalleryContainer";

const Media = ({ specialProfileId, editMode = false, userUrl }) => {
	const fileviewerRef = useRef();

	const [showSlider, setShowSlider] = useState(false);
	const [initialSlideIndex, setInitialSlideIndex] = useState(0);

	const [menu, setMenu] = useState({});

	// !=============================

	const [tab, setTab] = useState("");
	const menuLength = Object.entries(menu).length;

	useEffect(() => {
		if (menuLength > 0) setTab(Object.keys(menu)[0]);
	}, [menuLength]);

	// !=============================

	const [photos, setPhotos] = useState([]);
	const [videos, setVideos] = useState([]);
	const [audios, setAudios] = useState([]);

	useEffect(() => {
		if (photos.length > 0) {
			setMenu((prevState) => ({ ...prevState, ...{ images: "Photos" } }));
		} else {
			const menuCopy = { ...menu };
			delete menuCopy.images;
			setMenu(menuCopy);
		}
	}, [photos.length]);

	useEffect(() => {
		if (videos.length > 0) {
			setMenu((prevState) => ({ ...prevState, ...{ videos: "Videos" } }));
		} else {
			const menuCopy = { ...menu };
			delete menuCopy.videos;
			setMenu(menuCopy);
		}
	}, [videos.length]);

	useEffect(() => {
		if (audios.length > 0) {
			setMenu((prevState) => ({ ...prevState, ...{ audios: "Audios" } }));
		} else {
			const menuCopy = { ...menu };
			delete menuCopy.audios;
			setMenu(menuCopy);
		}
	}, [audios.length]);

	const getMediaApi = useApi(specialProfileApi.getMedias, true, true);

	const fetchBoard = async () => {
		const response = await getMediaApi.request(specialProfileId);
		return response.data;
	};

	const { isLoading, data: medias } = useQuery({
		queryKey: specialProfileKeys.medias(specialProfileId),
		queryFn: fetchBoard,
	});

	useEffect(() => {
		if (medias) {
			const photoFiles = viewerMapper(
				medias.filter((item) => item.file.previewType === "IMAGE")
			);
			const videosFiles = viewerMapper(
				medias.filter((item) => item.file.previewType === "VIDEO")
			);
			const audiosFiles = viewerMapper(
				medias.filter((item) => item.file.previewType === "AUDIO")
			);

			setPhotos(photoFiles);
			setVideos(videosFiles);
			setAudios(audiosFiles);
		}
	}, [medias]);

	const mapFiles = (files) => {
		return files.map((item) => {
			const newItem = {
				file: {
					originalName: item.originalName,
					url: item.url,
					thumbnail: item.thumbnail,
					mediaType: item.mediaType,
				},
			};

			return (
				<GalleryItem
					key={item.uuid}
					item={newItem}
					onClick={() => handleShowSlider(item)}
					deletable={editMode}
					onDelete={() =>
						deleteMedia({ specialProfileId, fileId: item.fileId })
					}
				/>
			);
		});
	};

	const queryClient = useQueryClient();

	const {
		action: { mutate: deleteMedia },
	} = useMutate(specialProfileApi.deleteMedia, () => {
		queryClient.invalidateQueries(
			specialProfileKeys.medias(specialProfileId)
		);
		queryClient.invalidateQueries(specialProfileKeys.detail(userUrl));
	});

	const filesItems = useMemo(() => {
		return mapFiles(
			tab === "images" ? photos : tab === "videos" ? videos : audios
		);
	}, [audios, photos, tab, videos]);

	const handleShowSlider = (item) => {
		const fileIndex = (
			tab === "images" ? photos : tab === "videos" ? videos : audios
		).findIndex((file) => file.uuid === item.uuid);

		setInitialSlideIndex(fileIndex);
		setShowSlider(true);
	};

	if (isLoading) return <LoadingSpinner />;

	return (
		<div className={styles.container}>
			<TabMenu
				menuItems={menu}
				selectedTab={tab}
				onSelectedTabChanged={setTab}
			/>
			<hr />

			<GalleryContainer>{filesItems}</GalleryContainer>
			{/* <div className={styles.gallery_container}>{filesItems}</div> */}

			<FullScreenModal
				show={showSlider}
				onClose={() => setShowSlider(false)}
				fullWidth={true}
				header={
					<ViewerHeader
						onClose={() => setShowSlider(false)}
						leftSide={""}
						rightSide={
							""
							// <VaultActions
							//     isFavorite={filesArray[activeSlideIndex]?.favorite}
							//     onFavorite={handleFavoriteItem}
							//     onDownload={handleSliderDownload}
							//     onShare={() => handleShare(getActiveFile())}
							//     onFullscreen={() => fileviewerRef.current.enterFullscreen()}
							// />
						}
					/>
				}
				children={
					<FileViewer
						ref={fileviewerRef}
						files={
							tab === "images"
								? photos
								: tab === "videos"
								? videos
								: audios
						}
						initialSlide={initialSlideIndex}
						// onLoadMore={handleLoadMoreItems}
						// isLoading={getFavoriteApi.loading || getSharedApi.loading || getMeItemsApi.loading}
						// onActiveSlide={(index) => setActiveSlideIndex(index)} //! needed ?
						// text={getActiveFile()?.file.originalName} //! needed ?
						// fsActions={
						//     <VaultFullscreenActions
						//         favorite={filesArray[activeSlideIndex]?.favorite}
						//         onFavorite={handleFavoriteItem}
						//         onDownload={handleSliderDownload}
						//         onShare={() => handleShare(getActiveFile())}
						//     />
						// }
					/>
				}
			/>
		</div>
	);
};
export default Media;
