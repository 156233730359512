import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./ConnectionTab.module.css";
import friendsIcon from "images/connection-icons/friends.svg";
import useApi from "../../../hooks/useApi";
import connectionApi from "../../../api/connection";
import GroupMember from "../../Chat/GroupMember";
import ContactLoader from "../../Utils/SubComs/CustomLoader/ContactLoader";
import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";
import ProfileTabContainer from "components/Utils/SubComs/ProfileTab/ProfileTabContainer";
import { pluralize } from "components/Utils/General";

const Friends = ({ profile, canEdit, connectionCount }) => {
	const navigate = useNavigate();
	const getFriendsApi = useApi(connectionApi.getFriends);
	const [friends, setFriends] = useState([]);
	const { connection } = profile;
	const { userUrl } = useParams();

	const fetchFriends = () => {
		getFriendsApi.request(profile.uuid, 0, 6);
	};

	useEffect(() => {
		// if (profile.uuid) {
		fetchFriends();
		// }
	}, [profile.uuid, connection?.friendStatus]);

	useEffect(() => {
		if (
			!getFriendsApi.loading &&
			getFriendsApi.responseCode === 200 &&
			getFriendsApi.data &&
			getFriendsApi.data.length > 0
		) {
			setFriends(getFriendsApi.data);
		}
	}, [getFriendsApi.data]);

	const handleViewAll = (action) => () => {
		sessionStorage.setItem("activeConnectionTab", action);
		navigate(
			userUrl
				? `/profile/${userUrl}/connections`
				: "/profile/connections",
			{
				state: {
					profile,
					canEdit,
				},
			}
		);
	};

	const friendsList = friends.map((friend) => (
		<div className={styles.contact_item} key={friend.uuid}>
			<GroupMember data={friend} redirect={true} />
		</div>
	));

	if (
		(connectionCount.friends === 0 && !canEdit) ||
		(connectionCount.friends === 0 &&
			connectionCount.friendPendingSent === 0 &&
			connectionCount.friendPendingReceived === 0)
	)
		return null;

	return (
		<ProfileTabContainer>
			<div className={styles.section_title}>
				<div>
					<img src={friendsIcon} alt="Friends" />
					Friends
				</div>
				<SeeMore text="See All" onClick={handleViewAll("Friends")} />
			</div>

			{getFriendsApi.loading && <ContactLoader />}

			{connectionCount.friends > 0 && !getFriendsApi.loading && (
				<>
					<div className={styles.subtitle}>
						{canEdit
							? "You have "
							: profile.type === "USER"
							? `${profile.firstName} ${profile.lastName} has `
							: `${profile.name} has `}
						<span
							onClick={handleViewAll("Friends")}
						>{`${connectionCount.friends}`}</span>{" "}
						{pluralize(connectionCount.friends, "friend")}.
					</div>

					<div>{friendsList}</div>
				</>
			)}

			{/* {connectionCount.friends === 0 && !getFriendsApi.loading && (
                        <div className={`${styles.more_section} ${styles.counter}`}>
                            <div className={`${styles.more_info} ${styles.counter}`}>You don't have any friends yet</div>
                        </div>
                    )} */}

			{canEdit && (
				<>
					{connectionCount.friendPendingSent > 0 && (
						<div className={styles.more_section}>
							<div className={styles.more_info}>
								You have sent{" "}
								<span
									onClick={handleViewAll("Sent Requests")}
								>{`${connectionCount.friendPendingSent}`}</span>{" "}
								{pluralize(
									connectionCount.friendPendingSent,
									"friend"
								)}{" "}
								{pluralize(
									connectionCount.friendPendingSent,
									"request"
								)}
								.
							</div>
							<div
								className={styles.view_all}
								onClick={handleViewAll("Sent Requests")}
							>
								View all sent requests
							</div>
						</div>
					)}

					{connectionCount.friendPendingReceived > 0 && (
						<div className={styles.more_section}>
							<div className={styles.more_info}>
								You have{" "}
								<span
									onClick={handleViewAll("Pending Requests")}
								>{`${connectionCount.friendPendingReceived}`}</span>{" "}
								{pluralize(
									connectionCount.friendPendingReceived,
									"friend"
								)}{" "}
								{pluralize(
									connectionCount.friendPendingReceived,
									"request"
								)}
								.
							</div>
							<div
								className={styles.view_all}
								onClick={handleViewAll("Pending Requests")}
							>
								View all pending requests
							</div>
						</div>
					)}
				</>
			)}
		</ProfileTabContainer>
	);
};

export default Friends;
