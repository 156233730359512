import React, {
	useState,
	useEffect,
	// useMemo
} from "react";
import styles from "./UpdateWorkStatus.module.css";
import PrimaryButton from "../../Utils/Button/PrimaryButton";
import CustomCheckbox from "../../Utils/SubComs/Inputs/CustomCheckbox/CustomCheckbox";
import publicIcon from "images/public_icon.svg";
import MultiCheckbox from "../../Utils/MultiCheckbox/MultiCheckbox";
import {
	employementTypes,
	workLocation as workLocationTypes,
} from "../../Utils/General";
import useApi from "../../../hooks/useApi";
import workStatusApi from "../../../api/workStatus";
// import CreatableInput from "../../Utils/SubComs/Inputs/CreatableInput/CreatableInput";
import {
	defaultFormatter,
	locationFormatter,
	// unduplicateIds,
} from "../../Utils/SubComs/Inputs/SearchableInput/response-formatter";
// import TagContainer from "../../Utils/SubComs/Tags/TagContainer";
// import Tag from "../../Utils/SubComs/Tags/Tag";
import useFetchLocation from "../../../hooks/useFetchLocation";
import MultiSelectInput from "../../Utils/SubComs/Inputs/MultiSelectInput/MultiSelectInput";
import { useNavigate } from "react-router-dom";
import { rolesKeys } from "queryKeys/roles-key-factory";
import useFetchRoles from "hooks/useFetchRoles";
import { useDispatch } from "react-redux";
import { updateProfileTab } from "store/slices/activeTab";
import { locationKeys } from "queryKeys/location-key-factory";

const itemsLimit = 10;
const itemsPerPage = 20;

const UpdateWorkStatus = ({ workStatus: prevWorkStatus }) => {
	const navigate = useNavigate();
	const updateWorkStatusApi = useApi(workStatusApi.updateWorkStatus);
	// const searchRolesApi = useApi(rolesApi.searchRoles);

	const dispatch = useDispatch();

	const [workStatus, setWorkStatus] = useState(prevWorkStatus);
	const {
		available,
		roles,
		types = [],
		locations = [],
		legalCountries = [],
		workplace_hybrid,
		workplace_office,
		workplace_remote,
	} = workStatus;

	// ! ========== WORK AVAILABILITY ========

	const handleAvailability = (bool) => {
		setWorkStatus((prevState) => ({ ...prevState, available: bool }));
	};

	// ! ========== WORKPLACES ===============
	const handleSelectWorkplace = (selectedWorkplaces) => {
		const workPlacesValues = selectedWorkplaces.map((item) => item.value);

		if (workPlacesValues.includes("OFFICE"))
			setWorkStatus((prevState) => ({
				...prevState,
				workplace_office: true,
			}));
		else
			setWorkStatus((prevState) => ({
				...prevState,
				workplace_office: false,
			}));

		if (workPlacesValues.includes("REMOTE"))
			setWorkStatus((prevState) => ({
				...prevState,
				workplace_remote: true,
			}));
		else
			setWorkStatus((prevState) => ({
				...prevState,
				workplace_remote: false,
			}));

		if (workPlacesValues.includes("HYBRID"))
			setWorkStatus((prevState) => ({
				...prevState,
				workplace_hybrid: true,
			}));
		else
			setWorkStatus((prevState) => ({
				...prevState,
				workplace_hybrid: false,
			}));
	};

	// ! ========== JOB TYPES ================
	const handleSelectJob = (jobTypes) => {
		const formattedJobTypes = jobTypes?.map((type) => type.value);
		setWorkStatus((prevState) => ({
			...prevState,
			types: formattedJobTypes,
		}));
	};

	// ! ========== ROLES ====================
	const handleSelectedRole = (role) => {
		setWorkStatus((prevState) => ({
			...prevState,
			roles: [...prevState.roles, role],
		}));
	};

	const handleRemoveRole = (roleIndex) => {
		setWorkStatus((prevState) => ({
			...prevState,
			roles: prevState.roles.filter((_, index) => index !== roleIndex),
		}));
	};

	// ! ========== CITIES ===================
	const handleCities = (city) => {
		setWorkStatus((prevState) => ({
			...prevState,
			locations: [city, ...prevState.locations],
		}));
	};

	const handleRemoveCity = (cityIndex) => {
		setWorkStatus((prevState) => ({
			...prevState,
			locations: prevState.locations.filter(
				(_, index) => index !== cityIndex
			),
		}));
	};

	// ! ========== COUNTRIES ================
	const handleCountries = (country) => {
		setWorkStatus((prevState) => ({
			...prevState,
			legalCountries: [country, ...prevState.legalCountries],
		}));
	};

	const handleRemoveCountry = (countryIndex) => {
		setWorkStatus((prevState) => ({
			...prevState,
			legalCountries: prevState.legalCountries.filter(
				(_, index) => index !== countryIndex
			),
		}));
	};

	// ! ========== SUBMIT HANDLER ===========
	const handleSubmit = (e) => {
		e.preventDefault();

		const body = {
			available,
			locations: locations.map((location) => location.value),
			legalCountries: legalCountries.map((country) => country.value),
			jobTypes: types,
			roleIds: roles.map((role) => role.value),
			workplace_office,
			workplace_hybrid,
			workplace_remote,
		};

		updateWorkStatusApi.request(body);
	};

	useEffect(() => {
		if (
			!updateWorkStatusApi.loading &&
			Object.keys(updateWorkStatusApi.data).length > 0 &&
			updateWorkStatusApi.responseCode === 200
		) {
			dispatch(updateProfileTab("work status"));
			navigate("/profile");
		}
	}, [updateWorkStatusApi.data]);

	const [workplaceItems, setWorkplaceItems] = useState([]);
	useEffect(() => {
		if (workplace_office) {
			setWorkplaceItems((prevState) => [
				...prevState,
				{
					label: "On Site",
					value: "OFFICE",
				},
			]);
		}

		if (workplace_remote) {
			setWorkplaceItems((prevState) => [
				...prevState,
				{
					label: "Remote",
					value: "REMOTE",
				},
			]);
		}

		if (workplace_hybrid) {
			setWorkplaceItems((prevState) => [
				...prevState,
				{
					label: "Hybrid",
					value: "HYBRID",
				},
			]);
		}
	}, []);

	const fetchLocation = useFetchLocation(true, itemsPerPage);
	const fetchCountries = useFetchLocation(false, itemsPerPage);

	const fetchRoles = useFetchRoles();

	return (
		<div className={styles.container}>
			<form onSubmit={handleSubmit}>
				<div className={styles.form_input_wrapper}>
					<label className={styles.input_label}>Work Status</label>
					<div className={styles.checkbox_options}>
						<div className={styles.checkbox_wrapper}>
							<CustomCheckbox
								checked={available === true}
								onChange={() => handleAvailability(true)}
								customBackgroundColor={"gaawk"}
								isCircle={true}
							/>
							<span onClick={() => handleAvailability(true)}>
								Available
							</span>
						</div>
						<div className={styles.checkbox_wrapper}>
							<CustomCheckbox
								checked={available === false}
								onChange={() => handleAvailability(false)}
								customBackgroundColor={"gaawk"}
								isCircle={true}
							/>
							<span onClick={() => handleAvailability(false)}>
								Not Available
							</span>
						</div>
					</div>
					<div className={styles.info_container}>
						<img src={publicIcon} alt="public" />
						Your work status is always public.
						<span className={styles.separator} />
					</div>
				</div>

				<MultiSelectInput
					creatable={true}
					queryName={rolesKeys.search}
					queryFn={fetchRoles}
					data={roles}
					itemsPerPage={itemsPerPage}
					formatter={defaultFormatter}
					label="Roles"
					limit={itemsLimit}
					onChange={handleSelectedRole}
					onRemoveItem={handleRemoveRole}
					infoText={`You can add up to ${itemsLimit} roles which outline your core
                    skill sets.`}
				/>

				<div
					className={`${styles.form_input_wrapper} ${styles.inline_checkbox_container}`}
				>
					<MultiCheckbox
						title={"Workplace"}
						infoText={
							"Workplace is your preference of working from home, office and / or hybrid."
						}
						options={workLocationTypes}
						perRow={"3, auto"}
						onSelect={handleSelectWorkplace}
						selected={workplaceItems}
					/>
				</div>

				<MultiSelectInput
					queryName={locationKeys.cities}
					queryFn={fetchLocation}
					data={locations}
					itemsPerPage={itemsPerPage}
					formatter={locationFormatter}
					label="Preferred Locations"
					limit={itemsLimit}
					onChange={handleCities}
					onRemoveItem={handleRemoveCity}
					infoText={`You can add up to ${itemsLimit} locations where you want to work.`}
				/>

				<div className={styles.form_input_wrapper}>
					<MultiCheckbox
						title={"Job Type"}
						infoText={
							"What type of job you’re looking for? Full time, freelance, contract and more…"
						}
						options={employementTypes.flatMap((job) =>
							job.value !== "APPRENTICESHIP" &&
							job.value !== "SELF_EMPLOYED"
								? job
								: []
						)}
						canSelectAll={true}
						onSelect={handleSelectJob}
						selected={types.map((type) =>
							employementTypes.find(
								(jobType) => jobType.value === type
							)
						)}
					/>
				</div>

				<MultiSelectInput
					queryName={locationKeys.countries}
					queryFn={fetchCountries}
					data={legalCountries}
					itemsPerPage={itemsPerPage}
					formatter={locationFormatter}
					label="Legal to work in"
					limit={itemsLimit}
					onChange={handleCountries}
					onRemoveItem={handleRemoveCountry}
					infoText={`You can add up to ${itemsLimit} countries where you have / do not need a work permit to work in.`}
				/>

				<div className={styles.button_wrapper}>
					<PrimaryButton
						type="submit"
						// disabled={editAlbumApi.loading}
						text={"save"}
						className={`${styles.button} ${styles.save}`}
					/>
				</div>
			</form>
		</div>
	);
};

export default UpdateWorkStatus;
