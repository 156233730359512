import { useRef } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
	setService,
	setStep,
	updateStep1,
	setModal,
} from "../../../../store/slices/service";
import styles from "./ServicePopover.module.css";

import Options from "images/three-dots-black-5-x-20.svg";
import OptionsWhite from "images/three-dots-white-5-x-20.svg";
import deleteIcon from "images/delete-black.svg";
import editIcon from "images/edit-icon-20-x-20-black.svg";
import viewIcon from "images/view-icon-20-x-20-black.svg";
import addIcon from "images/add-icon-gray.png";
import Popover from "../../../Utils/Popover/Popover";

const ServicePopover = ({
	level,
	onDelete,
	serviceId,
	threeDotsIcon = OptionsWhite,
	isTitlePopover = false,
	isActiveService,
	service,
	maxLimitServices,
}) => {
	const popoverRef = useRef(null);
	const dispatch = useDispatch();
	return (
		<Popover
			ref={popoverRef}
			render={
				<div className="popover_container">
					<Link
						to={`/services/${serviceId}`}
						className={styles.popover_link}
					>
						<div className="popover_item">
							<img src={viewIcon} alt="reply" />
							<span>View</span>
						</div>
					</Link>

					<button
						onClick={(e) => {
							dispatch(setService(service));
							// e.preventDefault();
							dispatch(setStep(2));
							dispatch(setModal(true));
							e.stopPropagation();
							popoverRef.current.closePopover();
						}}
						// className={styles.popover_link}
						className="popover_item"
					>
						{/* <div className="popover_item"> */}
						<img src={editIcon} alt="edit" />
						<span>Edit</span>
						{/* </div> */}
					</button>

					{level !== 3 && !maxLimitServices && (
						<button
							// to={`/services/add/2`}
							className={styles.popover_link}
						>
							<div
								className="popover_item"
								onClick={(e) => {
									dispatch(
										updateStep1({
											level: level + 1,
											parentId: serviceId,
										})
									);

									dispatch(setStep(2));
									dispatch(setModal(true));
									e.stopPropagation();
									popoverRef.current.closePopover();
								}}
							>
								<img src={addIcon} alt="add" />
								<span>Add Sub-Category</span>
							</div>
						</button>
					)}

					<button
						className="popover_item"
						onClick={(e) => {
							popoverRef.current.closePopover();
							e.stopPropagation();
							onDelete();
						}}
					>
						<img src={deleteIcon} alt="delete" />
						<span>Delete</span>
					</button>
				</div>
			}
		>
			<button
				className={styles.option_icon}
				onClick={(e) => {
					e.stopPropagation();
					e.preventDefault();
				}}
			>
				<img
					src={
						level === 1 || isTitlePopover || isActiveService
							? threeDotsIcon
							: Options
					}
					alt="options"
				/>
			</button>
		</Popover>
	);
};

export default ServicePopover;
