import { useEffect, useRef, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { saveAs } from "file-saver";

import PublicInfo from "../ProfilePicEdit/PublicInfo";
import AddCaption from "../ProfilePicEdit/AddCaption";
import FeedShare from "../ProfilePicEdit/FeedShare";

import WarningModal from "../../../../components/Utils/GaawkModal/WarningModal";

import uploadIcon from "images/upload_icon.svg";
// import cropIcon from "images/crop_icon.svg";

import downloadIcon from "images/download-icon-blue.svg";
// import shareIcon from "images/share-icon.svg";
import deleteIcon from "images/bin-icon.svg";

import styles from "./ProfileCoverEdit.module.css";

import { coverUpdated } from "../../../../store/slices/user";
import { coverUpdated as companyCoverUpdated } from "../../../../store/slices/company";

import {
	uploadCover,
	uploadCoverPreview,
	cropCover,
	resetCover,
	updateCoverUnsavedChanges,
	updateCaptionCover,
} from "../../../../store/slices/cover";
import CropModal from "../../../Utils/CropModal/CropModal";
import PromptModal from "components/Utils/Prompt/PromptModal";
import { successfulChangeToast } from "components/Utils/General";
import useMutate from "hooks/useMutate";
import profileApi from "api/profile";
import PrimaryButton from "components/Utils/Button/PrimaryButton";
import { useNavigate } from "react-router-dom";
import routes from "components/Routing/routing-keys";

const CoverEdit = ({ editCompany }) => {
	const dispatch = useDispatch();
	// const { pathname } = useLocation();

	const [imageName, setImageName] = useState("");

	//TODO >> replace with useCurrentUser ?

	const userData = useSelector((state) => {
		if (editCompany) {
			return state.company.companyInfo;
		} else {
			return state.user.profileInfo;
		}
	});

	/* prettier-ignore */
	const userCover = userData.profileCover ? userData.profileCover.originalImageURL : undefined;

	const userCaption = userData.profileCover?.postText ?? "";

	const croppedCover = useSelector(
		(state) => state.coverPicture.croppedCover
	);
	const uploadedCover = useSelector(
		(state) => state.coverPicture.loadedCover
	);

	const uploadedCoverPreview = useSelector(
		(state) => state.coverPicture.loadedCoverPreview
	);
	const updatedCaption = useSelector((state) => state.coverPicture.caption);
	const unsavedChanges = useSelector(
		(state) => state.coverPicture.unsavedChanges
	);

	const [cover, setCover] = useState(userCover);

	const [coverFile, setCoverFile] = useState("");

	const [caption, setCaption] = useState(userCaption);

	const prevCaption = useRef(caption).current;

	const [shareCheck, setShareCheck] = useState(false);
	// const [morePopoverOpened, setMorePopoverOpened] = useState(false);
	const [deleteModalOpened, setDeleteModalOpened] = useState(false);

	const [cropModal, setCropModal] = useState(false);

	const [isSaved, setIsSaved] = useState(false);
	// const [unsavedChanges, setUnsavedChanges] = useState(false);
	// const [shouldBlockNav, setShouldBlockNav] = useState(true);
	// const [redirect, setRedirect] = useState("");

	const handleCover = (e) => {
		setImageName(e.target.files[0].name);
		dispatch(uploadCover(e.target.files[0]));
		dispatch(uploadCoverPreview(URL.createObjectURL(e.target.files[0])));
		dispatch(cropCover(""));
		setCropModal(true);
	};

	const handleCaption = (text) => {
		setCaption(text);
		dispatch(updateCaptionCover(text));
	};

	const navigate = useNavigate();

	const {
		action: { mutate: updateCoverPicture, isLoading },
	} = useMutate(profileApi.updateCoverPicture, (response) => {
		dispatch(
			editCompany
				? companyCoverUpdated(response.data)
				: coverUpdated(response.data)
		);
		setIsSaved(true);
		successfulChangeToast();
		navigate(routes.profile());
	});

	const handleSave = () => {
		// const imageData = {
		// 	share: shareCheck,
		// 	...(caption && { caption: encodeURIComponent(caption) }),
		// };

		const formData = new FormData();
		if (coverFile !== "") {
			formData.append("coverImageFile", coverFile, imageName);
		}
		// dispatch(
		// 	editCompany
		// 		? updateCompanyCoverImage(formData, imageData, userData.uuid)
		// 		: updateCoverImage(formData, imageData)
		// );
		// setIsSaved(true);

		formData.append("share", shareCheck);

		if (caption) {
			formData.append("caption", encodeURIComponent(caption));
		}

		if (editCompany) formData.append("companyId", userData.uuid);

		setAllowNavigation(true);

		updateCoverPicture({
			formData,
			isCompany: editCompany ? true : false,
		});
		// updateCoverPicture({
		// 	formData,
		// 	imageData,
		// 	...(editCompany && { companyId: userData.uuid }),
		// });
	};

	const {
		action: { mutate: deleteCoverPic },
	} = useMutate(profileApi.deleteCoverPic, (response) => {
		dispatch(
			editCompany
				? companyCoverUpdated(response.data)
				: coverUpdated(response.data)
		);

		dispatch(resetCover());
		setImageName("");
		setCover("");
		setCoverFile("");
		setDeleteModalOpened(false);
		successfulChangeToast();
		navigate(routes.profile());
	});

	const handleDownload = () => {
		saveAs(cover, "cover.jpg");
	};

	// const handleCropRedirect = () => {
	// 	if (cover !== "") {
	// 		setShouldBlockNav(false);
	// 		setRedirect(`/profile/coverPic/crop`);
	// 	}
	// };

	const [allowNavigation, setAllowNavigation] = useState(false);

	const onBlock = () => {
		if (allowNavigation) return false;

		if (unsavedChanges) {
			return true;
		} else dispatch(resetCover());
	};

	useEffect(() => {
		if (croppedCover !== "") {
			setCover(URL.createObjectURL(croppedCover));
			setCoverFile(croppedCover);
		} else if (uploadedCoverPreview !== "" && uploadedCover !== "") {
			setCover(uploadedCoverPreview);
			setCoverFile(uploadedCover);
		} else {
			setCover(userCover);
		}

		if (!isSaved) {
			dispatch(
				updateCoverUnsavedChanges(
					uploadedCover !== "" ||
						croppedCover !== "" ||
						caption !== prevCaption
				)
			);
		} else {
			dispatch(updateCoverUnsavedChanges(false));
			// setUnsavedChanges(false);
		}
	}, [
		croppedCover,
		uploadedCover,
		uploadedCoverPreview,
		userCover,
		caption,
		updatedCaption,
		isSaved,
		dispatch,
		prevCaption,
	]);

	// useBlocker(
	// 	({
	// 		currentLocation: { pathname: currentPathname },
	// 		nextLocation: { pathname: nextPathname },
	// 	}) => {
	// 		if (nextPathname !== currentPathname) {
	// 			dispatch(resetCover());
	// 		}
	// 	}
	// );

	const inputFile = useRef(null);

	return (
		<>
			<div className={styles.container}>
				<div
					className={styles.cover_container}
					style={
						cover
							? { backgroundColor: "transparent" }
							: { backgroundColor: "#a6b1bc" }
					}
				>
					{cover && <img src={cover} alt="cover" />}
				</div>

				<PublicInfo subtext="cover picture" fixedMargin={true} />

				<AddCaption
					onChange={handleCaption}
					disabled={!cover}
					value={caption}
				/>

				<div className={styles.img_options}>
					<input
						ref={inputFile}
						type="file"
						accept="image/png, image/jpg, image/jpeg"
						onChange={(e) => {
							handleCover(e);
							setImageName(e.target.files[0].name);
							e.target.value = "";
						}}
						style={{ display: "none" }}
					/>
					<button
						onClick={() => {
							inputFile.current.click();
						}}
					>
						<img
							src={uploadIcon}
							className={styles.icon}
							alt={"upload"}
						/>
						Upload Cover Picture
					</button>

					{/* {coverFile && (
						<button onClick={() => setCropModal(true)}>
							<img
								src={cropIcon}
								className={styles.icon}
								alt={"crop"}
							/>
							Crop Cover
						</button>
					)} */}

					{cover && (
						<button onClick={handleDownload}>
							<img
								src={downloadIcon}
								className={styles.icon}
								alt="download"
							/>
							Download
						</button>
					)}

					{/* {cover && (
						<div
							className={styles.img_option}
							onClick={() => alert("TODO")}
						>
							<button>
								<img src={shareIcon} className={styles.icon} alt="Share" />
							</button>
							<label>Share</label>
						</div>
					)} */}

					{/* //TODO >> CROP ONLY IF COVER IS NEWLY UPLOADED */}
					{cover && (
						<button onClick={() => setDeleteModalOpened(true)}>
							<img
								src={deleteIcon}
								className={styles.icon}
								alt="Delete"
							/>
							Delete Cover Picture
						</button>
					)}
				</div>

				{cover !== userCover && (
					<div className={styles.feed_checkbox_container}>
						<FeedShare
							checked={shareCheck}
							onChange={() =>
								setShareCheck((prevState) => !prevState)
							}
						/>
					</div>
				)}
				{/* <ProfileSave onClick={handleSave} /> */}
				{/* <ProfileEditBottomButtons
					onSaveClicked={handleSave}
					showDeleteButton={false}
				/> */}

				<div className={styles.button_wrapper}>
					<PrimaryButton
						onClick={handleSave}
						isLoading={isLoading}
						type="button"
						text={"save"}
						width={130}
					/>
				</div>
			</div>

			<PromptModal
				when={onBlock}
				onProceed={() => dispatch(resetCover())}
			/>

			<WarningModal
				show={deleteModalOpened}
				headerText="Are you sure you want to delete this image?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setDeleteModalOpened(false)}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={() => {
					setAllowNavigation(true);
					deleteCoverPic(editCompany && userData.uuid);
				}}
			/>

			<CropModal
				image={cover}
				show={cropModal}
				onClose={() => setCropModal(false)}
				aspectMode={25 / 7}
				onCrop={(cropped) => {
					dispatch(cropCover(cropped));
					setCropModal(false);
				}}
				returnBlob={true}
			/>
		</>
	);
};

export default CoverEdit;
