import { useInfiniteQuery } from "@tanstack/react-query";
import styles from "./InfiniteSearchInput.module.scss";
import CustomSelect from "../../CustomSelect/CustomSelect";
import CreatableSelect from "../../Inputs/Select/CreatableSelect";
import { useMemo, useState } from "react";
import useDebounce from "../../../../../hooks/useDebounce";
// import { components } from "react-select";

const InfiniteSearchInput = ({
	queryName,
	queryFn,
	itemsPerPage,
	label,
	customStyle,
	required = false,
	error,
	errorStyle,
	name,
	control,
	isClearable = false,
	option,
	onChange,
	value = null,
	placeholder,
	unDuplicateFn,
	formatter,
	selected,
	enabled = true,
	staleTime = 300000,
	disabled = false,
	creatable = false,
}) => {
	const [searchInput, setSearchInput] = useState("");
	const debouncedSearch = useDebounce(searchInput);

	const handleSearchInputChange = (e) => {
		const query = e.target.value;

		if (e.key !== "ArrowUp" && e.key !== "ArrowDown") {
			setSearchInput(query);
		}
	};

	const { data, hasNextPage, fetchNextPage, isFetching } = useInfiniteQuery({
		// queryKey: [queryName, debouncedSearch],
		queryKey: queryName(debouncedSearch),
		queryFn,
		getNextPageParam: (lastPage, pages) => {
			const nextPage =
				lastPage.length === itemsPerPage ? pages.length : undefined;
			return nextPage;
		},

		staleTime,
		enabled,
	});

	const formattedData = useMemo(
		() =>
			unDuplicateFn
				? unDuplicateFn(
						data?.pages?.flat()?.map((item) => formatter(item))
				  )
				: data?.pages?.flat()?.map((item) => formatter(item)),
		[data?.pages, selected?.length]
	);

	return (
		<div
			className={`${styles.container} ${customStyle} ${
				disabled ? styles.disabled : undefined
			}`}
		>
			{label && (
				<label>
					{label}
					{required && <span className="required">*</span>}
				</label>
			)}
			<div onKeyUp={handleSearchInputChange}>
				{creatable ? (
					<CreatableSelect
						disabled={disabled}
						options={formattedData}
						placeholder={placeholder}
						height="35px"
						components={{
							// Menu: ({ children, options, ...props }) => {
							// 	return options.length === 0 &&
							// 		!searchInput ? null : (
							// 		<components.Menu {...props}>
							// 			{children}
							// 		</components.Menu>
							// 	);
							// },
							...option,
						}}
						isLoading={isFetching}
						error={!!error}
						control={control}
						name={name}
						onChange={(val) => {
							onChange && onChange(val);
							setSearchInput("");
						}}
						value={value}
						onBlur={() => {
							setSearchInput("");
						}}
						onMenuScrollToBottom={() => {
							if (hasNextPage && !isFetching) fetchNextPage();
						}}
					/>
				) : (
					<CustomSelect
						disabled={disabled}
						options={formattedData}
						placeholder={placeholder}
						height="35px"
						components={{
							// Menu: ({ children, options, ...props }) => {
							// 	// console.log("🚀 ~ options >>", options);
							// 	return options.length === 0 &&
							// 		!searchInput ? null : (
							// 		<components.Menu {...props}>
							// 			{children}
							// 		</components.Menu>
							// 	);
							// },
							...option,
						}}
						isLoading={isFetching}
						isClearable={isClearable}
						error={!!error}
						control={control}
						name={name}
						onChange={(val) => {
							onChange && onChange(val);
							setSearchInput("");
						}}
						value={value}
						onBlur={() => {
							setSearchInput("");
						}}
						onMenuScrollToBottom={() => {
							if (hasNextPage && !isFetching) fetchNextPage();
						}}
					/>
				)}
				{error && (
					<p className={`${styles.error_message} ${errorStyle}`}>
						{error.message}
					</p>
				)}
			</div>
		</div>
	);
};

export default InfiniteSearchInput;
