import { useParams } from "react-router";
import useCurrentUser from "./useCurrentUser";
import { useDispatch } from "react-redux";
import useApi from "./useApi";
import profileApi from "api/profile";
import { useQuery } from "@tanstack/react-query";
import { profileKeys } from "queryKeys/profile-key-factory";
import { useEffect, useState } from "react";
import { profileInfoUpdated } from "store/slices/user";
import { companyInfoUpdated } from "store/slices/company";

const useFetchProfile = () => {
	const dispatch = useDispatch();
	const { userUrl: paramsUrl } = useParams();
	const { type, uuid, url } = useCurrentUser();

	const [canEdit, setCanEdit] = useState(false);

	const getUserByUrlApi = useApi(profileApi.getUserByUrl, true, true);
	const getUserProfileApi = useApi(profileApi.getUserProfile, true, true);
	const getCompanyProfileApi = useApi(
		profileApi.getCompanyProfile,
		true,
		true
	);

	const fetchProfileByUrl = async ({ queryKey }) => {
		// eslint-disable-next-line no-unused-vars
		const [_, url] = queryKey;
		const response = await getUserByUrlApi.request(url);
		return response.data;
	};

	const fetchProfileByUuid = async ({ queryKey }) => {
		// eslint-disable-next-line no-unused-vars
		const [_, __, id, userType] = queryKey;
		const response = await (userType === "USER"
			? getUserProfileApi.request(id)
			: getCompanyProfileApi.request(id));
		return response.data;
	};

	const isOwnProfile = paramsUrl === url || !paramsUrl;

	const { data: urlData, isSuccess: isUrlSuccess } = useQuery({
		queryKey: profileKeys.profileByUrl(paramsUrl),
		queryFn: fetchProfileByUrl,
		enabled: !isOwnProfile,
	});

	const {
		data: profileData,
		isSuccess: isProfileSuccess,
		isFetching,
	} = useQuery({
		queryKey: profileKeys.profileByUuid(
			isOwnProfile ? uuid : urlData?.uuid,
			isOwnProfile ? type : urlData?.type
		),
		queryFn: fetchProfileByUuid,
		enabled: isOwnProfile || (isUrlSuccess && !!urlData?.uuid),
	});

	useEffect(() => {
		if (isProfileSuccess && profileData) {
			const isCurrentUser = profileData.uuid === uuid;
			setCanEdit(isCurrentUser);

			if (isCurrentUser) {
				if (profileData.type === "USER") {
					dispatch(profileInfoUpdated(profileData));
				} else if (profileData.type === "COMPANY") {
					dispatch(companyInfoUpdated(profileData));
				}
			}
		}
	}, [isProfileSuccess, profileData, uuid, dispatch]);

	return { profileData, isProfileSuccess, isFetching, canEdit };
};

export default useFetchProfile;
