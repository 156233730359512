import React from "react";
// import { shallowEqual } from "react-redux";
// import Work from "images/work-grey-16-x-16.svg";
// import Friend from "images/friends-grey-16-x-16.svg";
// import ME from "images/me-grey-100.svg";
import meIcon from "images/privacy-icons/me.svg";
import allIcon from "images/privacy-icons/all.svg";
import workIcon from "images/privacy-icons/work.svg";
import friendIcon from "images/privacy-icons/friend.svg";
import publicIcon from "images/privacy-icons/public.svg";
import emojiRegex from "emoji-regex/RGI_Emoji";
import otherIcon from "images/other_icon.svg";
import pdfIcon from "images/pdf_icon.svg";
import dwgIcon from "images/dwg_icon.svg";
import exeIcon from "images/exe_icon.svg";
import htmlIcon from "images/html_icon.svg";
import pptIcon from "images/ppt_icon.svg";
import xlsIcon from "images/xls_icon.svg";
import docIcon from "images/doc_icon.svg";
import fontIcon from "images/ttf_icon.svg";
import zipIcon from "images/zip_icon.svg";
import txtIcon from "images/txt_icon.svg";
import inddIcon from "images/indd_icon.svg";
import psdIcon from "images/psd_icon.svg";
import aiIcon from "images/ai_icon.svg";
import audioIcon from "images/audio_icon.svg";
import svgIcon from "images/svg_icon.svg";
// import playIcon from "images/play-icon-blue.svg";
import playIcon from "images/play-button.svg";

import gaawkGray from "images/reaction-default.svg";
import gaawk from "images/reaction-gaawk.svg";
import Love from "images/reaction-love.svg";
import Wow from "images/reaction-wow.svg";
import Haha from "images/reaction-haha.svg";
import Sad from "images/reaction-sad.svg";
import Angry from "images/reaction-angry.svg";
import Insightful from "images/reaction-insightful.svg";
import { format, differenceInDays, formatDistanceToNow } from "date-fns";
import { purgeStoredState } from "redux-persist";
import { persistConfig } from "store/configureStore";
import { videoToImage } from "./videoToImage";
import { toast } from "react-toastify";
import routes from "components/Routing/routing-keys";

export const headerId = () => {
	if (localStorage.getItem("uuid")) return localStorage.getItem("uuid");
	else if (sessionStorage.getItem("uuid"))
		return sessionStorage.getItem("uuid");
	else window.location = "/register";
};

export const getProfileId = () => {
	if (localStorage.getItem("uuid")) return localStorage.getItem("uuid");
	else if (sessionStorage.getItem("uuid"))
		return sessionStorage.getItem("uuid");
	else return null;
};

export const getDiffIn = (date) => {
	return formatDistanceToNow(new Date(date), {
		addSuffix: true,
		locale: { formatDistance },
	});
};

const formatDistance = (token, count, options) => {
	options = options || {};

	const result = formatDistanceLocale[token].replace("{{count}}", count);

	if (options.addSuffix) {
		if (options.comparison > 0) {
			return "in " + result;
		} else {
			return result + " ago";
		}
	}

	return result;
};

const formatDistanceLocale = {
	lessThanXSeconds: "{{count}}s",
	xSeconds: "{{count}}s",
	halfAMinute: "30s",
	lessThanXMinutes: "{{count}}m",
	xMinutes: "{{count}}m",
	aboutXHours: "{{count}}h",
	xHours: "{{count}}h",
	xDays: "{{count}}d",
	aboutXWeeks: "{{count}}w",
	xWeeks: "{{count}}w",
	aboutXMonths: "{{count}}m",
	xMonths: "{{count}}m",
	aboutXYears: "{{count}}y",
	xYears: "{{count}}y",
	overXYears: "{{count}}y",
	almostXYears: "{{count}}y",
};

export const dateToFromNowDaily = (myDate) => {
	const diffInDays = differenceInDays(new Date(myDate), new Date());
	if (diffInDays <= -2) {
		return format(new Date(myDate), "dd/MM/yyyy");
	} else if (diffInDays <= -1) {
		return "Yesterday";
	} else {
		return format(new Date(myDate), "h:mm aaa");
	}
};

export const getVisibility = (visibility) => {
	switch (visibility) {
		case "WORK_CIRCLE_FRIENDS":
			return <img src={allIcon} alt="all connections" />;

		case "ME":
			return <img src={meIcon} alt="me only" />;

		case "PUBLIC":
			return <img src={publicIcon} alt="public" />;

		case "WORK_CIRCLE":
			return <img src={workIcon} alt="work" />;

		case "FRIENDS":
			return <img src={friendIcon} alt="friends" />;

		default:
			return "";
	}
};

export const logout = () => {
	if (sessionStorage.getItem("uuid")) {
		sessionStorage.removeItem("uuid");
	}
	if (localStorage.getItem("uuid")) {
		localStorage.removeItem("uuid");
	}

	if (localStorage.getItem("companyId")) {
		localStorage.removeItem("companyId");
	}

	purgeStoredState(persistConfig);

	window.location = routes.login;
};

export const monthNames = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];

// export const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export const monthDropdown = [
	{
		label: "January",
		value: 1,
	},
	{
		label: "February",
		value: 2,
	},
	{
		label: "March",
		value: 3,
	},
	{
		label: "April",
		value: 4,
	},
	{
		label: "May",
		value: 5,
	},
	{
		label: "June",
		value: 6,
	},
	{
		label: "July",
		value: 7,
	},
	{
		label: "August",
		value: 8,
	},
	{
		label: "September",
		value: 9,
	},
	{
		label: "October",
		value: 10,
	},
	{
		label: "November",
		value: 11,
	},
	{
		label: "December",
		value: 12,
	},
];

export const range = (start, stop, step) =>
	Array.from(
		{ length: (stop - start) / step + 1 },
		(_, i) => start + i * step
	).map((item) => ({
		label: item.toString(),
		value: item,
	}));

export const getReadableValue = (value) => {
	switch (value) {
		case "ME":
			return "Only Me";

		case "WORK_CIRCLE_FRIENDS":
			return "All connections";

		case "FRIENDS":
			return "Friends";

		case "WORK_CIRCLE":
			return "Work Circle";

		default:
			return "Public";
	}
};

export const comparer = (otherArray) => {
	return function (current) {
		return (
			otherArray.filter(function (other) {
				return other.uuid === current.uuid;
			}).length === 0
		);
	};
};

export const getClickableLink = (link) => {
	return link.startsWith("http://") || link.startsWith("https://")
		? link
		: `http://${link}`;
};

const removeEmojis = (string) => {
	if (!string) return "";

	return string.replace(emojiRegex(), "");
};

export const isEmojisOnly = (string) => removeEmojis(string).length === 0;

export const getFileExtension = (fileName) => {
	return fileName.substr(fileName.lastIndexOf(".") + 1);
};

export const removeFileExtension = (fileName) => {
	return fileName.replace(/\.[^/.]+$/, "");
};

export const convertFileToBase64 = (file) => {
	return new Promise((resolve) => {
		let baseURL = "";
		let reader = new FileReader();

		// Convert the file to base64 text
		reader.readAsDataURL(file);

		// on reader load somthing...
		reader.onload = () => {
			baseURL = reader.result;
			resolve(baseURL);
		};
	});
};

export const formatBytes = (bytes, decimals = 2) => {
	if (bytes === 0) return "0 MB";

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const checkOverflow = (el) => {
	const curOverflow = el.style.overflow;

	if (!curOverflow || curOverflow === "visible") el.style.overflow = "hidden";

	const isOverflowing =
		el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;

	el.style.overflow = curOverflow;

	return isOverflowing;
};

export const employementTypes = [
	{
		label: "Full Time",
		value: "FULL_TIME",
	},
	{
		label: "Part Time",
		value: "PART_TIME",
	},
	{
		label: "Self-Employed",
		value: "SELF_EMPLOYED",
	},
	{
		label: "Freelance",
		value: "FREELANCE",
	},
	{
		label: "Contract",
		value: "CONTRACT",
	},
	{
		label: "Internship",
		value: "INTERNSHIP",
	},
	{
		label: "Apprenticeship",
		value: "APPRENTICESHIP",
	},
	{
		label: "Volunteering",
		value: "VOLUNTEERING",
	},
	{
		label: "Casting call",
		value: "CASTING_CALLS",
	},
];

export const specialProfileTypes = [
	{
		label: "Singer / Songwriter",
		value: "SINGER_SONGWRITER",
	},
	{
		label: "Voice Over Artist",
		value: "VOICE_OVER_ARTIST",
	},
	{
		label: "Actor / Actress",
		value: "ACTOR_ACTRESS",
	},
	{
		label: "Model",
		value: "MODEL",
	},
	{
		label: "Entertainer",
		value: "ENTERTAINER",
	},
	{
		label: "Dancer",
		value: "DANCER",
	},
];

export const companySizeList = [
	{
		label: "0 - 50",
		value: "SIZE_0_50",
	},
	{
		label: "50 - 100",
		value: "SIZE_50_100",
	},
	{
		label: "100 - 500",
		value: "SIZE_100_500",
	},
	{
		label: "More than 500",
		value: "SIZE_MORE_500",
	},
];

export const measurementUnits = [
	{
		label: "Millimeters",
		value: "MILLIMETER",
	},
	{
		label: "Centimeters",
		value: "CENTIMETER",
	},
	{
		label: "Meters",
		value: "METER",
	},
	{
		label: "Kilometers",
		value: "KILOMETER",
	},
];
export const measurementUnitsCuts = [
	{
		label: "mm",
		value: "MILLIMETER",
	},
	{
		label: "cm",
		value: "CENTIMETER",
	},
	{
		label: "m",
		value: "METER",
	},
	{
		label: "km",
		value: "KILOMETER",
	},
];

export const weightUnits = [
	{
		label: "Grams",
		value: "GRAM",
	},
	{
		label: "Kilograms",
		value: "KG",
	},
	{
		label: "Tons",
		value: "TON",
	},
];

export const replaceItemInArray = (
	item,
	arr,
	identifier,
	appendOnNoneExists = false
) => {
	// get the index
	const index = arr.findIndex((i) => i[identifier] === item[identifier]);

	// copy arr
	const copiedArr = [...arr];

	// not found
	if (index === -1) {
		if (appendOnNoneExists) return [item, ...arr];
		else return arr;
	}

	// replace item in copied array
	copiedArr[index] = item;

	// return copied array
	return copiedArr;
};

export const featuresSection = {
	people: "PEOPLE",
	companies: "COMPANIES",
	vault: "VAULT",
	boards: "BOARDS",
	products: "PRODUCTS",
	services: "SERVICES",
	jobs: "JOBS",
};

// export const removeItemFromArray = (item, arr, identifier) => {
//
//     // get the index
//     const index = arr.findIndex(i => i[identifier] === item[identifier]);
//
//     // doesn't exists
//     if (index === -1)
//         return arr;
//
//     return arr.filter(i => i[identifier] !== item[identifier]);
//
// }

// to check shallow equality between 2 objects:
// const objectsEqual = (o1, o2) =>
//     Object.keys(o1).length === Object.keys(o2).length &&
//     Object.keys(o1).every((p) => o1[p] === o2[p]);

export const removeItemsFromArray = (items, arr) => {
	return arr.filter((v) => !items.includes(v));
};

export const removeItemIdFromArray = (items, arr) => {
	const idArray = items.map((item) => item.uuid);
	return arr.filter((v) => !idArray.includes(v.uuid));
};

export const removeItemPropFromArray = (items, arr, property) => {
	const propArray = items.map((item) => item[property]);
	return arr.filter((v) => !propArray.includes(v[property]));
};

export const getFileIcon = (file, fileName = "") => {
	const ext = getFileExtension(fileName);

	let icon;
	let isPhoto = false;

	switch (ext) {
		case "png":
		case "jpg":
		case "jpeg":
			if (file instanceof File) {
				icon = URL.createObjectURL(file);
			} else {
				icon = file.customName || file.url;
			}
			isPhoto = true;
			break;

		case "svg":
			icon = svgIcon;
			break;

		case "mp4":
			icon = playIcon;
			break;

		case "dwg":
			icon = dwgIcon;
			break;

		case "exe":
			icon = exeIcon;
			break;

		case "htm":
		case "html":
			icon = htmlIcon;
			break;

		case "ppt":
		case "pps":
		case "pptx":
			icon = pptIcon;
			break;

		case "xls":
		case "xlsx":
			icon = xlsIcon;
			break;

		case "doc":
		case "docx":
			icon = docIcon;
			break;

		case "pdf":
			icon = pdfIcon;
			break;

		case "ttf":
		case "otf":
			icon = fontIcon;
			break;

		case "zip":
		case "rar":
			icon = zipIcon;
			break;

		case "txt":
		case "rtf":
			icon = txtIcon;
			break;

		case "indd":
			icon = inddIcon;
			break;

		case "psd":
			icon = psdIcon;
			break;

		case "ai":
			icon = aiIcon;
			break;

		case "mp3":
			icon = audioIcon;
			break;

		default:
			icon = otherIcon;
	}

	return { icon, isPhoto, ext };
};

export const isLogged = (isDataValid) => {
	if (
		(localStorage.getItem("uuid") || sessionStorage.getItem("uuid")) &&
		isDataValid
	) {
		return true;
	}
};

export const pluralize = (count, word) => {
	if (count > 1) return `${word}s`;
	else return word;
};

export const customGenderValues = [
	{ value: "she", label: 'She - "Wish her a happy birthday"' },
	{ value: "he", label: 'He - "Wish him a happy birthday"' },
	{ value: "they", label: 'They - "Wish them a happy birthday"' },
];

export const urlRegex = /^[a-zA-Z0-9._]+$/;

export const passwordRegex =
	/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;

export const viewerMapper = (array) => {
	return array?.map((item) => {
		const fileObject = item.media
			? {
					...item.media.file,
					favorite: item.media.favorite,
					...(item.conversationId && { message: item.message }),
			  }
			: { ...item.file, favorite: item.favorite };

		fileObject["url"] = fileObject["customName"];
		fileObject["mediaType"] = fileObject["previewType"];
		fileObject["fileId"] = item.uuid; //*NEEDED FOR SPECIAL-PROFILE MEDIA

		delete fileObject["customName"];
		delete fileObject["previewType"];

		return fileObject;
	});
};

export const copyToClipboard = (content) => {
	navigator.clipboard.writeText(content);
};

export const getReactionImg = (reaction) => {
	switch (reaction) {
		case "gaawk":
			return gaawk;
		case "Love":
			return Love;
		case "Wow":
			return Wow;
		case "Haha":
			return Haha;
		case "Sad":
			return Sad;
		case "Angry":
			return Angry;
		case "Insightful":
			return Insightful;
		default:
			return gaawkGray;
	}
};

export const entryTypes = [
	{ label: "Booked", value: "Booked" },
	{ label: "Penciled", value: "Penciled" },
	{ label: "Blacked Out", value: "Blacked_Out" },
];

export const frequencyTypes = [
	{ label: "Hourly", value: "HOURLY" },
	{ label: "Daily", value: "DAILY" },
	{ label: "Monthly", value: "MONTHLY" },
	{ label: "Yearly", value: "YEARLY" },
];

export const postedDateTypes = [
	{
		label: "Any Time",
		value: "NONE",
	},
	{
		label: "Last 24 Hours",
		value: "LAST_DAY",
	},
	{
		label: "Last Week",
		value: "LAST_WEEK",
	},
	{
		label: "Last Month",
		value: "LAST_MONTH",
	},
];

export const workLocation = [
	{
		label: "On Site",
		value: "OFFICE",
	},
	{
		label: "Remote",
		value: "REMOTE",
	},
	{
		label: "Hybrid",
		value: "HYBRID",
	},
];
export const privacyOptions = [
	{
		label: "Everyone",
		value: "EVERYONE",
	},
	{
		label: "Connections only",
		value: "CONNECTIONS",
	},
	// {
	//     label: "No one",
	//     value: "NO_ONE",
	// },
];

export const getWorkingLocation = (location) => {
	if (location === "OFFICE") {
		return "On Site";
	} else {
		return `${location[0].toUpperCase() + location.slice(1).toLowerCase()}`;
	}
};

export const dubaiDefaultLocation = {
	lat: 25.266666,
	lng: 55.316666,
	id: 4818,
};

export const movePageItems = (pages, maxItemsPerPage) => {
	// const splitArray = (array, size) => {
	//     let result = [];
	//     for (let i = 0; i < array.length; i += size) {
	//         let chunk = array.slice(i, i + size);
	//         result.push(chunk);
	//     }
	//     return result;
	// };

	let addItemsForNextPage = [];
	return pages.reduce((resultArray, page, i) => {
		if (addItemsForNextPage.length > 0) {
			//if there are items to add from the previous page, push them to the front of this page
			page.unshift(...addItemsForNextPage);
			addItemsForNextPage = [];
		}

		if (page.length >= maxItemsPerPage) {
			// transfer x number of excess items beyond the max item limit
			addItemsForNextPage = page.splice(maxItemsPerPage, page.length);
		}

		resultArray.push(page);

		//* this piece of code (if uncommented) will create a NEW page for remaining items
		// if (pages.length - 1 === i && addItemsForNextPage.length > 0)
		//     resultArray.push(
		//         ...splitArray(addItemsForNextPage, maxItemsPerPage)
		//     );

		return resultArray;
	}, []);
};

export const splitName = (fullName) => {
	const parts = fullName.split(" ");
	const firstName = parts[0];
	const lastName = parts.slice(1).join(" ");
	return { firstName, lastName };
};

export const jobTypes = {
	all: 0,
	long_term: 1,
	freelance: 2,
	casting: 3,
};

export const jobTypeOptions = [
	{ label: "All", value: jobTypes.all },
	{ label: "Jobs", value: jobTypes.long_term },
	{ label: "Freelance", value: jobTypes.freelance },
	{ label: "Casting Call", value: jobTypes.casting },
];

export const genderTypes = [
	{ label: "All", value: 2 },
	{ label: "Male", value: 0 },
	{ label: "Female", value: 1 },
];

export const notificationCategory = {
	ALL: "All",
	CONNECTIONS: "Connections",
	JOBS: "Jobs",
	POST_MEDIA_GALLERY: "Post & Media",
	COMPANIES: "Companies",
	VAULT: "Vault",
	OTHER: "Other",
	MESSAGES: "Messages",
	MARKETING: "Marketing",
};

export const getLocationType = (locationTypes) => {
	const inLocations = [
		"locality",
		"sublocality",
		"postal_code",
		"country",
		"administrative_area_level_1",
		"administrative_area_level_2",
		"neighborhood",
		"airport",
		"shopping_mall",
	];

	const atLocations = [
		"point_of_interest",
		"establishment",
		"transit_station",
		"intersection",
	];

	const contextDependentLocations = [
		"park",
		"school",
		"university",
		"library",
		"museum",
	];

	if (locationTypes.some((type) => inLocations.includes(type))) {
		return "in";
	}
	if (locationTypes.some((type) => atLocations.includes(type))) {
		return "at";
	}
	if (
		locationTypes.some((type) => contextDependentLocations.includes(type))
	) {
		return "at";
	}

	// Default to "at" for any unspecified types
	return "at";
};

export const getVideoFrame = async (url, originalName) => {
	const videoThumbnail = await videoToImage(url, {
		filename: originalName,
	}).then((image) => {
		return URL.createObjectURL(image);
		// console.log("THE IMAGE >>", image);
		// videoThumbnail = URL.createObjectURL(image);
	});
	return videoThumbnail;
};

export const successfulChangeToast = () => {
	toast.success("Changes saved!");
};

// Configuration
const COUNT_FORMATS = [
	{
		// 0 - 999
		letter: "",
		limit: 1e3,
	},
	{
		// 1,000 - 999,999
		letter: "K",
		limit: 1e6,
	},
	{
		// 1,000,000 - 999,999,999
		letter: "M",
		limit: 1e9,
	},
	{
		// 1,000,000,000 - 999,999,999,999
		letter: "B",
		limit: 1e12,
	},
	{
		// 1,000,000,000,000 - 999,999,999,999,999
		letter: "T",
		limit: 1e15,
	},
];

// Format Method:
export const formatCount = (value) => {
	const format = COUNT_FORMATS.find((format) => value < format.limit);

	value = (1000 * value) / format.limit;
	value = Math.floor(value * 10) / 10; // keep one decimal number, only if needed

	return value + format.letter;
};

export const cssValue = (property) => {
	return getComputedStyle(document.documentElement).getPropertyValue(
		property
	);
};

export const packageTypes = {
	free: "FREE",
	premium: "PREMIUM",
	aaa: "AAA",
	custom: "CUSTOM",
};

export const capitalize = (word) => {
	return word?.charAt(0) + word?.slice(1).toLowerCase();
};

export const storageWarningThreshold = 80;

export const showStorageWarning = (usedStorageInPercentage, vaultInfo) => {
	const {
		usedProductsCount,
		usedServicesCount,
		currentPlan: { products, services } = {},
	} = vaultInfo || {};

	if (
		usedStorageInPercentage >= storageWarningThreshold ||
		usedProductsCount >= products ||
		usedServicesCount >= services
	) {
		return true;
	} else return false;
};

export const getStorageWarningText = (
	category = "default", //* possible values = "productsAndServices" || "default"
	usedSpaceInPercentage,
	vaultInfo
) => {
	const { usedProductsCount, usedServicesCount, currentPlan, nextPlan } =
		vaultInfo || {};

	const { products, services } = currentPlan || {};

	const currentPlanLabel =
		currentPlan.paymentPackageType === packageTypes.aaa
			? currentPlan.paymentPackageType
			: capitalize(currentPlan.paymentPackageType);

	const nextPlanLabel =
		nextPlan.paymentPackageType === packageTypes.aaa
			? nextPlan.paymentPackageType
			: capitalize(nextPlan.paymentPackageType);

	const getUpgradeSentence = (type = "storage") => {
		if (nextPlan.paymentPackageType === packageTypes.custom) {
			return "Talk to us for Custom tailored Package.";
		} else {
			if (type === "storage") {
				return `Get ${nextPlan.storage} GB of storage free
							for ${nextPlan.trial / 30} months.`;
			} else {
				return `Upgrade to ${nextPlanLabel} for more options.`;
			}
		}
	};

	const defaultStorageSentence = () => {
		return `${usedSpaceInPercentage}% of
							the storage has been used. You may have trouble uploading or sending files.`;
	};

	switch (category) {
		case "productsAndServices":
			if (
				(usedProductsCount >= products ||
					usedServicesCount >= services) &&
				usedSpaceInPercentage < storageWarningThreshold
			) {
				// * case where only products / services reached limit

				return `With the ${currentPlanLabel} plan, you can add ${products} ${pluralize(
					products,
					"product"
				)} and ${services} ${pluralize(
					services,
					"service"
				)}. ${getUpgradeSentence("products")}`;
			} else if (
				(usedProductsCount >= products ||
					usedServicesCount >= services) &&
				usedSpaceInPercentage > storageWarningThreshold
			) {
				// * case where everything reached the limit

				return `${defaultStorageSentence()} Your ${currentPlanLabel} plan allows ${products} ${pluralize(
					products,
					"product"
				)} and ${services} ${pluralize(
					services,
					"service"
				)}. ${getUpgradeSentence("products")}`;
			} else {
				// * default case, only the storage reached the limit

				return `${defaultStorageSentence()} ${getUpgradeSentence()}`;
			}

		case "homeSidebar":
			if (usedSpaceInPercentage > storageWarningThreshold) {
				return `${defaultStorageSentence()} ${getUpgradeSentence()}`;
			} else {
				return `${usedSpaceInPercentage}% of
							the storage has been used. ${getUpgradeSentence()}`;
			}

		default:
			return `${defaultStorageSentence()} ${getUpgradeSentence()}`;
	}
};

export const getMapsLink = (location) => {
	if (location.placeId) {
		return `https://www.google.com/maps/place/?q=place_id:${location.placeId}`;
	} else {
		return `https://www.google.com/maps/search/?api=1&query=${`${location.latitude},${location.longitude}`}`;
	}
};
