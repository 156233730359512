import React, { useState, useEffect, useRef } from "react";
import styles from "./CommentItem.module.css";

import { useNavigate } from "react-router-dom";

// import { getDiffIn } from "../Utils/General";
import ProfilePic from "./ProfilePic";
import moreIcon from "images/three-dots-black-5-x-20.svg";
import replyIcon from "images/reply-icon.svg";
// import Comment from "images/comment-black.svg";
import binIcon from "images/bin-icon.svg";
import editIcon from "images/rename-icon.svg";
import FormatSpecialText from "../Utils/SubComs/FormatSpecialText/FormatSpecialText";
// import Reaction from "./Reaction";

import CommentInput from "./CommentInput";
// import CustomTextarea from "../Utils/SubComs/CustomTextarea/CustomTextarea";
import Replies from "./Replies";
import postApi from "../../api/post";
import useApi from "../../hooks/useApi";
import Popover from "../Utils/Popover/Popover";
// import Reactions from "../Utils/Reactions/Reactions";
import CommentReactionsCounter from "../Utils/Reactions/CommentReactionsCounter";
import ReadMore from "../Utils/SubComs/ReadMore/ReadMore";
import routes from "components/Routing/routing-keys";

const CommentItem = ({
	comment,
	postId,
	onCommentUpdate,
	onUpdateRepliesCount,
	profileImg,
	type,
	url,
	onTextChange,
	editedText,
	onDelete,
	isFullscreenPost,
	name,
}) => {
	const addCommentReactionApi = useApi(postApi.addCommentReaction);
	const removeCommentReactionApi = useApi(postApi.removeCommentReaction);
	const updateCommentApi = useApi(postApi.updateComment);
	// const deleteCommentApi = useApi(postApi.deleteComment);

	const navigate = useNavigate();

	const didMountRef = useRef(false);

	const repliesRef = useRef(null);
	const popoverRef = useRef(null);

	const [showReplies, setShowReplies] = useState(false);

	// !======= REACTION HANDLERS ==============

	const handleAddReaction = (reactionType) => {
		// console.log("THE REACTION TYPE >>", reactionType);
		if (comment.profileReactionType === reactionType)
			removeCommentReactionApi.request(comment.uuid);
		else addCommentReactionApi.request(comment.uuid, reactionType);
		// setShowReactions(false);
	};

	const handleReactionBtnClicked = () => {
		if (comment.profileReactionType)
			removeCommentReactionApi.request(comment.uuid);
		else addCommentReactionApi.request(comment.uuid, "gaawk");
	};

	useEffect(() => {
		if (
			!addCommentReactionApi.loading &&
			addCommentReactionApi.responseCode === 200 &&
			addCommentReactionApi.data
		) {
			onCommentUpdate(addCommentReactionApi.data);
		}
	}, [addCommentReactionApi.data]);

	useEffect(() => {
		if (
			!removeCommentReactionApi.loading &&
			removeCommentReactionApi.responseCode === 200 &&
			removeCommentReactionApi.data
		) {
			onCommentUpdate(removeCommentReactionApi.data);
		}
	}, [removeCommentReactionApi.data]);

	// ! ========== comment edit item ==========

	const [isEditing, setIsEditing] = useState(false);

	const handleEdit = () => {
		setIsEditing((prevState) => !prevState);
		onTextChange(comment.comment);
	};

	const handleConfirmComment = () => {
		updateCommentApi.request({
			comment: editedText,
			commentId: comment.uuid,
			postId,
		});
	};

	useEffect(() => {
		if (
			!updateCommentApi.loading &&
			updateCommentApi.responseCode === 200 &&
			updateCommentApi.data
		) {
			onCommentUpdate(updateCommentApi.data);
			setIsEditing(false);
		}
	}, [updateCommentApi.data]);

	const handleEmojiClick = (_, emojiObject) => {
		onTextChange(editedText + emojiObject.emoji);
	};

	const handleCancelEdit = () => {
		setIsEditing(false);
		onTextChange("");
	};

	// ! ==== reply   handler =====

	const handleReply = () => {
		setShowReplies(true);
	};

	useEffect(() => {
		if (didMountRef.current) {
			repliesRef.current.replyComment(comment.profile.url);
		}
		didMountRef.current = true;
	}, [showReplies]);

	return (
		<>
			{!isEditing ? (
				<div className={styles.comment_item} key={comment.uuid}>
					<ProfilePic
						thumbnail={
							comment.profile.profileImage
								? comment.profile.profileImage.image
								: undefined
						}
						type={comment.profile.type}
						url={comment.profile.url}
						name={name}
						enableName={false}
					/>

					<div className={styles.comment_flex}>
						<div className={styles.comment}>
							<div className={styles.comment_header}>
								<label
									className={styles.owner}
									onClick={() =>
										navigate(
											routes.profile(comment.profile.url)
										)
									}
								>
									{comment.profile.name}
								</label>

								<Popover
									ref={popoverRef}
									render={
										<div className="popover_container">
											<button
												className="popover_item"
												onClick={() => {
													setShowReplies(
														(prevState) =>
															!prevState
													);
													popoverRef.current.closePopover();
												}}
											>
												<img
													src={replyIcon}
													alt="reply"
												/>
												<span>Reply</span>
											</button>
											<button
												className="popover_item"
												onClick={handleEdit}
											>
												<img
													src={editIcon}
													alt="forward"
												/>
												<span>Edit</span>
											</button>
											<button
												className="popover_item"
												onClick={() =>
													onDelete(comment.uuid)
												}
											>
												<img
													src={binIcon}
													alt="delete"
												/>
												<span>Delete</span>
											</button>
										</div>
									}
								>
									<button className={styles.option_icon}>
										<img src={moreIcon} alt="options" />
									</button>
								</Popover>
							</div>

							<ReadMore>
								<FormatSpecialText
									text={comment.comment}
									customStyle={styles.reply_text}
								/>
							</ReadMore>
						</div>

						<CommentReactionsCounter
							item={comment}
							onReply={handleReply}
							onClick={handleReactionBtnClicked}
							onReact={handleAddReaction}
							isFullscreenPost={isFullscreenPost}
						/>
					</div>

					{showReplies && (
						<Replies
							ref={repliesRef}
							commentId={comment.uuid}
							repliesCount={comment.repliesCount}
							ownerUrl={comment.profile.url}
							onUpdateRepliesCount={onUpdateRepliesCount}
						/>
					)}
				</div>
			) : (
				<>
					<CommentInput
						profileImg={profileImg}
						name={name}
						type={type}
						url={url}
						onTextChange={onTextChange}
						text={editedText}
						// onQuery={handleQuery}
						// onKeyPress={handleKeyPress}
						onEmojiClick={handleEmojiClick}
						onCancel={handleCancelEdit}
						isEditing={isEditing}
						onConfirm={handleConfirmComment}
					/>
				</>
			)}
		</>
	);
};

export default CommentItem;
