import Joi from "joi";

const createIntegerSchema = (label, max) =>
	Joi.number()
		.integer()
		.allow("")
		.max(max)
		.label(label)
		.messages({
			"number.base": `${label} must be a number`,
			"number.integer": `${label} must be an integer`,
			"number.max": `${label} can't be more than ${max}`,
		});

const physicalDetailsSchema = Joi.object({
	height: createIntegerSchema("Height", 300),
	weight: createIntegerSchema("Weight", 999),
	waist: createIntegerSchema("Waist", 999),
	chest: createIntegerSchema("Chest", 999),
	shoe: createIntegerSchema("Shoe Size", 100),
	build: Joi.object({
		value: Joi.string(),
		label: Joi.string(),
	})
		.label("Build")
		.allow(null),
	eyeColor: Joi.object({
		value: Joi.string(),
		label: Joi.string(),
	})
		.label("Eye Color")
		.allow(null),
	hairType: Joi.object({
		value: Joi.string(),
		label: Joi.string(),
	})
		.label("Hair Type")
		.allow(null),
	hairColor: Joi.object({
		value: Joi.string(),
		label: Joi.string(),
	})
		.label("Hair Color")
		.allow(null),
	hairLength: Joi.object({
		value: Joi.string(),
		label: Joi.string(),
	})
		.label("Hair Length")
		.allow(null),
});

export default physicalDetailsSchema;
