import useDirectMessage from "hooks/useDirectMessage";
import styles from "./ConnectionActions.module.scss";
import { useRef, useState } from "react";
import Popover from "components/Utils/Popover/Popover";
// import approvedIcon from "images/approved-friend-icon.svg";
// import addWorkIcon from "images/add-work-40-x-35.svg";
// import addedWorkIcon from "images/added-work-icon.svg";
// import chatIcon from "images/chatIcon.svg";
import friendsIcon from "images/connection-icons/friends.svg";
import addFriendIcon from "images/add-friend-40-x-35.svg";
import workIcon from "images/work-circle-black.svg";
import pendingIcon from "images/pending-friend-icon.svg";
import followIcon from "images/follow-icon.svg";
import favoriteIcon from "images/connection-icons/favorite.svg";
import unfavoriteIcon from "images/icon-metro-star-full.svg";
import downloadIcon from "images/download-black.svg";
import blockBlackIcon from "images/connection-icons/block.svg";
import reportIcon from "images/report-black-icon.svg";
import options from "images/three-dots-black-5-x-20.svg";
// import blockIcon from "images/block-icon.svg";
import EditButton from "components/Utils/Button/EditButton";
import useMutate from "hooks/useMutate";
import connectionApi from "api/connection";
import profileApi from "api/profile";
import ConnectionModal from "./ConnectionModal";
import { ReactComponent as WorkIcon } from "images/connection-icons/work.svg";
import { ReactComponent as FriendIcon } from "images/connection-icons/friends.svg";
// import { ReactComponent as TickIcon } from "images/tick.svg";
import { ReactComponent as PendingIcon } from "images/pending.svg";
import ReportModal from "components/Utils/ReportModal/ReportModal";
import { useQueryClient } from "@tanstack/react-query";
import { profileKeys } from "queryKeys/profile-key-factory";
import useCurrentUser from "hooks/useCurrentUser";
// import { ReactComponent as BlockIcon } from "images/connection-icons/block.svg";

const ConnectionActions = ({ canEdit, onClickEdit, userInfo, pathname }) => {
	const {
		type,
		connection,
		uuid,
		firstName = "",
		lastName = "",
		companyUserType,
	} = userInfo || {};

	const { type: currentUserType } = useCurrentUser();
	const isCurrentUserACompany = currentUserType === "COMPANY";

	const reportRef = useRef();

	const { handleDirectMessage } = useDirectMessage();

	const [showConnectionModal, setShowConnectionModal] = useState(false);
	const [modalAction, setModalAction] = useState("");

	const popoverRef = useRef(null);

	const queryClient = useQueryClient();

	const invalidateProfile = () => {
		queryClient.invalidateQueries(profileKeys.details());
	};

	// ! ========== modal connection handlers ===============

	const {
		action: { mutate: addWorkCircle },
	} = useMutate(connectionApi.addWorkCircle, invalidateProfile);

	const {
		action: { mutate: removeWorkCircle },
	} = useMutate(connectionApi.removeWorkCircle, invalidateProfile);

	const {
		action: { mutate: follow },
	} = useMutate(connectionApi.follow, invalidateProfile);

	const {
		action: { mutate: unfollow },
	} = useMutate(connectionApi.unfollow, invalidateProfile);

	const {
		action: { mutate: sendFriendRequest },
	} = useMutate(connectionApi.sendFriendRequest, invalidateProfile);

	const {
		action: { mutate: revokeFriendRequest },
	} = useMutate(connectionApi.revokeFriendRequest, invalidateProfile);

	const {
		action: { mutate: deleteFriend },
	} = useMutate(connectionApi.deleteFriend, invalidateProfile);

	const {
		action: { mutate: declineFriend },
	} = useMutate(connectionApi.declineFriend, invalidateProfile);

	const {
		action: { mutate: acceptFriend },
	} = useMutate(connectionApi.acceptFriend, invalidateProfile);

	const {
		action: { mutate: block },
	} = useMutate(profileApi.block, invalidateProfile);

	const {
		action: { mutate: unblock },
	} = useMutate(profileApi.unblock, invalidateProfile);

	const {
		action: { mutate: favorite },
	} = useMutate(profileApi.addFavorite, invalidateProfile);

	const {
		action: { mutate: unfavorite },
	} = useMutate(profileApi.removeFavorite, invalidateProfile);

	const handleModalAction = (action) => () => {
		popoverRef.current?.closePopover();
		setModalAction(action);
		setShowConnectionModal(true);
	};

	const handleModalConfirm = (action) => {
		switch (action) {
			case "ADD_WORK":
				addWorkCircle(uuid);
				break;
			case "REMOVE_WORK":
				removeWorkCircle(uuid);
				break;
			case "ADD_FRIEND":
				sendFriendRequest(uuid);
				break;
			case "REVOKE_FRIEND":
				revokeFriendRequest(uuid);
				break;
			case "ACCEPT_FRIEND":
				acceptFriend(uuid);
				break;
			case "DECLINE_FRIEND":
				declineFriend(uuid);
				break;
			case "REMOVE_FRIEND":
				deleteFriend(uuid);
				break;
			case "FOLLOW":
				follow(uuid);
				break;
			case "UNFOLLOW":
				unfollow(uuid);
				break;
			case "BLOCK":
				block(uuid);
				break;
			case "UNBLOCK":
				unblock(uuid);
				break;
			default:
				break;
		}
		setShowConnectionModal(false);
	};

	return (
		<>
			{canEdit ? (
				<div className={`${styles.container} ${styles.centered}`}>
					<EditButton className onClick={onClickEdit} />
				</div>
			) : connection?.blocking ? (
				<div className={`${styles.container} ${styles.block_btn}`}>
					<button
						className={`${styles.btn} ${styles.black}`}
						onClick={handleModalAction("UNBLOCK")}
					>
						Unblock
					</button>
				</div>
			) : connection?.blocked ? (
				<div className={`${styles.container} ${styles.centered}`}>
					<div className={`${styles.btn} ${styles.outline}`}>
						No Access
					</div>
				</div>
			) : (
				connection && (
					<div className={styles.container}>
						{!isCurrentUserACompany &&
							type !== "COMPANY" &&
							connection.friendStatus === "APPROVED" && (
								<button
									className={`${styles.btn} ${styles.outline}`}
									onClick={handleModalAction("REMOVE_FRIEND")}
								>
									<FriendIcon className={styles.icon} />
									Friends
								</button>
							)}

						{!isCurrentUserACompany &&
							type !== "COMPANY" &&
							connection.friendStatus === "SENT" && (
								<button
									className={`${styles.btn} ${styles.grey}`}
									onClick={handleModalAction("REVOKE_FRIEND")}
								>
									<PendingIcon className={styles.icon} />
									Requested
								</button>
							)}

						{!isCurrentUserACompany &&
							type !== "COMPANY" &&
							connection.friendStatus === "PENDING" && (
								<button
									className={`${styles.btn} ${styles.grey}`}
									onClick={handleModalAction("ACCEPT_FRIEND")}
								>
									{/* <TickIcon
										className={styles.icon}
										width={20}
									/> */}
									Respond
								</button>
							)}

						{!isCurrentUserACompany &&
							type !== "COMPANY" &&
							connection.friendStatus === "N_A" && (
								<button
									className={`${styles.btn} ${styles.black}`}
									onClick={handleModalAction("ADD_FRIEND")}
								>
									Add Friend
								</button>
							)}

						{connection.inMyWorkCircle ? (
							<button
								className={`${styles.btn} ${styles.outline}`}
								onClick={handleModalAction("REMOVE_WORK")}
							>
								<WorkIcon className={styles.icon} />
								In Circle
							</button>
						) : (
							<button
								className={`${styles.btn} ${styles.black}`}
								onClick={handleModalAction("ADD_WORK")}
							>
								Add Work
							</button>
						)}

						{pathname !== "/chat" && !canEdit && (
							<button
								className={`${styles.btn} ${styles.grey}`}
								onClick={() => handleDirectMessage(userInfo)}
							>
								Message
							</button>
						)}

						<Popover
							ref={popoverRef}
							render={
								<div className="popover_container">
									{!isCurrentUserACompany &&
										type !== "COMPANY" &&
										connection.friendStatus === "N_A" && (
											<button
												className={`popover_item`}
												onClick={handleModalAction(
													"ADD_FRIEND"
												)}
											>
												<span>
													<img
														src={friendsIcon}
														alt="add friend"
													/>
												</span>
												<span>Add Friend</span>
											</button>
										)}

									{!isCurrentUserACompany &&
										type !== "COMPANY" &&
										connection.friendStatus === "SENT" && (
											<button
												className={`popover_item`}
												onClick={handleModalAction(
													"REVOKE_FRIEND"
												)}
											>
												<span>
													<img
														src={pendingIcon}
														alt="revoke friend"
													/>
												</span>
												<span>
													Revoke friend request
												</span>
											</button>
										)}

									{!isCurrentUserACompany &&
										type !== "COMPANY" &&
										connection.friendStatus ===
											"PENDING" && (
											<button
												className={`popover_item`}
												onClick={handleModalAction(
													"ACCEPT_FRIEND"
												)}
											>
												<span>
													<img
														src={pendingIcon}
														alt="pending friend"
													/>
												</span>
												<span>
													Accept/Decline Friend
												</span>
											</button>
										)}

									{!isCurrentUserACompany &&
										type !== "COMPANY" &&
										connection.friendStatus ===
											"APPROVED" && (
											<button
												className={`popover_item`}
												onClick={handleModalAction(
													"REMOVE_FRIEND"
												)}
											>
												<span>
													<img
														src={addFriendIcon}
														alt="remove friend"
													/>
												</span>
												<span>Remove Friend</span>
											</button>
										)}

									{connection.inMyWorkCircle ? (
										<button
											className={`popover_item`}
											onClick={handleModalAction(
												"REMOVE_WORK"
											)}
										>
											<span>
												<img
													src={workIcon}
													alt="remove from work circle"
												/>
											</span>
											<span>Remove from work circle</span>
										</button>
									) : (
										<button
											className={`popover_item`}
											onClick={handleModalAction(
												"ADD_WORK"
											)}
										>
											<span>
												<img
													src={workIcon}
													alt="add to work circle"
												/>
											</span>
											<span>Add to work circle</span>
										</button>
									)}

									{connection.following ? (
										<button
											className={`popover_item`}
											onClick={handleModalAction(
												"UNFOLLOW"
											)}
										>
											<span>
												<img
													src={followIcon}
													alt="Unfollow"
												/>
											</span>
											<span>Unfollow</span>
										</button>
									) : (
										<button
											className={`popover_item`}
											onClick={handleModalAction(
												"FOLLOW"
											)}
										>
											<span>
												<img
													src={followIcon}
													alt="Follow"
												/>
											</span>
											<span>Follow</span>
										</button>
									)}

									{connection.favorite ? (
										<button
											className={`popover_item`}
											onClick={() => {
												// unfavoriteApi.request(
												// 	uuid
												// );
												unfavorite(uuid);
												popoverRef.current.closePopover();
											}}
										>
											<span>
												<img
													src={unfavoriteIcon}
													alt="Unfavorite"
												/>
											</span>
											<span>Unfavorite</span>
										</button>
									) : (
										<button
											className={`popover_item`}
											onClick={() => {
												// favoriteApi.request(
												// 	uuid
												// );
												favorite(uuid);
												popoverRef.current.closePopover();
											}}
										>
											<span>
												<img
													src={favoriteIcon}
													alt="Favorite"
												/>
											</span>
											<span>Favorite</span>
										</button>
									)}

									<button
										className={`popover_item`}
										onClick={() => {
											alert("download profile as PDF");
											popoverRef.current.closePopover();
										}}
									>
										<span>
											<img
												src={downloadIcon}
												alt="Download"
											/>
										</span>
										<span>Download Profile as PDF</span>
									</button>

									{!companyUserType && (
										<button
											className={`popover_item`}
											onClick={handleModalAction("BLOCK")}
										>
											<span>
												<img
													src={blockBlackIcon}
													alt="Block"
												/>
											</span>
											<span>Block</span>
										</button>
									)}

									<button
										className={`popover_item`}
										onClick={() => {
											reportRef.current.showReportModal(
												uuid
											);
											popoverRef.current.closePopover();
										}}
									>
										<span>
											<img
												src={reportIcon}
												alt="Report"
											/>
										</span>
										<span>Report</span>
									</button>
								</div>
							}
						>
							<button className={styles.more_btn}>
								<img src={options} alt="options" />
							</button>
						</Popover>
					</div>
				)
			)}

			{/* //TODO >> redo design */}
			{/* {connection?.blocking && (
				<div className={styles.block}>
					You've blocked {firstName} {lastName}.{" "}
					<button onClick={handleModalAction("UNBLOCK")}>
						Unblock
					</button>
				</div>
			)} */}

			<ConnectionModal
				show={showConnectionModal}
				profileImage={
					userInfo?.profileImage
						? userInfo?.profileImage.originalImageURL
						: undefined
				}
				name={`${firstName} ${lastName}`}
				action={modalAction}
				onConfirm={handleModalConfirm}
				onClose={() => setShowConnectionModal(false)}
			/>

			<ReportModal ref={reportRef} category="profile" />
		</>
	);
};

export default ConnectionActions;
