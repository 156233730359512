import PrimaryButton from "components/Utils/Button/PrimaryButton";
import LoadingPage from "components/Utils/SubComs/CustomLoader/LoadingPage";
import TabMenu from "components/Utils/SubComs/TabMenu/TabMenu";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CollapsibleJobSection from "./CollapsibleSection/CollapsibleJobSection";
import useJobsLanding from "./hooks/useJobsLanding";
import useProject from "./hooks/useProject";
import styles from "./EmployerLanding.module.scss";
import ProjectSwitcher from "./ProjectSwitcher/ProjectSwitcher";
import routes from "components/Routing/routing-keys";
import useCurrentUser from "hooks/useCurrentUser";

const EmployerLanding = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { uuid: companyId } = useCurrentUser();

	const [activeTab, setActiveTab] = useState("long_term");
	const [projectId, setProjectId] = useState("");

	const validateTab = (tab) => {
		const validTabs = ["long_term", "freelance", "casting"];
		return validTabs.includes(tab) ? tab : "long_term";
	};

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const currentTab = searchParams.get("tab") || "long_term";
		const currentProjectId = searchParams.get("projectId") || "";
		const validTab = validateTab(currentTab);

		if (currentTab !== validTab) {
			const newSearchParams = new URLSearchParams(searchParams);
			newSearchParams.set("tab", validTab);
			navigate(`${location.pathname}?${newSearchParams.toString()}`, {
				replace: true,
			});
		}

		setActiveTab(validTab);
		setProjectId(currentProjectId);
	}, [location, navigate]);

	const { data: projectData, isFetching: isFetchingProject } =
		useProject(projectId);

	const { data: jobsLanding, isFetching } = useJobsLanding(projectId);

	const {
		activeLongTermJobs,
		pastLongTermJobs,
		activeFreelanceJobs,
		pastFreelanceJobs,
		activeCastingCallJobs,
		pastCastingCallJobs,
	} = jobsLanding || {};

	const {
		activeJobsCount,
		passedJobsCount,
		activeFreelanceCount,
		passedFreelanceCount,
		activeCastingCallsCount,
		passedCastingCallsCount,
	} = projectData || {};

	const jobCounts = {
		long_term: {
			open: projectData ? activeJobsCount : activeLongTermJobs,
			closed: projectData ? passedJobsCount : pastLongTermJobs,
		},
		freelance: {
			open: projectData ? activeFreelanceCount : activeFreelanceJobs,
			closed: projectData ? passedFreelanceCount : pastFreelanceJobs,
		},
		casting: {
			open: projectData ? activeCastingCallsCount : activeCastingCallJobs,
			closed: projectData ? passedCastingCallsCount : pastCastingCallJobs,
		},
	};

	const handleTabChange = (tab) => {
		const validTab = validateTab(tab);
		const searchParams = new URLSearchParams(location.search);
		searchParams.set("tab", validTab);
		navigate(`${location.pathname}?${searchParams.toString()}`, {
			replace: true,
		});
		setActiveTab(validTab);
	};

	const handleAddJob = () => {
		const jobType =
			activeTab === "long_term"
				? "Long_Term"
				: activeTab === "freelance"
				? "Freelance"
				: "Casting_Call";

		navigate(routes.jobCreate, {
			state: { jobType, project: projectData },
		});
	};

	if (isFetching || isFetchingProject) return <LoadingPage />;

	return (
		<div className={styles.container}>
			<div className={styles.header}>
				<ProjectSwitcher activeProjectTitle={projectData?.title} />
				<PrimaryButton
					text={"+ Add New Job"}
					className={styles.addJobBtn}
					onClick={handleAddJob}
				/>
			</div>

			<div className={styles.menu}>
				<TabMenu
					menuItems={{
						long_term: "Job Openings",
						freelance: "Freelancing",
						casting: "Casting Calls",
					}}
					selectedTab={activeTab}
					onSelectedTabChanged={handleTabChange}
					customStyle={styles.menu_button}
				/>
			</div>

			<div>
				<CollapsibleJobSection
					activeTab={activeTab}
					open
					count={jobCounts[activeTab].open}
					projectId={projectId}
					companyId={companyId}
				/>

				<CollapsibleJobSection
					activeTab={activeTab}
					open={false}
					count={jobCounts[activeTab].closed}
					projectId={projectId}
					companyId={companyId}
				/>
			</div>
		</div>
	);
};

export default EmployerLanding;
