import FileSaver from "file-saver";
import "../Utils/Popover/Popover.css";

// import downloadIconBlue from "images/download-icon-blue.svg";
// import renameIcon from "images/rename-icon.svg";
// import infoIcon from "images/info-icon.svg";
// import shareIconBlue from "images/share-icon.svg";
// import moveIcon from "images/move-icon.svg";
// import copyIcon from "images/copy-icon.svg";
// import starIcon from "images/icon-metro-star-full.svg";
// import starFullIcon from "images/icon-metro-star-full-color.svg";
// import binIcon from "images/bin-icon.svg";

import { ReactComponent as DownloadIcon } from "images/download.svg";
import { ReactComponent as ShareIcon } from "images/share.svg";
import { ReactComponent as MoveIcon } from "images/move-item.svg";
import { ReactComponent as CopyIcon } from "images/copy.svg";
import { ReactComponent as DeleteIcon } from "images/deleteInMenu.svg";
import { ReactComponent as StarIcon } from "images/star.svg";
import { ReactComponent as PencilIcon } from "images/pencil.svg";
import { ReactComponent as InfoIcon } from "images/info.svg";

// import { ReactComponent as StarIcon } from "images/icon-metro-star-full.svg";
import styles from "./VaultItemOptions.module.scss";
// import styles from "react-day-picker/dist/style.css";

const VaultItemOptions = ({
	// isOwner,
	isFolder,
	file,
	gaawkFolder,
	favorite,
	onRename,
	onDetails,
	onShare,
	onMove,
	onCopy,
	onFavorite,
	onDelete,
	onClose,
	shared,
	enableDelete,
}) => {
	const handleClosePopover = (e) => {
		e.stopPropagation();
		onClose && onClose();
	};

	// TODO >> HOW TO DISABLE 'DELETE' ACTION FOR FILES INSIDE THE SYSTEM FOLDER

	return (
		<div className="popover_container">
			{!isFolder && (
				<button
					className="popover_item"
					onClick={(e) => {
						handleClosePopover(e);
						FileSaver.saveAs(
							`${file.customName}`,
							`${file.originalName}`
						);
					}}
				>
					<DownloadIcon className={styles.icon} />
					<span>Download</span>
				</button>
			)}

			{!gaawkFolder && enableDelete && !shared && (
				<button
					className="popover_item"
					onClick={(e) => {
						handleClosePopover(e);
						onRename();
					}}
				>
					<PencilIcon className={styles.pencil_icon} />

					<span>Rename</span>
				</button>
			)}

			<button
				className="popover_item"
				onClick={(e) => {
					handleClosePopover(e);
					onDetails();
				}}
			>
				<InfoIcon className={styles.icon} />

				<span>Details</span>
			</button>

			<button
				className="popover_item"
				onClick={(e) => {
					handleClosePopover(e);
					onShare();
				}}
			>
				<ShareIcon className={styles.icon} />
				<span>Share</span>
			</button>

			{!gaawkFolder && !shared && (
				<button
					className="popover_item"
					onClick={(e) => {
						handleClosePopover(e);
						onMove();
					}}
				>
					<MoveIcon className={styles.icon} />
					<span>Move</span>
				</button>
			)}

			{!gaawkFolder && !isFolder && (
				<button
					className="popover_item"
					onClick={(e) => {
						handleClosePopover(e);
						onCopy();
					}}
				>
					<CopyIcon className={styles.icon} />
					<span>Copy</span>
				</button>
			)}

			<button
				className="popover_item"
				onClick={(e) => {
					handleClosePopover(e);
					onFavorite();
				}}
			>
				<StarIcon
					className={styles.icon}
					fill={favorite ? "#6cc5d1" : "none"}
					stroke={favorite ? "#6cc5d1" : "#a6b1bc"}
				/>
				<span>{favorite ? "Unfavorite" : "Favorite"}</span>
			</button>

			{enableDelete && (
				<button
					className="popover_item"
					onClick={(e) => {
						handleClosePopover(e);
						onDelete();
					}}
				>
					<DeleteIcon className={styles.icon} />
					<span>Delete</span>
				</button>
			)}
		</div>
	);
};

export default VaultItemOptions;
