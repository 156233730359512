import React, {
	useState,
	useEffect,
	useRef,
	forwardRef,
	useImperativeHandle,
	// useCallback,
	useMemo,
} from "react";

import ReactPlayer from "react-player/lazy";
import ReactAudioPlayer from "react-audio-player";
// import playIcon from "images/play-icon-blue.svg";
// import audioIcon from "images/audio_icon.svg";
// import pdfIcon from "images/pdf_icon.svg";

import { Swiper, SwiperSlide } from "swiper/react";
// import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import {
	FreeMode,
	Navigation,
	Thumbs,
	Scrollbar,
	Mousewheel,
	Keyboard,
} from "swiper";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import deleteIcon from "images/icon-exit.svg";
import { getFileIcon } from "../../Utils/General";
import useThumbsCount from "../../../hooks/useThumbsCount";
import Loader from "react-loader-spinner";
import PdfReader from "../PdfReader/PdfReader";

import "swiper/swiper.min.css";
// import "swiper/modules/navigation/navigation.min.css";
// import "swiper/modules/free-mode/free-mode.min.css";
// import "swiper/modules/thumbs/thumbs.min.css";
// import "swiper/modules/scrollbar/scrollbar.min.css";
// import "swiper/modules/keyboard/keyboard.min.css";
// import "swiper/modules/mousewheel/mousewheel.min.css";

import "./FileViewer.css";
import FullscreenControls from "./FullscreenControls";
import LazyImage from "../LazyImage/LazyImage";
import useWindowSize from "../../../hooks/useWindowSize";
import FileThumbnail from "../SubComs/FileThumbnail/FileThumbnail";
import convertHEICToImageURL from "../convertHEICToImageURL";

const FileViewer = forwardRef(
	(
		{
			files,
			disableScrollToEnd = false,
			inModal = true,
			initialSlide,
			onLoadMore,
			isLoading = false,
			onActiveSlide,
			text = "",
			fsActions = null,
			deletableItems = false,
			isChatPreview = false,
			onDelete,
		},
		ref
	) => {
		const swiperRef = useRef(null);
		const filesCountRef = useRef(files?.length);

		const fullscreenHandle = useFullScreenHandle();
		const thumbsPerView = useThumbsCount();

		const [swiper, setSwiper] = useState(null);
		const [thumbsSwiper, setThumbsSwiper] = useState(null);
		const [textHeight, setTextHeight] = useState();
		const [thumbHeight, setThumbHeight] = useState();
		const [fullscreenEnabled, setFullscreenEnabled] = useState(false);

		const reportChange = (state) => {
			setFullscreenEnabled(state);
		};

		useImperativeHandle(ref, () => ({
			enterFullscreen() {
				fullscreenHandle.enter();
			},
		}));

		const loadingSpinner = (
			<div className="loading_container">
				<Loader type="Oval" color="#6cc5d1" height={50} width={50} />
			</div>
		);

		const [swiperHeightDimensions, setSwiperHeightDimensions] = useState(0);

		useEffect(() => {
			setSwiperHeightDimensions(
				swiperRef?.current?.getBoundingClientRect().height
			);
		});

		const [formattedFiles, setFormattedFiles] = useState([]);
		const [formattedThumbs, setFormattedThumbs] = useState([]);

		useEffect(() => {
			const loadFiles = async () => {
				const formatted = await Promise.all(
					files?.map(async (file) => {
						if (
							file.mediaType === "IMAGE" ||
							file.type === "heic"
						) {
							let src = file.url;
							// Check if the file is a HEIC image
							if (
								file.originalName
									.toLowerCase()
									.endsWith(".heic")
							) {
								src = await convertHEICToImageURL(file.url);
							}

							return (
								<SwiperSlide key={file.url}>
									<LazyImage
										image={
											<img
												src={src}
												alt={file.originalName}
											/>
										}
									/>
								</SwiperSlide>
							);
						} else if (file.mediaType === "VIDEO") {
							return (
								<SwiperSlide key={file.url}>
									<ReactPlayer
										width={"100%"}
										height={"100%"}
										url={file.url}
										controls={true}
										playing={false}
									/>
								</SwiperSlide>
							);
						} else if (file.mediaType === "PDF") {
							return (
								<SwiperSlide key={file.url}>
									<PdfReader
										file={file}
										loadingSpinner={loadingSpinner}
										parentDimensions={
											swiperRef?.current?.getBoundingClientRect() || {
												width: 0,
												height: 0,
											}
										}
									/>
								</SwiperSlide>
							);
						} else if (file.mediaType === "AUDIO") {
							return (
								<SwiperSlide key={file.url}>
									<ReactAudioPlayer
										src={file.url}
										autoPlay={false}
										controls
									/>
								</SwiperSlide>
							);
						} else {
							const { icon } = getFileIcon("", file.originalName);
							return (
								<SwiperSlide key={file.url}>
									<div className="other-file">
										<LazyImage
											image={
												<img
													src={
														icon || file?.customName
													}
													className="other-icon"
													alt={file.originalName}
												/>
											}
										/>
										<p>{file.originalName}</p>
									</div>
								</SwiperSlide>
							);
						}
					})
				);
				setFormattedFiles(formatted);
			};

			const loadThumbs = async () => {
				const thumbs = await Promise.all(
					files?.map(async (file, index) => {
						return (
							<SwiperSlide key={index}>
								{isChatPreview && onDelete && (
									<button
										onClick={() => onDelete(index)}
										className="swiper-delete-btn"
									>
										<img src={deleteIcon} alt="" />
									</button>
								)}
								<FileThumbnail
									thumbnail={
										file.type === "heic"
											? await convertHEICToImageURL(
													file.url
											  )
											: getFileIcon(
													file,
													file.originalName
											  ).icon
									}
									isImage={
										file.mediaType === "IMAGE" ||
										file.type === "heic" ||
										!file?.mediaType
									}
									containerStyle={"container_wrapper"}
									customStyle={"thumb_wrapper"}
									customIconStyle={"noRadius"}
								/>
							</SwiperSlide>
						);
					})
				);
				setFormattedThumbs(thumbs);
			};

			loadFiles();
			loadThumbs();
		}, [files]);

		// const formattedFiles = files?.map((file) => {
		// 	if (file.mediaType === "IMAGE") {
		// 		return (
		// 			<SwiperSlide key={file.url}>
		// 				<LazyImage
		// 					image={
		// 						<img src={file.url} alt={file.originalName} />
		// 					}
		// 				/>
		// 			</SwiperSlide>
		// 		);
		// 	} else if (file.mediaType === "VIDEO") {
		// 		return (
		// 			<SwiperSlide key={file.url}>
		// 				<ReactPlayer
		// 					width={"100%"}
		// 					height={"100%"}
		// 					url={file.url}
		// 					controls={true}
		// 					playing={false}
		// 				/>
		// 			</SwiperSlide>
		// 		);
		// 	} else if (file.mediaType === "PDF") {
		// 		return (
		// 			<SwiperSlide key={file.url}>
		// 				<PdfReader
		// 					file={file}
		// 					loadingSpinner={loadingSpinner}
		// 					parentDimensions={
		// 						swiperRef?.current?.getBoundingClientRect() || {
		// 							width: 0,
		// 							height: 0,
		// 						}
		// 					}
		// 				/>
		// 			</SwiperSlide>
		// 		);
		// 	} else if (file.mediaType === "AUDIO") {
		// 		return (
		// 			<SwiperSlide key={file.url}>
		// 				<ReactAudioPlayer
		// 					src={file.url}
		// 					autoPlay={false}
		// 					controls
		// 				/>
		// 			</SwiperSlide>
		// 		);
		// 	} else {
		// 		const { icon } = getFileIcon("", file.originalName);
		// 		return (
		// 			<SwiperSlide key={file.url}>
		// 				<div className="other-file">
		// 					<LazyImage
		// 						image={
		// 							<img
		// 								src={icon || file?.customName}
		// 								className="other-icon"
		// 								alt={file.originalName}
		// 							/>
		// 						}
		// 					/>
		// 					<p>{file.originalName}</p>
		// 				</div>
		// 			</SwiperSlide>
		// 		);
		// 	}
		// });

		// const formattedThumbs = files?.map((file, index) => {
		// 	return (
		// 		<SwiperSlide key={index}>
		// 			{isChatPreview && onDelete && (
		// 				<button
		// 					onClick={() => onDelete(index)}
		// 					className="swiper-delete-btn"
		// 				>
		// 					<img src={deleteIcon} alt="" />
		// 				</button>
		// 			)}
		// 			<FileThumbnail
		// 				thumbnail={getFileIcon(file, file.originalName).icon}
		// 				isImage={file.mediaType === "IMAGE" || !file?.mediaType}
		// 				containerStyle={"container_wrapper"}
		// 				customStyle={"thumb_wrapper"}
		// 				customIconStyle={"noRadius"}
		// 			/>
		// 		</SwiperSlide>
		// 	);
		// });

		useEffect(() => {
			// * SCROLL TO LAST THUMB WHEN LOADING MORE MEDIAS (N/A FOR POST VIEWER)
			if (
				!disableScrollToEnd &&
				files?.length > 0 &&
				filesCountRef?.current > 0 &&
				filesCountRef?.current !== files?.length &&
				!deletableItems
			) {
				if (thumbsSwiper)
					thumbsSwiper?.slideTo(filesCountRef.current - 1);
			}
			filesCountRef.current = files?.length;
		}, [files]);

		// useEffect(() => {
		//     console.log("ACTIVE INDEX >>", swiper?.activeIndex);
		//     if (swiper?.activeIndex + 1 === files.length)
		//         console.log("END SLIDE REACHED !");
		// }, [files, swiper.activeIndex]);

		const handleTextRef = (node) => {
			setTextHeight(node?.getBoundingClientRect().height);
		};

		const handleThumbsRef = (node) => {
			setThumbHeight(node?.getBoundingClientRect().height);
		};

		const { height: windowHeight, width: windowWidth } = useWindowSize();

		const dynamicHeight = useMemo(
			() => ({
				height: `calc(${windowHeight}px ${
					fullscreenEnabled ? "" : "- 55px"
				} ${textHeight ? `- ${textHeight}px` : ""} ${
					thumbHeight ? `- ${thumbHeight}px` : ""
				} ${
					windowWidth < 748 && !fullscreenEnabled && !inModal
						? "- 55px"
						: ""
				})`,
			}),
			[
				fullscreenEnabled,
				textHeight,
				thumbHeight,
				windowHeight,
				windowWidth,
				inModal,
			]
		);

		return (
			<FullScreen
				// className={fullscreenEnabled ? undefined : "fs_disabled"}
				handle={fullscreenHandle}
				onChange={reportChange}
			>
				{fullscreenEnabled && (
					<FullscreenControls
						actions={fsActions}
						onExit={() => fullscreenHandle.exit()}
					/>
				)}

				<Swiper
					initialSlide={initialSlide}
					onSwiper={setSwiper}
					spaceBetween={10}
					navigation={true}
					ref={swiperRef}
					onActiveIndexChange={() => {
						onActiveSlide &&
							onActiveSlide(
								swiper?.activeIndex !== undefined
									? swiper.activeIndex
									: initialSlide
							);
					}}
					thumbs={{ swiper: thumbsSwiper }}
					keyboard={true}
					modules={[FreeMode, Navigation, Thumbs, Keyboard]}
					className="mySwiper2"
					style={isChatPreview ? undefined : dynamicHeight}
				>
					{isLoading && loadingSpinner}
					{formattedFiles}
				</Swiper>

				{text && (
					<div ref={handleTextRef} className="media_text">
						{text}
					</div>
				)}

				{formattedThumbs?.length > 1 && (
					<div ref={handleThumbsRef} className="thumb_container">
						<Swiper
							onSwiper={setThumbsSwiper}
							onReachEnd={onLoadMore}
							initialSlide={initialSlide}
							slidesPerView={"auto"}
							spaceBetween={10}
							centeredSlides={
								fullscreenEnabled
									? false
									: files.length >= thumbsPerView
									? true
									: false
							}
							centeredSlidesBounds={true}
							centerInsufficientSlides={true}
							scrollbar={{
								hide: false,
								draggable: true,
							}}
							// scrollbar={
							//     fullscreenEnabled && width > 2000
							//         ? false
							//         : files.length >= thumbsPerView && {
							//               hide: false,
							//               draggable: true,
							//           }
							// }
							// freeMode={files.length >= thumbsPerView ? true : false}
							freeMode={true}
							grabCursor={true}
							watchSlidesProgress={true}
							modules={[
								FreeMode,
								Navigation,
								Thumbs,
								Scrollbar,
								Mousewheel,
							]}
							className="mySwiper"
							mousewheel={true}
						>
							{formattedThumbs}
						</Swiper>
					</div>
				)}
			</FullScreen>
		);
	}
);

export default FileViewer;
