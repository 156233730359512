import { cloneElement } from "react";
import useCompanyId from "../../hooks/useCompanyId";

const EditProfileRoute = ({ children }) => {
	const companyId = useCompanyId();

	return cloneElement(children, { editCompany: companyId });
};

export default EditProfileRoute;
