import React, { useMemo } from "react";

import { getReactionImg } from "../General";
import styles from "./Reactions.module.css";
import { pluralize } from "../General";
import { classNames } from "primereact/utils";

const ReactionsCounter = ({
	reactionStats,
	commentsCount,
	sharedCount,
	onShowComment,
	onShowReactions,
	counterOnly = false,
	onClick,
	onShowRepost,
}) => {
	const { stats, totalCount } = reactionStats;

	const reactionOptions = useMemo(
		() =>
			stats.map((item) => (
				<img
					key={item.reaction}
					className={styles.emoji_icon}
					src={getReactionImg(item.reaction)}
					alt={item.reaction}
					onClick={() => {
						onClick(item.reaction);
						onShowReactions();
					}}
				/>
			)),
		[reactionStats]
	);

	// return <ReactionCounter className={styles.reaction_counter_container} reactions={reactionOptions} />;
	return (
		<div
			className={classNames(styles.reaction_counter_wrapper, {
				[styles.flex_end]: counterOnly,
			})}
		>
			{totalCount > 0 && (
				<div className={styles.left_side}>
					<div className={styles.emoji_container}>
						{reactionOptions}
					</div>

					<button onClick={onShowReactions}>
						{totalCount}{" "}
						{!counterOnly && pluralize(totalCount, "Reaction")}
					</button>
				</div>
			)}

			{/* IF a reply, do not show the reaction_counter below div */}

			{!counterOnly && (
				<div className={styles.reaction_counter}>
					<div className={styles.right_side}>
						{commentsCount > 0 && (
							<button onClick={onShowComment}>
								{commentsCount}{" "}
								{pluralize(commentsCount, "Comment")}
							</button>
						)}
						{sharedCount > 0 && (
							<button onClick={onShowRepost}>
								{sharedCount} {pluralize(sharedCount, "Repost")}
							</button>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export default ReactionsCounter;
