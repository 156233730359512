import HomeLeftSidebar from "components/Home/Components/HomeLeftSidebar/HomeLeftSidebar";
import useFetchProfile from "hooks/useFetchProfile";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateProfileTab } from "store/slices/activeTab";
import DefaultPage from "../Utils/PageArchs/DefaultPage/DefaultPage";
import ProfilePage from "./ProfilePage/ProfilePage";
import ServicesTree from "./ServicesTab/ServicesComponent/ServicesTree";

const Index = () => {
	const navigate = useNavigate();

	const dispatch = useDispatch();

	const activeTab = useSelector((state) => state.activeTab.profileTab);

	const { profileData: profile, isFetching, canEdit } = useFetchProfile();

	const tabClicked = (e) => {
		dispatch(updateProfileTab(e.target?.innerHTML || e));
	};

	const leftSidePanel = useMemo(() => {
		if (activeTab === "services" && profile?.hasServices) {
			return (
				<div style={{ padding: "20px" }}>
					<ServicesTree userId={profile?.uuid} enabled={true} />
				</div>
			);
		} else if (activeTab === "products" && profile?.productsHasServices) {
			return (
				<div style={{ padding: "20px" }}>
					<ServicesTree
						showServicesWithProducts={true}
						userId={profile?.uuid}
						enabled={true}
					/>
				</div>
			);
		} else {
			return <HomeLeftSidebar />;
		}
	}, [
		activeTab,
		profile?.hasServices,
		profile?.productsHasServices,
		profile?.uuid,
	]);

	return (
		<DefaultPage
			forceShowLeftScroll={true}
			leftSideChildren={leftSidePanel}
			rightSideChildren={
				profile && (
					<ProfilePage
						userInfo={profile}
						onItemClicked={tabClicked}
						canEdit={canEdit}
						onClickEditProfilePic={() =>
							navigate("/profile/profilePic")
						}
						onClickSetFrame={() =>
							navigate("/profile/profilePic/frame")
						}
						onClickEditCoverPic={() =>
							navigate("/profile/coverPic")
						}
						onClickEdit={() => navigate("/profile/edit")}
					/>
				)
			}
		/>
	);
};

export default Index;
