import React, { forwardRef, useImperativeHandle, useRef } from "react";
import MultiCheckbox from "../../../Utils/MultiCheckbox/MultiCheckbox";
import { locationFormatter } from "../../../Utils/SubComs/Inputs/SearchableInput/response-formatter";
import styles from "./Filters.module.css";
import InfiniteSearchInput from "../../../Utils/SubComs/Inputs/InfiniteSearchInput/InfiniteSearchInput";
import useFetchLocation from "../../../../hooks/useFetchLocation";
import { locationKeys } from "queryKeys/location-key-factory";

const itemsPerPage = 20;

const SearchType = forwardRef(
	(
		{
			onLocationChange,
			onTypeChange,
			onDateChange,
			type,
			postedDate,
			location,
		},
		ref
	) => {
		const typeRef = useRef();
		const dateRef = useRef();

		useImperativeHandle(ref, () => ({
			clear() {
				typeRef.current.set({ label: "All", value: 0 });
				dateRef.current.set({ label: "Any Time", value: "NONE" });
			},
		}));

		const fetchLocation = useFetchLocation(true, itemsPerPage);

		return (
			<div className={styles.container}>
				<InfiniteSearchInput
					queryName={locationKeys.cities}
					queryFn={fetchLocation}
					itemsPerPage={itemsPerPage}
					formatter={locationFormatter}
					label={"Location"}
					onChange={onLocationChange}
					value={location}
				/>

				<div className={styles.section}>
					<MultiCheckbox
						ref={typeRef}
						title={"Type"}
						options={[
							{ label: "All", value: 0 },
							{ label: "Jobs", value: 1 },
							{ label: "Freelance", value: 2 },
							{ label: "Casting Calls", value: 3 },
						]}
						onSelect={onTypeChange}
						selected={type}
						type={"radio"}
					/>
				</div>

				<div className={styles.section}>
					<MultiCheckbox
						ref={dateRef}
						title={"Date Posted"}
						options={[
							{ label: "Any Time", value: "NONE" },
							{ label: "Last 24 Hours", value: "LAST_DAY" },
							{ label: "Last Week", value: "LAST_WEEK" },
							{ label: "Last Month", value: "LAST_MONTH" },
						]}
						onSelect={onDateChange}
						selected={postedDate}
						type={"radio"}
					/>
				</div>
			</div>
		);
	}
);

export default SearchType;
