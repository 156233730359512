import useApi from "./useApi";
import profileApi from "api/profile";

const useSearchUsers = (itemsPerPage = 20) => {
	const searchUsersApi = useApi(profileApi.searchUser, true, true);

	const fetchUsers = async ({ pageParam = 0, signal, queryKey }) => {
		const [_, __, searchInput] = queryKey;

		const response = await searchUsersApi.request(
			pageParam,
			itemsPerPage,
			searchInput
		);
		return response.data;
	};

	return fetchUsers;
};

export default useSearchUsers;
