import { joiResolver } from "@hookform/resolvers/joi";
import routes from "components/Routing/routing-keys";
import PromptModal from "components/Utils/Prompt/PromptModal";
import Tag from "components/Utils/SubComs/Tags/Tag";
import TagContainer from "components/Utils/SubComs/Tags/TagContainer";
// import { format } from "date-fns";
import useApi from "hooks/useApi";
import useCompanyId from "hooks/useCompanyId";
import useCurrencies from "hooks/useCurrencies";
import useFetchLanguages from "hooks/useFetchLanguages";
import useFetchLocation from "hooks/useFetchLocation";
import { languagesKeys } from "queryKeys/languages-key-factory";
import { locationKeys } from "queryKeys/location-key-factory";
import { skillsKeys } from "queryKeys/skills-key-factory";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
	Navigate,
	useLocation,
	useNavigate,
	useParams,
} from "react-router-dom";
import Switch from "react-switch";
// import TimePicker from "react-time-picker"; //TODO >> delete
import { toast } from "react-toastify";
import ethnicityApi from "../../../api/ethnicity";
import jobApi from "../../../api/job";
import skillsApi from "../../../api/skills";
import MapItem from "../../Profile/ProfileEdit/Contacts/Address/MapItem";
import PrimaryButton from "../../Utils/Button/PrimaryButton";
import GaawkModal from "../../Utils/GaawkModal/GaawkModal";
import {
	employementTypes,
	frequencyTypes,
	workLocation as workLocationTypes,
} from "../../Utils/General";
import MapSelect from "../../Utils/MapSelect/MapSelect";
import MultiCheckbox from "../../Utils/MultiCheckbox/MultiCheckbox";
import LoadingPage from "../../Utils/SubComs/CustomLoader/LoadingPage";
import CustomRadioButton from "../../Utils/SubComs/CustomRadioButton/CustomRadioButton";
import CustomSelect from "../../Utils/SubComs/CustomSelect/CustomSelect";
import DayPicker from "../../Utils/SubComs/DayPicker/DayPicker";
import InfiniteSearchInput from "../../Utils/SubComs/Inputs/InfiniteSearchInput/InfiniteSearchInput";
import MultiSelectInput from "../../Utils/SubComs/Inputs/MultiSelectInput/MultiSelectInput";
import {
	defaultFormatter,
	languageFormatter,
	locationFormatter,
	unduplicateValue,
} from "../../Utils/SubComs/Inputs/SearchableInput/response-formatter";
import TextInput from "../../Utils/SubComs/Inputs/TextInput/TextInput";
import jobSchema from "./job-schema";
import styles from "./JobEntry.module.css";
import { Calendar } from "primereact/calendar";

const itemsPerPage = 20;
const cityItemsPerPage = 20;

const JobEntry = ({ jobType: type = "Long_Term", project: projectObject }) => {
	const navigate = useNavigate();
	const { jobId } = useParams();
	const { pathname } = useLocation();
	const editMode = useMemo(
		() => pathname === `/jobs/${jobId}/edit`,
		[jobId, pathname]
	);

	const [isLoading, setIsLoading] = useState(false);

	useMemo(() => {
		if (editMode) {
			setIsLoading(true);
		}
	}, [editMode]);

	const [jobType, setJobType] = useState(type);
	const [project, setProject] = useState(projectObject);

	const getJobApi = useApi(jobApi.getJobById, true);
	const createJobApi = useApi(jobApi.createJob);
	const updateJobApi = useApi(jobApi.updateJob);
	const createCastingApi = useApi(jobApi.createCasting);
	const updateCastingApi = useApi(jobApi.updateCasting);
	const createFreelanceApi = useApi(jobApi.createFreelance);
	const updateFreelanceApi = useApi(jobApi.updateFreelance);
	const companyId = useCompanyId();

	const [locationModal, setLocationModal] = useState(false);

	const [shouldBlockSave, setShouldBlockSave] = useState(true);
	// const [redirect, setRedirect] = useState("");

	const [mapLocation, setMapLocation] = useState("");

	const [selectedLocation, setSelectedLocation] = useState("");

	const { data: currencies, isLoading: isLoadingCurrencies } =
		useCurrencies();

	const handleMapLocation = (location) => {
		setMapLocation(location);
	};

	const handleSelectedLocation = () => {
		setValue("address", mapLocation.address);
		setSelectedLocation(mapLocation);
		setLocationModal(false);
	};

	const handleRemoveLocation = () => {
		setSelectedLocation("");
	};

	useEffect(() => {
		const fetchJobData = async () => {
			const response = await getJobApi.request(jobId);

			if (companyId === response.data.minifiedCompany.uuid) {
				const {
					name,
					longTermJob: { workingLocation, type, experience } = {},
					castingCallDTO: {
						dateTime,
						gender,
						languages = [],
						maxAge,
						minAge,
						ethnicity,
					} = {},
					freelanceJob: {
						startDate,
						endDate,
						experience: freelanceExp,
						workingLocation: freelanceWorkingLocation,
					} = {},
					closed,
					description,
					requirements,
					skills,
					location,
					compensation,
					currency,
					frequency,
					jobType,
					project: projectObject,
				} = response.data;

				setJobType(jobType);

				if (projectObject) setProject(projectObject);

				if (location)
					setSelectedLocation({
						address: location?.title,
						city: location.locationDTO?.name,
						country: location.locationDTO?.countryDTO?.name,
						countryCode: location.locationDTO?.countryDTO?.code,
						lat: location.latitude,
						lng: location.longitude,
					});

				reset({
					title: name,
					...(type && {
						employementType: employementTypes.find(
							(item) => item.value === type
						),
					}),
					...((workingLocation || freelanceWorkingLocation) && {
						location: workLocationTypes.find(
							(location) =>
								location.value === workingLocation ||
								location.value === freelanceWorkingLocation
						),
					}),
					...((experience || freelanceExp) && {
						experienceNeeded: experience || freelanceExp,
					}),
					...(gender && { gender: gender.toLowerCase() }),
					...(minAge > 0 && { minAge }),
					...(maxAge > 0 && { maxAge }),
					...(dateTime > 0 && {
						date: new Date(dateTime),
						// time: format(new Date(dateTime), "h:mm aaa"),
					}),
					...(startDate > 0 && {
						startDate: new Date(startDate),
					}),
					...(endDate > 0 && {
						endDate: new Date(endDate),
					}),
					...(languages.length > 0 && {
						languages: languages.map((language) => ({
							label: language.name,
							value: language.code,
						})),
					}),
					...(languages.length === 0 &&
						jobType === "Casting_Call" && {
							languages: [],
						}),
					...(ethnicity && {
						ethnicity: {
							label: ethnicity.name,
							value: ethnicity.uuid,
						},
					}),
					status: !closed,
					description,
					requirements,
					skills: skills.map((skill) => defaultFormatter(skill)),
					...(compensation > 0 && { compensation }),
					...(currency && { currency: defaultFormatter(currency) }),
					...(frequency !== "N_A" && {
						frequency: frequencyTypes.find(
							(item) => item.value === frequency
						),
					}),
					...(location?.title && {
						address: location.title,
					}),
					...(location?.locationDTO && {
						city: {
							label: `${location.locationDTO.name}, ${location.locationDTO.countryDTO.name}`,
							value: location.locationDTO.id,
						},
					}),
				});

				setIsLoading(false);
			} else {
				navigate(`/jobs/${jobId}`, { replace: true });
			}
		};

		if (editMode) fetchJobData();
	}, [editMode, jobId]);

	const updatedTypes = employementTypes.filter(
		(type) =>
			type.value !== "SELF_EMPLOYED" &&
			type.value !== "FREELANCE" &&
			type.value !== "APPRENTICESHIP" &&
			type.value !== "CASTING_CALLS"
	);

	const handleRemoveSkill = (skillIndex) => {
		const updatedSkills = skillsWatcher.filter(
			(_, index) => index !== skillIndex
		);
		setValue("skills", updatedSkills);
	};

	const {
		register,
		setValue,
		formState: { errors, isDirty },
		watch,
		handleSubmit,
		control,
		reset,
	} = useForm({
		resolver: joiResolver(jobSchema(jobType)),
		mode: "onSubmit",
		defaultValues: {
			title: "",
			...(jobType !== "Casting_Call" && {
				location: null,
				experienceNeeded: "",
			}),
			...(jobType === "Casting_Call" && {
				minAge: "",
				maxAge: "",
				gender: "",
				ethnicity: null,
				languages: [],
				date: null,
				// time: null,
			}),
			...(jobType === "Long_Term" && {
				employementType: null,
			}),
			...(jobType === "Freelance" && {
				startDate: null,
				endDate: null,
			}),
			status: true,
			description: "",
			requirements: "",
			skills: [],
			compensation: "",
			currency: null,
			frequency: null,
			city: null,
			address: "",
		},
	});

	console.log("🚀 ~ errors >>", errors);

	const statusWatcher = watch("status");
	const skillsWatcher = watch("skills");
	const currencyWatcher = watch("currency");
	const frequencyWatcher = watch("frequency");
	const languagesWatcher = watch("languages");
	const cityWatcher = watch("city");

	const getSkills = useApi(skillsApi.searchSkills, true, true);

	const fetchSkills = async ({ pageParam = 0, signal, queryKey }) => {
		const [_, searchInput] = queryKey;

		const response = await getSkills.request(
			pageParam,
			itemsPerPage,
			searchInput
		);
		return response.data;
	};

	const handleSave = (data) => {
		const {
			compensation,
			currency,
			description,
			location,
			employementType = {},
			experienceNeeded,
			frequency,
			requirements,
			skills,
			status: active,
			title,
			gender,
			languages,
			date,
			// time,
			minAge,
			maxAge,
			ethnicity,
			startDate,
			endDate,
			address,
			city,
		} = data;

		//TODO >> check si ca break pas
		// if (date) {
		// 	if (time) {
		// 		const [hours, minutes] = time.split(":");
		// 		date.setHours(hours, minutes);
		// 	} else {
		// 		date.setHours(0, 0);
		// 	}
		// }

		const body = {
			title,
			active,
			...(project && { projectId: project.uuid }),
			...(city && {
				locationId: city.value,
			}),
			...(address && {
				address,
			}),
			...(selectedLocation &&
				cityWatcher &&
				selectedLocation.lat !== 0 &&
				selectedLocation.lng !== 0 && {
					latitude: selectedLocation.lat,
					longitude: selectedLocation.lng,
				}),
			...(compensation > 0 && { compensation }),
			...(currency && { currencyId: currency.value }),
			...(frequency && { frequency: frequency.value }),
			...(description && { description }),
			...(requirements && { requirements }),
			...(jobType !== "Casting_Call" && {
				location: location.value,
				...(experienceNeeded > 0 && { experienceNeeded }),
			}),
			...(jobType === "Long_Term" && {
				type: employementType.value,
			}),
			...(jobType === "Freelance" && {
				...(startDate && { startDate: startDate.getTime() }),
				...(endDate && { endDate: endDate.getTime() }),
			}),
			...(jobType === "Casting_Call" && {
				gender: gender.toUpperCase(),
				...(date && { dateTime: date.getTime() }),
				...(languages.length > 0 && {
					languages: languages.map((language) => language.value),
				}),
				...(ethnicity && {
					ethnicityId: ethnicity.value,
				}),
				...(minAge > 0 && {
					minAge,
				}),
				...(maxAge > 0 && {
					maxAge,
				}),
			}),
			...(skills.length > 0 && {
				skills: skills.map((skill) => skill.value),
			}),
		};

		if (jobType === "Casting_Call") {
			if (editMode) {
				updateCastingApi.request(body, jobId);
			} else {
				createCastingApi.request(body);
			}
		} else if (jobType === "Freelance") {
			if (editMode) {
				updateFreelanceApi.request(body, jobId);
			} else {
				createFreelanceApi.request(body);
			}
		} else {
			if (editMode) {
				updateJobApi.request(body, jobId);
			} else {
				createJobApi.request(body);
			}
		}

		setShouldBlockSave(false);
	};

	const handleFailedRequest = () => {
		toast.error("Something Wrong Happened");
		setShouldBlockSave(true);
	};

	useEffect(() => {
		if (
			!createJobApi.loading &&
			createJobApi.responseCode === 201 &&
			createJobApi.data
		) {
			navigate(`/jobs/${createJobApi.data.uuid}`, { replace: true });
		} else if (
			updateJobApi.responseCode !== 201 &&
			Object.keys(updateJobApi.data).length > 0
		)
			handleFailedRequest();
	}, [createJobApi.data]);

	useEffect(() => {
		if (
			!createCastingApi.loading &&
			createCastingApi.responseCode === 201 &&
			createCastingApi.data
		) {
			navigate(`/jobs/${createCastingApi.data.uuid}`, { replace: true });
		} else if (
			updateJobApi.responseCode !== 201 &&
			Object.keys(updateJobApi.data).length > 0
		)
			handleFailedRequest();
	}, [createCastingApi.data]);

	useEffect(() => {
		if (
			!createFreelanceApi.loading &&
			createFreelanceApi.responseCode === 201 &&
			createFreelanceApi.data
		) {
			navigate(`/jobs/${createFreelanceApi.data.uuid}`, {
				replace: true,
			});
		} else if (
			updateJobApi.responseCode !== 201 &&
			Object.keys(updateJobApi.data).length > 0
		)
			handleFailedRequest();
	}, [createFreelanceApi.data]);

	useEffect(() => {
		if (
			!updateJobApi.loading &&
			updateJobApi.responseCode === 200 &&
			updateJobApi.data
		) {
			navigate(`/jobs/${updateJobApi.data.uuid}`, { replace: true });
		} else if (
			updateJobApi.responseCode !== 200 &&
			Object.keys(updateJobApi.data).length > 0
		)
			handleFailedRequest();
	}, [updateJobApi.data]);

	useEffect(() => {
		if (
			!updateCastingApi.loading &&
			updateCastingApi.responseCode === 200 &&
			updateCastingApi.data
		) {
			navigate(`/jobs/${updateCastingApi.data.uuid}`, { replace: true });
		} else if (
			updateJobApi.responseCode !== 200 &&
			Object.keys(updateJobApi.data).length > 0
		)
			handleFailedRequest();
	}, [updateCastingApi.data]);

	useEffect(() => {
		if (
			!updateFreelanceApi.loading &&
			updateFreelanceApi.responseCode === 200 &&
			updateFreelanceApi.data
		) {
			navigate(`/jobs/${updateFreelanceApi.data.uuid}`, {
				replace: true,
			});
		} else if (
			updateJobApi.responseCode !== 200 &&
			Object.keys(updateJobApi.data).length > 0
		)
			handleFailedRequest();
	}, [updateFreelanceApi.data]);

	// !============================= LANGUAGES =============================

	const fetchLanguages = useFetchLanguages();

	const languagesList = useMemo(
		() =>
			languagesWatcher?.map((language, index) => (
				<Tag
					key={language.value}
					itemName={language.label}
					onRemove={() => handleRemoveLanguage(index)}
				/>
			)),
		[languagesWatcher]
	);

	const handleSelectLanguages = (language) => {
		setValue("languages", [...languagesWatcher, language]);
	};

	const handleRemoveLanguage = (languageIndex) => {
		const updatedLanguages = [...languagesWatcher].filter(
			(_, index) => index !== languageIndex
		);
		setValue("languages", updatedLanguages);
	};

	// !============================= ETHNICITIES =============================
	const getEthnicitiesApi = useApi(ethnicityApi.getEthnicities);

	const [ethnicities, setEthnicities] = useState([]);

	useEffect(() => {
		getEthnicitiesApi.request();
	}, []);

	useEffect(() => {
		if (
			!getEthnicitiesApi.loading &&
			getEthnicitiesApi.responseCode === 200 &&
			getEthnicitiesApi.data.length > 0
		) {
			const response = getEthnicitiesApi.data;
			setEthnicities(response.map((item) => defaultFormatter(item)));
		}
	}, [getEthnicitiesApi.data]);

	const fetchLocation = useFetchLocation(true, cityItemsPerPage);

	if (!companyId) {
		//* 1 - redirecting if you're not a company (should not be able to edit job as a user)
		//* 2 - Knowing if you're the owner of the job and allow editing >> check performed in the useEffect
		return <Navigate to={jobId ? routes.job(jobId) : routes.jobs} />;
	}

	return (
		<>
			{isLoading ? (
				<LoadingPage />
			) : (
				<form
					className={styles.job_form}
					onSubmit={handleSubmit((data) => handleSave(data))}
					noValidate
				>
					{project && (
						<div className={styles.form_input_wrapper}>
							<label>
								Project
								<span className="required">*</span>
							</label>

							<TextInput value={project.title} disabled={true} />
						</div>
					)}
					<div className={styles.form_input_wrapper}>
						<label>
							{jobType === "Casting_Call"
								? "Casting Call Role Name"
								: "Title"}
							<span className="required">*</span>
						</label>

						<TextInput
							{...register("title")}
							error={!!errors.title}
							placeholder={
								jobType === "Casting_Call"
									? "Enter Role Name"
									: "Enter Job Title"
							}
						/>
						{errors?.title?.message && (
							<p className={styles.error_message}>
								{errors?.title?.message}
							</p>
						)}
					</div>

					{jobType === "Long_Term" && (
						<div className={styles.react_select_wrapper}>
							<label>
								Employment Type
								<span className="required">*</span>
							</label>

							<CustomSelect
								options={updatedTypes}
								placeholder="Select"
								height="35px"
								error={!!errors.employementType}
								control={control}
								name="employementType"
							/>
							{errors?.employementType?.message && (
								<p className={styles.error_message}>
									{errors?.employementType?.message}
								</p>
							)}
						</div>
					)}

					{jobType !== "Casting_Call" && (
						<div className={styles.form_input_wrapper}>
							<MultiCheckbox
								title={"Working Location"}
								options={workLocationTypes}
								type={"radio"}
								control={control}
								name="location"
								perRow={"3, auto"}
								error={errors?.location}
								required={true}
							/>
						</div>
					)}

					{jobType !== "Casting_Call" && (
						<div className={styles.form_input_wrapper}>
							<label>Experience Needed</label>

							<TextInput
								{...register("experienceNeeded")}
								placeholder="Enter number of years"
								type="number"
								error={errors?.experienceNeeded}
							/>
							{errors?.experienceNeeded?.message && (
								<p className={styles.error_message}>
									{errors?.experienceNeeded?.message}
								</p>
							)}
						</div>
					)}

					<div className={styles.react_switch}>
						<Controller
							name="status"
							control={control}
							render={({ field: { onChange, value } }) => (
								<Switch
									checked={value}
									onColor="#6cc5d1"
									offColor="#a6b1bc"
									handleDiameter={18}
									width={39}
									height={24}
									checkedIcon={false}
									uncheckedIcon={false}
									activeBoxShadow="0px 0px 1px 8px rgb(108, 197, 209, 0.3)"
									onChange={(val) => {
										onChange(val);
									}}
								/>
							)}
						/>
						<span>
							{jobType === "Casting_Call" ? "Casting" : "Job"}{" "}
							Status: <b>{statusWatcher ? "Open" : "Closed"}</b>
						</span>
					</div>

					{jobType === "Freelance" && (
						<div className={styles.inline_input}>
							<div className={styles.item}>
								<label>Start Date</label>
								<DayPicker
									className={"form-start-date"}
									control={control}
									name="startDate"
									isClearable={true}
								/>
							</div>
							<div className={styles.item}>
								<label>End Date</label>
								<DayPicker
									className={`form-end-date ${
										!!errors.endDate ? "error" : undefined
									}`}
									control={control}
									name="endDate"
									isClearable={true}
								/>
								{errors?.endDate?.message && (
									<p className={styles.error_message}>
										{errors?.endDate?.message}
									</p>
								)}
							</div>
						</div>
					)}

					{jobType === "Casting_Call" && (
						<>
							<div className={styles.form_input_wrapper}>
								<label>
									Gender
									<span className="required">*</span>
								</label>
								<div
									className={`${styles.inline_input} ${styles.gender_wrapper}`}
								>
									<div className={styles.gender_item}>
										<CustomRadioButton
											{...register("gender", {
												required: true,
											})}
											id="female"
											value="female"
											name="gender"
										/>
										<label htmlFor="female">Female</label>
									</div>

									<div className={styles.gender_item}>
										<CustomRadioButton
											{...register("gender", {
												required: true,
											})}
											id="male"
											value="male"
											name="gender"
										/>
										<label htmlFor="male">Male</label>
									</div>
								</div>
								{errors?.gender?.message && (
									<p className={styles.error_message}>
										{errors?.gender?.message}
									</p>
								)}
							</div>

							<div className={styles.form_input_wrapper}>
								<label>Age Range</label>
								<div
									className={`${styles.inline_input} ${styles.age_wrapper}`}
								>
									<div className={styles.item}>
										<TextInput
											{...register("minAge")}
											placeholder="Minimum"
											type="number"
										/>
									</div>
									<div className={styles.item}>
										<TextInput
											{...register("maxAge")}
											placeholder="Maximum"
											type="number"
										/>
									</div>
								</div>
							</div>

							<div className={styles.text_input}>
								<label>Ethnicity</label>
								<CustomSelect
									options={ethnicities}
									height="35px"
									isClearable={true}
									control={control}
									name={"ethnicity"}
									className={styles.add_margin}
								/>
							</div>

							{/* <InfiniteSearchInput
								queryName={languagesKeys.search}
								queryFn={fetchLanguages}
								itemsPerPage={itemsPerPage}
								label={"Languages"}
								onChange={handleSelectLanguages}
								selected={languagesWatcher}
								formatter={languageFormatter}
								unDuplicateFn={(passedData) =>
									unduplicateValue(
										languagesWatcher,
										passedData
									)
								}
							/>

							{languagesWatcher.length > 0 && (
								<TagContainer
									label="Selected Languages"
									items={languagesList}
								/>
							)} */}

							<MultiSelectInput
								queryName={languagesKeys.search}
								queryFn={fetchLanguages}
								data={languagesWatcher}
								itemsPerPage={itemsPerPage}
								formatter={languageFormatter}
								label="Languages"
								onRemoveItem={handleRemoveLanguage}
								onChange={(language) =>
									setValue("languages", [
										...languagesWatcher,
										language,
									])
								}
								unDuplicateFn={(passedData) =>
									unduplicateValue(
										languagesWatcher,
										passedData
									)
								}
								selected={languagesWatcher}
								customStyle={styles.margin_20}
							/>
						</>
					)}

					<div className={styles.text_input}>
						<label>
							{jobType === "Casting_Call"
								? "Role Overview"
								: "Job Description"}
						</label>

						<textarea
							{...register("description")}
							placeholder={
								jobType === "Casting_Call"
									? "Enter Role Overview"
									: "Enter Job Description"
							}
						/>
					</div>

					{jobType === "Casting_Call" && (
						<div className={styles.form_input_wrapper}>
							<label>Date</label>

							<Controller
								name="date"
								control={control}
								render={({ field }) => (
									<Calendar
										className={styles.timepicker}
										{...field}
										showTime
										hourFormat="12"
										dateFormat={"dd/mm/yy"}
										showButtonBar
										placeholder={"Select..."}
									/>
								)}
							/>
						</div>
					)}

					<div className={styles.text_input}>
						<label>
							{jobType === "Casting_Call" ? "" : "Job"}{" "}
							Requirements
						</label>

						<textarea
							{...register("requirements")}
							placeholder={`Enter ${
								jobType === "Casting_Call" ? "Role" : "Job"
							} Requirements`}
						/>
					</div>

					<MultiSelectInput
						queryName={skillsKeys.search}
						queryFn={fetchSkills}
						data={skillsWatcher}
						itemsPerPage={itemsPerPage}
						formatter={defaultFormatter}
						label="Skills"
						onChange={(skill) =>
							setValue("skills", [...skillsWatcher, skill])
						}
						onRemoveItem={handleRemoveSkill}
					/>

					<div className={styles.form_input_wrapper}>
						<label>Address</label>

						<TextInput
							{...register("address")}
							placeholder="Enter address"
						/>
					</div>

					<InfiniteSearchInput
						queryName={locationKeys.cities}
						queryFn={fetchLocation}
						itemsPerPage={cityItemsPerPage}
						formatter={locationFormatter}
						label={"City & Country"}
						name="city"
						control={control}
						isClearable={true}
						customStyle={styles.margin_20}
					/>

					{cityWatcher && (
						<MapSelect
							location={selectedLocation}
							onClick={() => setLocationModal(true)}
							onRemove={handleRemoveLocation}
							customStyle={styles.map}
						/>
					)}

					<div className={styles.form_input_wrapper}>
						<label>Compensation</label>

						<TextInput
							{...register("compensation")}
							placeholder="Amount"
							type="number"
						/>
					</div>

					<div className={styles.inline_input}>
						<div className={styles.item}>
							<label>Currency</label>
							<CustomSelect
								isLoading={isLoadingCurrencies}
								options={currencies?.map((currency) => ({
									label: currency.name,
									value: currency.uuid,
								}))}
								placeholder="Select"
								height="35px"
								isClearable={true}
								onChange={(currency) =>
									setValue("currency", currency)
								}
								value={currencyWatcher}
							/>
						</div>
						<div className={styles.item}>
							<label>Payment Frequency</label>
							<CustomSelect
								options={frequencyTypes}
								placeholder="Select"
								height="35px"
								isClearable={true}
								onChange={(frequency) =>
									setValue("frequency", frequency)
								}
								value={frequencyWatcher}
							/>
						</div>
					</div>

					<div className={styles.button_container}>
						<PrimaryButton
							className={styles.save_btn}
							text={editMode ? "save" : "create"}
							isLoading={
								createJobApi.loading ||
								updateJobApi.loading ||
								createCastingApi.loading ||
								updateCastingApi.loading ||
								createFreelanceApi.loading ||
								updateFreelanceApi.loading
							}
						/>
					</div>

					<PromptModal when={isDirty && shouldBlockSave} />

					{locationModal && (
						<GaawkModal
							show={locationModal}
							handleClose={() => setLocationModal(false)}
							defaultModal={false}
							showHeader={true}
							title={"Select Job Location"}
							closeAlign={"right"}
							children={
								<div className={styles.map_container}>
									{/* <MapItem
                                    onLocation={handleMapLocation}
                                    locationData={
                                        selectedLocation || mapLocation
                                    }
                                /> */}
									<MapItem
										onLocation={handleMapLocation}
										locationData={
											selectedLocation || mapLocation
										}
									/>

									{/* <LoadingSpinner visible={!mapLocation} /> */}

									{mapLocation && (
										<div
											className={styles.button_container}
										>
											<PrimaryButton
												className={styles.save_btn}
												text="select"
												onClick={handleSelectedLocation}
											/>
										</div>
									)}
								</div>
							}
						/>
					)}
				</form>
			)}
		</>
	);
};

export default JobEntry;
