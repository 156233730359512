import paymentApi from "api/payment";
import routes from "components/Routing/routing-keys";
import PrimaryButton from "components/Utils/Button/PrimaryButton";
import { capitalize, packageTypes } from "components/Utils/General";
import useMutate from "hooks/useMutate";
import { useNavigate } from "react-router-dom";
import styles from "./Payment.module.scss";

const Payment = ({ plan }) => {
	const navigate = useNavigate();

	const { paymentPackageType, price } = plan;

	const packageName =
		paymentPackageType === packageTypes.aaa
			? paymentPackageType
			: capitalize(paymentPackageType);

	const {
		action: { mutate: subscribe },
	} = useMutate(paymentApi.subscribe, (response) => {
		navigate(routes.invoice, { state: response.data });
	});

	return (
		<div className={styles.container}>
			<div>
				<h3>Billing Cycle</h3>
				<div className={styles.plan_container}>
					<p>{packageName} Plan</p>

					{/* //* AMOUNT IS HARDCODED FOR NOW */}
					<p>
						0 <span>AED</span>
					</p>
				</div>
				<div className={styles.charging_info}>
					<p>After 6 month free we charge {price} AED monthly</p>
				</div>

				<div className={styles.card}>
					<p>
						You'll have free subscription for 6 Months. After that
						you'll be charged {price} AED per month. Enjoy all the
						benefits from your {packageName} plan.
					</p>
					<p>
						<span>Total:</span>
						{/* //* AMOUNT IS HARDCODED FOR NOW */}
						<span>0 AED</span>
					</p>
				</div>
			</div>
			<div className={styles.button_container}>
				<PrimaryButton
					text={"CONFIRM"}
					onClick={() => subscribe(paymentPackageType)}
				/>
			</div>
		</div>
	);
};

export default Payment;
