import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useApi from "../../../hooks/useApi";
import profileApi from "../../../api/profile";
import DefaultPage from "../../Utils/PageArchs/DefaultPage/DefaultPage";
import ConnectionDetails from "./ConnectionDetails";
import { useSelector } from "react-redux";
import useCompanyId from "../../../hooks/useCompanyId";
import BackButton from "../../Utils/Button/BackButton";
import styles from "../../../views/Header.module.css";
import HomeLeftSidebar from "components/Home/Components/HomeLeftSidebar/HomeLeftSidebar";

const ConnectionPage = () => {
	const getUserByUrlApi = useApi(profileApi.getUserByUrl);
	const getUserProfileApi = useApi(profileApi.getUserProfile);
	const getCompanyProfileApi = useApi(profileApi.getCompanyProfile);

	const { userUrl } = useParams();
	const navigate = useNavigate();

	const userId = useSelector((state) => state.user.profileInfo.uuid);
	const companyId = useCompanyId();

	const { state } = useLocation();

	const [profile, setProfile] = useState(state?.profile);
	const [canEdit, setCanEdit] = useState(state?.canEdit);

	useEffect(() => {
		if (!profile) {
			if (userUrl) {
				getUserByUrlApi.request(userUrl);
			} else if (!userUrl) {
				if (companyId) {
					getCompanyProfileApi.request(companyId);
				} else {
					getUserProfileApi.request(userId);
				}
			}
		}
	}, []);

	useEffect(() => {
		if (
			!getUserByUrlApi.loading &&
			getUserByUrlApi.responseCode === 200 &&
			getUserByUrlApi.data
		) {
			const response = getUserByUrlApi.data;
			if (response.type === "USER")
				getUserProfileApi.request(response.uuid);
			else if (response.type === "COMPANY")
				getCompanyProfileApi.request(response.uuid);
		} else if (!getUserByUrlApi.loading && getUserByUrlApi.error) {
			throw new Response("", {
				statusText: getUserByUrlApi.data.message,
				status: getUserByUrlApi.data.code,
			});
		}
	}, [getUserByUrlApi.data]);

	useEffect(() => {
		if (
			!getCompanyProfileApi.loading &&
			getCompanyProfileApi.responseCode === 200 &&
			getCompanyProfileApi.data
		) {
			const response = getCompanyProfileApi.data;
			setProfile(response);
			setCanEdit(response.uuid === companyId);
		}
	}, [getCompanyProfileApi.data]);

	useEffect(() => {
		if (
			!getUserProfileApi.loading &&
			getUserProfileApi.responseCode === 200 &&
			getUserProfileApi.data
		) {
			const response = getUserProfileApi.data;
			setProfile(response);
			setCanEdit(userId === response.uuid);
		}
	}, [getUserProfileApi.data]);

	return (
		<>
			{profile && (
				<DefaultPage
					defaultHeader={false}
					forceShowLeftScroll={true}
					leftSideChildren={<HomeLeftSidebar />}
					headerRightContent={
						<div className={styles.header}>
							<BackButton onBack={() => navigate(-1)} />{" "}
							Connections
						</div>
					}
					// rightSideBgColor={"#FFFFFF"}
					rightSideContainerColor={"#FFF"}
					rightSideChildren={
						<ConnectionDetails
							profile={profile}
							canEdit={canEdit}
						/>
					}
				/>
			)}
		</>
	);
};

export default ConnectionPage;
