import Joi from "joi";

const vocalDetailsSchema = Joi.object({
	recordingType: Joi.array().min(0).label("Recording Types"),
	voiceCharacteristics: Joi.array().min(0).label("Voice Characteristics"),
	vocalAge: Joi.object({
		value: Joi.string(),
		label: Joi.string(),
	})
		.label("Vocal Age")
		.allow(null),
	pitch: Joi.object({
		value: Joi.string(),
		label: Joi.string(),
	})
		.label("Pitch")
		.allow(null),
	vocalRange: Joi.object({
		value: Joi.string(),
		label: Joi.string(),
	})
		.label("Vocal Range")
		.allow(null),
});

export default vocalDetailsSchema;
