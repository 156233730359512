import ProfilePic from "components/Home/ProfilePic";
import styles from "./ConnectionItem.module.scss";
import SecondaryButton from "components/Utils/Button/SecondaryButton";
import GaawkLink from "components/Utils/SubComs/GaawkLink/GaawkLink";
import routes from "components/Routing/routing-keys";

const ConnectionItem = ({
	data,
	onDecline,
	onAccept,
	showButtons = false,
	rightSideAction,
	customStyle,
	redirect = true,
}) => {
	const { name, tagLine, type, url, profileImage: { image } = {} } = data;

	//TODO >> change the onClick behavior on the component
	// * if the component has a right side action, should stop propagation and click on the action only

	return (
		<div className={`${styles.container} ${customStyle}`}>
			<div className={styles.details_wrapper}>
				<GaawkLink
					to={routes.profile(url)}
					onClick={(e) => !redirect && e.preventDefault()}
					customStyle={styles.overflow_hidden}
				>
					<div className={styles.profile_container}>
						<ProfilePic
							thumbnail={image}
							w={36}
							h={36}
							type={type}
							fullWidth={true}
							// url={redirect && url}
							name={name}
							subdata={tagLine}
							subdataStyle={styles.subData}
						/>
					</div>
				</GaawkLink>

				{rightSideAction && rightSideAction}
			</div>
			{showButtons && (
				<div className={styles.buttons_wrapper}>
					<SecondaryButton
						className={`${styles.button} ${styles.decline}`}
						text={"decline"}
						onClick={onDecline}
					/>
					<SecondaryButton
						className={`${styles.button} ${styles.accept}`}
						text={"accept"}
						onClick={onAccept}
					/>
				</div>
			)}
		</div>
	);
};

export default ConnectionItem;
