import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import { skillUpdated } from "../../../../store/slices/user";

import { shallowEqual } from "react-redux";
import styles from "../Roles/Role.module.css";

import useApi from "../../../../hooks/useApi";
import skillsApi from "../../../../api/skills";
import { defaultFormatter } from "../../../Utils/SubComs/Inputs/SearchableInput/response-formatter";

import MultiSelectInput from "components/Utils/SubComs/Inputs/MultiSelectInput/MultiSelectInput";
import PromptModal from "components/Utils/Prompt/PromptModal";
import useMutate from "hooks/useMutate";
import profileApi from "api/profile";
import { toast } from "react-toastify";
import PrimaryButton from "components/Utils/Button/PrimaryButton";
import { successfulChangeToast } from "components/Utils/General";
import { skillsKeys } from "queryKeys/skills-key-factory";

const skillsLimit = 10;
const itemsPerPage = 20;

const Index = () => {
	const getSkills = useApi(skillsApi.searchSkills, true, true);

	const dispatch = useDispatch();

	const { skills } = useSelector((state) => state.user.profileInfo);

	const [mySkills, setMySkills] = useState(() =>
		skills.map((skill) => ({
			label: skill.skillDTO.name,
			value: skill.skillDTO.uuid,
		}))
	);

	const prevSkillsList = useRef(mySkills).current;

	const [unsavedChanges, setUnsavedChanges] = useState(false);
	const [shouldBlockSave, setShouldBlockSave] = useState(true);

	const fetchSkills = async ({ pageParam = 0, signal, queryKey }) => {
		const [_, searchInput] = queryKey;

		const response = await getSkills.request(
			pageParam,
			itemsPerPage,
			searchInput
		);
		return response.data;
	};

	const handleRemoveSkill = (skillIndex) => {
		setMySkills((prevState) =>
			prevState.filter((_, index) => index !== skillIndex)
		);
	};

	const handleSelectedSkills = (skill) => {
		setMySkills((prevState) => [...prevState, skill]);
	};

	const {
		action: { mutate: updateSkills, isLoading },
	} = useMutate(profileApi.updateSkills, (response) => {
		dispatch(skillUpdated(response.data));
		successfulChangeToast();
		setShouldBlockSave(false);
	});

	const handleSave = () => {
		const skillsToSave = mySkills.map((skill) => skill.value);
		updateSkills(skillsToSave);
	};

	useEffect(() => {
		if (!shallowEqual(mySkills, prevSkillsList)) {
			setUnsavedChanges(true);
		} else {
			setUnsavedChanges(false);
		}
	}, [mySkills]);

	return (
		<>
			<div className={styles.container}>
				<MultiSelectInput
					creatable={true}
					queryName={skillsKeys.search}
					queryFn={fetchSkills}
					data={mySkills}
					itemsPerPage={itemsPerPage}
					formatter={defaultFormatter}
					label="Skills"
					limit={skillsLimit}
					onChange={handleSelectedSkills}
					onRemoveItem={handleRemoveSkill}
					infoText={`You can add up to ${skillsLimit} skills which outline your core
                    skill sets.`}
				/>

				<div className={styles.button_wrapper}>
					<PrimaryButton
						onClick={handleSave}
						isLoading={isLoading}
						type="button"
						text={"save"}
						width={130}
					/>
				</div>
			</div>

			<PromptModal when={unsavedChanges && shouldBlockSave} />
		</>
	);
};

export default Index;
