import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./ConnectionTab.module.css";
import useApi from "../../../hooks/useApi";
import profileApi from "../../../api/profile";
import GroupMember from "../../Chat/GroupMember";
import ContactLoader from "../../Utils/SubComs/CustomLoader/ContactLoader";
import favoriteIcon from "images/connection-icons/favorite.svg";
import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";
import ProfileTabContainer from "components/Utils/SubComs/ProfileTab/ProfileTabContainer";
import { pluralize } from "components/Utils/General";

const Favorites = ({ profile, canEdit, connectionCount }) => {
	const getFavoritesApi = useApi(profileApi.getFavorites);
	const [favorites, setFavorites] = useState([]);
	const { connection } = profile;
	const navigate = useNavigate();

	const { userUrl } = useParams();

	const fetchFavorites = () => {
		getFavoritesApi.request(profile.uuid, 0, 6);
	};

	useEffect(() => {
		// if (profile.uuid) {
		fetchFavorites();
		// }
	}, [profile.uuid, connection?.favorite]);

	useEffect(() => {
		if (
			!getFavoritesApi.loading &&
			getFavoritesApi.responseCode === 200 &&
			getFavoritesApi.data &&
			getFavoritesApi.data.length > 0
		) {
			setFavorites(getFavoritesApi.data);
		}
	}, [getFavoritesApi.data]);

	const handleViewAll = (action) => () => {
		sessionStorage.setItem("activeConnectionTab", action);
		navigate(
			userUrl
				? `/profile/${userUrl}/connections`
				: "/profile/connections",
			{
				state: {
					profile,
					canEdit,
				},
			}
		);
	};

	const favoritesList = favorites.map((favorite) => (
		<div className={styles.contact_item} key={favorite.uuid}>
			<GroupMember data={favorite} redirect={true} />
		</div>
	));

	if (connectionCount.favorites === 0 || !canEdit) return null;

	return (
		<ProfileTabContainer>
			<div className={styles.section_title}>
				<div>
					<img src={favoriteIcon} alt="Favorites" />
					Favorites
				</div>
				<SeeMore text="See All" onClick={handleViewAll("Favorites")} />
			</div>

			{getFavoritesApi.loading && <ContactLoader />}

			{connectionCount.favorites > 0 && !getFavoritesApi.loading && (
				<>
					<div className={styles.subtitle}>
						You have added{" "}
						<span
							onClick={handleViewAll("Favorites")}
						>{`${connectionCount.favorites}`}</span>{" "}
						{pluralize(connectionCount.favorites, "connection")} to
						your favorites.
					</div>

					<div>{favoritesList}</div>
				</>
			)}

			{/* {connectionCount.favorites === 0 && !getFavoritesApi.loading && (
                        <div className={`${styles.more_section} ${styles.counter}`}>
                            <div className={`${styles.more_info} ${styles.counter}`}>
                                You don't have any favorites connection yet
                            </div>
                        </div>
                    )} */}
		</ProfileTabContainer>
	);
};

export default Favorites;
