import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import servicesApi from "../../../../api/services";
import useMutate from "../../../../hooks/useMutate";
import { servicesKeys } from "queryKeys/services-key-factory";
import { vaultKeys } from "queryKeys/vault-key-factory";
import { profileKeys } from "queryKeys/profile-key-factory";

export const useServiceDelete = () => {
	const queryClient = useQueryClient();
	const handleSuccess = () => {
		// queryClient.invalidateQueries({ queryKey: servicesKeys.lists() });
		queryClient.invalidateQueries(servicesKeys.lists());
		queryClient.invalidateQueries(vaultKeys.storage());
		queryClient.invalidateQueries(profileKeys.details());

		toast.success("Service deleted successfully");
	};
	const {
		warningModal: serviceWarningModal,
		setWarningModal: setServiceWarningModal,
		handleRequestDelete: handleRequestServiceDelete,
		handleConfirmDelete: handleConfirmServiceDelete,
	} = useMutate(servicesApi.deleteService, handleSuccess);
	return {
		serviceWarningModal,
		setServiceWarningModal,
		handleRequestServiceDelete,
		handleConfirmServiceDelete,
	};
};
