import styles from "./GeneralDetails.module.css";

const GeneralDetails = ({ data }) => {
	const { bio, accents, ethnicity, languages, nationalities } = data;

	return (
		<div className={styles.general_details_container}>
			{bio && <div className={styles.bio}>{bio}</div>}
			{(accents.length > 0 ||
				ethnicity ||
				languages.length > 0 ||
				nationalities.length > 0) && (
				<div className={styles.subsection_wrapper}>
					{bio && <h3>General Details</h3>}
					{ethnicity && (
						<div className={styles.subsection}>
							<h4>Ethnicity</h4>
							{ethnicity.name}
						</div>
					)}
					{nationalities.length > 0 && (
						<div className={styles.subsection}>
							<h4>Nationalities</h4>
							{nationalities
								.map(
									(nationality) => nationality.countryDTO.name
								)
								.join(", ")}
						</div>
					)}
					{languages.length > 0 && (
						<div className={styles.subsection}>
							<h4>Languages Spoken</h4>
							{languages
								.map((language) => language.name)
								.join(", ")}
						</div>
					)}
					{accents.length > 0 && (
						<div className={styles.subsection}>
							<h4>Dialects Spoken / Accents</h4>
							{accents.map((accent) => accent.name).join(", ")}
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default GeneralDetails;
