import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import VisibilitySelector from "../../../../Utils/Visibility/VisibilitySelector";

import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";

import { addAddress, updateAddress } from "../../../../../store/slices/user";
import {
	addCompanyAddress,
	updateCompanyAddress,
} from "../../../../../store/slices/company";

import styles from "./Address.module.css";
import PrimaryButton from "../../../../Utils/Button/PrimaryButton";
import TextInput from "../../../../Utils/SubComs/Inputs/TextInput/TextInput";

import { locationFormatter } from "../../../../Utils/SubComs/Inputs/SearchableInput/response-formatter";

import GaawkModal from "../../../../Utils/GaawkModal/GaawkModal";
import MapItem from "./MapItem";

import addressSchema from "./address-schema";
import useFetchLocation from "../../../../../hooks/useFetchLocation";
import InfiniteSearchInput from "../../../../Utils/SubComs/Inputs/InfiniteSearchInput/InfiniteSearchInput";
import MapSelect from "../../../../Utils/MapSelect/MapSelect";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import PromptModal from "components/Utils/Prompt/PromptModal";
import { locationKeys } from "queryKeys/location-key-factory";

const itemsPerPage = 20;

const AddressEntry = ({ editCompany: companyId }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { pathname, state } = useLocation();

	const editMode = pathname === "/profile/address/edit";

	const {
		uuid: addressUuid,
		building,
		label,
		locationDTO: {
			latitude: lat,
			longitude: lng,
			name: cityName,
			countryDTO: { name: countryName } = {},
			id: locationId,
		} = {},
		street,
		url,
		visibility: currentVisibility,
		zipCode: zip,
	} = state || {};

	// !==== map handlers =====

	const [locationModal, setLocationModal] = useState(false);
	const [mapLocation, setMapLocation] = useState("");
	const [selectedLocation, setSelectedLocation] = useState(() =>
		state
			? {
					address: "",
					building,
					city: cityName,
					country: countryName,
					street,
					lat,
					lng,
					zipCode: zip,
			  }
			: ""
	);

	const handleMapLocation = (location) => {
		setMapLocation(location);
	};

	const handleSelectedLocation = () => {
		setSelectedLocation(mapLocation);

		setLocationModal(false);

		setValue("building", mapLocation.building);

		setValue("street", mapLocation.street);

		setValue("zip", mapLocation.zipCode);
	};

	const handleRemoveLocation = () => {
		setSelectedLocation("");
	};

	// !=======================

	const {
		register,
		setValue,
		formState: { errors, isDirty },
		handleSubmit,
		control,
	} = useForm({
		resolver: joiResolver(addressSchema),
		mode: "onSubmit",
		defaultValues: editMode
			? {
					label,
					city: {
						//   id: locationId,
						value: locationId,
						label: `${cityName}, ${countryName}`,
						//   countryCode: country.code,
					},
					building,
					street,
					zip,
					url,
			  }
			: {
					label: "",
					city: null,
					building: "",
					street: "",
					zip: "",
					url: "",
			  },
	});

	const [visibility, setVisibility] = useState(
		currentVisibility ?? "WORK_CIRCLE_FRIENDS"
	);

	const [shouldBlockSave, setShouldBlockSave] = useState(true);

	const handleVisibilityChange = (value) => () => {
		setVisibility(value);
	};

	const handleSave = (data) => {
		const {
			label,
			building,
			// city: { value: cityName, countryCode: countryId },
			city: { value: locationId },
			street,
			url,
			zip: zipCode,
		} = data;

		const body = {
			...(editMode && { addressUuid }),
			label,
			building,
			// cityName,
			// countryId,
			locationId,
			street,
			url,
			zipCode,
			...(selectedLocation &&
				selectedLocation.lat !== 0 &&
				selectedLocation.lng !== 0 && {
					latitude: selectedLocation.lat,
					longitude: selectedLocation.lng,
				}),
			visibility,
		};

		if (companyId) {
			dispatch(
				editMode
					? updateCompanyAddress(body, companyId)
					: addCompanyAddress(body, companyId)
			);
		} else {
			dispatch(editMode ? updateAddress(body) : addAddress(body));
		}

		setShouldBlockSave(false);
	};

	useEffect(() => {
		if (!shouldBlockSave) navigate("/profile/address");
	}, [navigate, shouldBlockSave]);

	const fetchLocation = useFetchLocation(true, itemsPerPage);

	if (editMode && !state) {
		return <Navigate to="/profile/address" />;
	}

	return (
		<>
			<form
				className={styles.add_form}
				onSubmit={handleSubmit((data) => handleSave(data))}
				noValidate
			>
				<div className={styles.form_input_wrapper}>
					<label>
						Label
						<span className="required">*</span>
					</label>

					<TextInput
						{...register("label")}
						error={!!errors.label}
						placeholder="Label"
					/>
					{errors?.label?.message && (
						<p className={styles.error_message}>
							{errors?.label?.message}
						</p>
					)}
				</div>

				<InfiniteSearchInput
					queryName={locationKeys.cities}
					queryFn={fetchLocation}
					itemsPerPage={itemsPerPage}
					formatter={locationFormatter}
					label={"City & Country"}
					placeholder={"Search for a city"}
					required={true}
					error={errors?.city}
					control={control}
					name="city"
				/>

				<MapSelect
					location={selectedLocation}
					onClick={() => setLocationModal(true)}
					onRemove={handleRemoveLocation}
					customStyle={styles.map}
				/>

				<div className={styles.form_input_wrapper}>
					<label>Building</label>
					<TextInput
						{...register("building")}
						placeholder="Building"
					/>
				</div>
				<div id={styles.street_wrapper}>
					<div id={styles.street}>
						<label>Street</label>
						<TextInput
							{...register("street")}
							placeholder="Street"
						/>
					</div>
					<div id={styles.zip}>
						<label>Zip Code</label>
						<TextInput
							{...register("zip")}
							placeholder="Zip code"
						/>
					</div>
				</div>
				<div className={styles.form_input_wrapper}>
					<label>Address Url</label>
					<TextInput {...register("url")} placeholder="Address Url" />
				</div>
				<div className={styles.visibility_wrapper}>
					<label className={styles.visibility_label}>
						Visibility
						<span className="required">*</span>
					</label>

					<VisibilitySelector
						value={visibility}
						onValueChange={handleVisibilityChange}
					/>
				</div>
				<div className={styles.button_container}>
					<PrimaryButton className={styles.save_btn} text={"save"} />
				</div>
			</form>

			<GaawkModal
				show={locationModal}
				handleClose={() => setLocationModal(false)}
				defaultModal={false}
				showHeader={true}
				title={"Select location on map"}
				closeAlign={"right"}
				children={
					<div className={styles.modal_map_container}>
						<MapItem
							onLocation={handleMapLocation}
							locationData={selectedLocation || mapLocation}
						/>

						{mapLocation && (
							<div className={styles.button_container}>
								<PrimaryButton
									className={styles.save_btn}
									text="select"
									onClick={handleSelectedLocation}
								/>
							</div>
						)}
					</div>
				}
			/>

			<PromptModal when={isDirty && shouldBlockSave} />
		</>
	);
};

export default AddressEntry;
