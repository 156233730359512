import React, { useState, useEffect } from "react";
import styles from "./CommentReactionsCounter.module.css";
import Reactions from "./Reactions";
// import Comment from "images/comment-black.svg";
import { getDiffIn } from "../General";
import ReactionsCounter from "./ReactionsCounter";
import ReactionsModal from "./ReactionsModal";

const CommentReactionsCounter = ({
	item,
	onReply,
	onReact,
	onClick,
	isFullscreenPost,
}) => {
	const {
		stats,
		repliesCount,
		profileReactionType,
		commentTime,
		replyTime,
		commentId,
		uuid,
	} = item;

	const [reactionTab, setReactionTab] = useState("All");
	const [showReactionsModal, setShowReactionsModal] = useState(false);
	const [showReactions, setShowReactions] = useState(false);
	const [delayHandler, setDelayHandler] = useState(null);

	const handleBtnEnter = () => {
		setDelayHandler(
			setTimeout(() => {
				setShowReactions(true);
			}, 500)
		);
	};

	const handleBtnLeave = () => {
		setDelayHandler(
			setTimeout(() => {
				setShowReactions(false);
			}, 700)
		);
	};

	useEffect(() => {
		return () => {
			clearTimeout(delayHandler);
		};
	}, [delayHandler]);

	return (
		<div className={styles.container}>
			{/* {stats.stats.length > 0 && <Reaction reactionStats={stats} sm={true} />} */}

			{/* {stats.totalCount > 0 && <label>{stats.totalCount}</label>} */}

			<div className={styles.left_side}>
				<div
					onMouseEnter={handleBtnEnter}
					onMouseLeave={handleBtnLeave}
				>
					{showReactions && (
						<div
							className={`${styles.reactions_container} ${
								commentId ? styles.reply : undefined
							}`}
						>
							<Reactions
								onSelect={(reactionType) => {
									onReact(reactionType);
									setShowReactions(false);
								}}
								isFullscreenPost={isFullscreenPost}
							/>
						</div>
					)}

					<button
						onClick={() => {
							onClick();
							setShowReactions(false);
						}}
						className={`${styles.react_btn} ${
							profileReactionType ? styles.active : undefined
						}`}
					>
						{profileReactionType ?? "React"}
					</button>
				</div>

				<span className={styles.seperator}>.</span>

				{/* {repliesCount > 0 && <img className={styles.reply_icon} src={Comment} alt={"replies"} />} */}

				<button onClick={onReply} className={styles.reply_btn}>
					{repliesCount > 0
						? repliesCount === 1
							? `1 reply`
							: `${repliesCount} replies`
						: `Reply`}
				</button>

				<span className={styles.seperator}>.</span>

				{/* <span className={styles.time}>{getDiffIn(commentTime)}</span> */}
				{(commentTime || replyTime) && (
					<span className={styles.time_container}>
						{getDiffIn(commentTime || replyTime)}
					</span>
				)}
			</div>

			<ReactionsCounter
				reactionStats={stats}
				counterOnly={true}
				onShowReactions={() => setShowReactionsModal(true)}
				onClick={(reaction) => setReactionTab(reaction)}

				// onShowReactions={() => setShowReactionsModal(true)}
				// onShowComment={() => setShowingComments(!showingComments)}
			/>

			<ReactionsModal
				show={showReactionsModal}
				itemId={uuid}
				type={commentId ? "reply" : "comment"}
				onClose={() => setShowReactionsModal(false)}
				title={`${commentId ? "Reply" : "Comment"} reactions`}
				activeTab={reactionTab}
				reactionStats={stats}
			/>
		</div>
	);
};

export default CommentReactionsCounter;
