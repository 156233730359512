import ProfilePic from "components/Home/ProfilePic";
import CustomCheckbox from "../Inputs/CustomCheckbox/CustomCheckbox";
import styles from "./ProfileSwitchItem.module.scss";
import { pluralize } from "components/Utils/General";

const ProfileSwitchItem = ({ data, isActive, onClick, fontColorWhite }) => {
	const { messageCount, notificationCount } = data;

	const messageDTO = `${messageCount} ${pluralize(messageCount, "Message")}`;

	const notificationDTO = `${notificationCount} ${pluralize(
		notificationCount,
		"Notification"
	)}`;

	let subData = "";

	switch (true) {
		case messageCount > 0 && notificationCount > 0:
			subData = `${messageDTO}, ${notificationDTO}`;
			break;

		case messageCount > 0 && notificationCount === 0:
			subData = messageDTO;
			break;

		case messageCount === 0 && notificationCount > 0:
			subData = notificationDTO;
			break;

		default:
			subData = "";
			break;
	}

	return (
		<button className={styles.company_item} onClick={onClick}>
			<ProfilePic
				type={data.type}
				fontColorWhite={fontColorWhite}
				w={24}
				h={24}
				name={data.name || `${data.firstName} ${data.lastName}`}
				thumbnail={
					data?.profileImage?.image ||
					data?.profileImage?.originalImageURL
				}
				subdata={subData}
				fullWidth={true}
				letterSize={14}
			/>

			<CustomCheckbox
				isCircle={true}
				checked={isActive}
				readOnly={true}
				customBackgroundColor={"gaawk"}
				marginRight="0"
			/>
		</button>
	);
};

export default ProfileSwitchItem;
