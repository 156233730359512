import useApi from "./useApi";
import { useQuery } from "@tanstack/react-query";
import specialProfileApi from "api/special-profile";
import { specialProfileKeys } from "queryKeys/specialProfile-key-factory";

const useSpecialProfiles = (userId) => {
	const getSpecialProfilesApi = useApi(
		specialProfileApi.getAllSpecial,
		true,
		true
	);

	const fetchSpecialProfiles = async ({ queryKey }) => {
		const [_, __, userId] = queryKey;

		const response = await getSpecialProfilesApi.request(userId);
		return response.data;
	};

	const query = useQuery({
		queryKey: specialProfileKeys.lists(userId),
		queryFn: fetchSpecialProfiles,
		enabled: !!userId,
	});

	return query;
};

export default useSpecialProfiles;
