import StorageCard from "components/Storage/StorageComponents/StorageCard";
import CtaButton from "components/Utils/Button/CtaButton";
import {
	getStorageWarningText,
	pluralize,
	showStorageWarning,
} from "components/Utils/General";
import NoResults from "components/Utils/SubComs/NoResults/NoResults";
import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";
import useVaultStorage from "hooks/useVaultStorage";
import useWindowSize from "hooks/useWindowSize";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { setModalProduct } from "store/slices/products";
import SearchInput from "../../Utils/SubComs/Inputs/SearchInput/SearchInput";
import LoadingSpinner from "../../Utils/SubComs/LoadingSpinner/LoadingSpinner";
import ServicesTree from "../ServicesTab/ServicesComponent/ServicesTree";
import {
	useGetUncategoirzedProducts,
	useProductsSearch,
	useProductsStats,
} from "./hooks";
import { useGetServicesProduct } from "./hooks/useGetServicesProduct";
import ProductEntry from "./ProductEntry/ProductEntry";
import { AllProductsComponent } from "./ProductsComponents/AllProducts";
import { ProductItems } from "./ProductsComponents/ProductItems";
import { UncategorisedProductsComponent } from "./ProductsComponents/UncategorizedProducts";
import styles from "./ProductsTab.module.scss";

const ProductsTab = ({ userInfo, canEdit }) => {
	const dispatch = useDispatch();

	const { data, usedSpaceInPercentage } = useVaultStorage();

	const {
		usedProductsCount,
		currentPlan: { products: productsAdCount } = {},
	} = data;

	const { width } = useWindowSize();
	const isMobile = width < 748;

	const {
		uuid: userId,
		hasProducts,
		firstName,
		lastName,
		name: companyName,
		type,
	} = userInfo;

	const name = type === "USER" ? `${firstName} ${lastName}` : companyName;

	const navigate = useNavigate();

	const { stats, isLoadingStats } = useProductsStats(userId);

	const { productsServicesLevel1, isFetchingLevel1, viewRefLevel1 } =
		useGetServicesProduct(userId, stats?.total > 0 ? true : false);

	const {
		isFetchingUncategorized,
		uncategorizedProducts,
		// isInitialLoadingUncategorized,
	} = useGetUncategoirzedProducts(
		userId,
		stats?.uncategorizedCount > 0 ? true : false
	);

	const {
		_handleClearSearch,
		handleSearchInputChange,
		isFetching: isFetchingSearch,
		products,
		isStale,
		ref,
		searchInput,
	} = useProductsSearch(
		{
			ownerIds: [userId],
		},
		true
	);

	const isSearching = searchInput.length > 0;

	return (
		<div className={styles.product_tab_container}>
			{canEdit && hasProducts && (
				<div className={styles.btn_wrapper}>
					<CtaButton
						text={"+ add product"}
						onClick={() => dispatch(setModalProduct(true))}
						className={styles.custom_btn}
						disabled={usedProductsCount >= productsAdCount}
					/>
				</div>
			)}

			{canEdit && showStorageWarning(usedSpaceInPercentage, data) && (
				<div className={styles.warning_wrapper}>
					<StorageCard
						text={getStorageWarningText(
							"productsAndServices",
							usedSpaceInPercentage,
							data
						)}
					/>
				</div>
			)}

			{hasProducts && (
				<div className={styles.search_container}>
					<SearchInput
						onChange={handleSearchInputChange}
						value={searchInput}
						showIcons={true}
						classNameInput={styles.search_input}
						onClearClicked={_handleClearSearch}
						border={false}
						placeholder={`Search Products by ${name}`}
					/>
				</div>
			)}

			{isMobile && (
				<div style={{ padding: "20px" }}>
					<ServicesTree
						showServicesWithProducts={true}
						userId={userId}
						enabled={true}
					/>
				</div>
			)}

			{hasProducts &&
				!isSearching &&
				productsServicesLevel1?.pages[0].length > 0 && (
					<div className={styles.products_container}>
						<ProductItems
							ref={viewRefLevel1}
							productsServicesLevel1={productsServicesLevel1}
						/>
						<LoadingSpinner visible={isFetchingLevel1} />
					</div>
				)}
			{hasProducts && stats?.uncategorizedCount > 0 && !searchInput && (
				<div className={styles.products_container}>
					<UncategorisedProductsComponent
						userUrl={userInfo?.url}
						UncategorizedProducts={uncategorizedProducts}
						visible={searchInput.length === 0}
					/>
					<LoadingSpinner visible={isFetchingUncategorized} />
				</div>
			)}

			{/* //* USED TO DISPLAY THE SEARCHED COMPONENT  */}
			<AllProductsComponent
				visible={isSearching && hasProducts}
				products={products}
				ref={ref}
				canEdit={canEdit}
				userUrl={userInfo?.url}
				searchInput={searchInput}
				isLoading={isFetchingSearch}
				isStale={isStale}
			/>

			<NoResults
				visible={
					!hasProducts &&
					!isFetchingLevel1 &&
					!isFetchingUncategorized &&
					!isLoadingStats
				}
				text="Feel free to drop your products right here!"
				button={
					canEdit && (
						<CtaButton
							text={"+ Add Product"}
							onClick={() => dispatch(setModalProduct(true))}
						/>
					)
				}
			/>

			<ProductEntry />

			{hasProducts && !isSearching && (
				<div className={styles.button_wrapper}>
					<SeeMore
						text={`View All ${stats?.total} ${pluralize(
							stats?.total,
							"Product"
						)}`}
						onClick={() => {
							navigate(`/profile/${userInfo?.url}/products`, {
								state: {
									filters: {
										onlyUncategorizedProducts: false,
									},
								},
							});
						}}
					/>
				</div>
			)}
		</div>
	);
};

export default ProductsTab;
