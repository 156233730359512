import GaawkModal from "components/Utils/GaawkModal/GaawkModal";
import SearchInput from "components/Utils/SubComs/Inputs/SearchInput/SearchInput";
import LoadingSpinner from "components/Utils/SubComs/LoadingSpinner/LoadingSpinner";
import useDebounce from "hooks/useDebounce";
import usePaginatedResource from "hooks/usePaginatedResource";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useInView } from "react-intersection-observer";
import styles from "./UserListModal.module.scss";
import { classNames } from "primereact/utils";

const itemsPerPage = 20;

const UserListModal = ({
	show,
	onClose,
	title,
	fetchUsers,
	renderUser,
	queryKey,
	hasSearch = false,
}) => {
	const [searchInput, setSearchInput] = useState("");
	const debouncedQuery = useDebounce(searchInput);

	const handleSearchInputChange = ({ target }) => {
		setSearchInput(target.value);
	};

	const handleClearSearch = () => {
		setSearchInput("");
	};

	const { data, isFetching, hasNextPage, fetchNextPage } =
		usePaginatedResource(
			queryKey(debouncedQuery),
			fetchUsers,
			itemsPerPage,
			show
		);

	const { ref: viewRef, inView } = useInView({ triggerOnce: true });

	useEffect(() => {
		if (inView && hasNextPage && !isFetching) {
			fetchNextPage();
		}
	}, [inView, hasNextPage, isFetching]);

	const userList = useMemo(
		() =>
			data?.pages?.map((page) =>
				page.map((user, i) => (
					<Fragment key={user.uuid}>
						{renderUser({
							user,
							ref: page.length === i + 1 ? viewRef : null,
						})}
					</Fragment>
				))
			),
		[data, renderUser]
	);

	return (
		<GaawkModal
			show={show}
			handleClose={onClose}
			defaultModal={false}
			showHeader={true}
			title={title}
			closeAlign="right"
		>
			<div className={styles.container}>
				{hasSearch && (
					<SearchInput
						onChange={handleSearchInputChange}
						value={searchInput}
						showIcons={true}
						onClearClicked={handleClearSearch}
						border={false}
					/>
				)}

				<div
					className={classNames(styles.users_container, {
						[styles.no_margin_top]: !hasSearch,
					})}
				>
					<div className={styles.users_list}>{userList}</div>
					{isFetching && <LoadingSpinner />}
				</div>
			</div>
		</GaawkModal>
	);
};

export default UserListModal;
