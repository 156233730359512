import "react-toastify/dist/ReactToastify.css";
// import { StompSessionProvider } from "react-stomp-hooks";
import AppEntryPoint from "./AppEntryPoint";
import { PrimeReactProvider } from "primereact/api";
import "primereact/resources/themes/lara-light-cyan/theme.css";
// import useUserId from "./hooks/useUserId";

const App = () => {
	// const userId = useUserId();

	// if (userId)

	const pt = {
		inputtext: {
			root: { className: "customInput" },
		},
		inputnumber: {
			input: { root: { className: "customInput" } },
		},
		inputmask: {
			root: { className: "customInput" },
		},
	};

	return (
		// <StompSessionProvider
		// 	url={process.env.REACT_APP_SOCKET_BASE_URL}
		// 	debug={(str) => {
		// 		console.debug(
		// 			"%c WEBSOCKET DEBUGGER ",
		// 			"color: orange; font-weight: bolder;",
		// 			str
		// 		);
		// 	}}
		// 	splitLargeFrames={true}
		// 	discardWebsocketOnCommFailure={true}
		// 	maxWebSocketChunkSize={8 * 1024}
		// 	onStompError={(error) => console.error(error)}
		// 	onWebSocketError={(error) => console.log(error)}
		// 	onDisconnect={(frame) => console.log(frame)}
		// 	onConnect={() => {
		// 		console.warn(
		// 			"%c WEBSOCKET CONNECTED ",
		// 			"font-weight:bolder; color: lime; border: 2px solid lime"
		// 		);
		// 	}}
		// >
		<PrimeReactProvider value={{ pt }}>
			<AppEntryPoint />
		</PrimeReactProvider>
		// </StompSessionProvider>
	);

	// return <AppEntryPoint />;
};

export default App;
