import styles from "./Filters.module.css";
import TextInput from "../../../Utils/SubComs/Inputs/TextInput/TextInput";
import { defaultFormatter } from "../../../Utils/SubComs/Inputs/SearchableInput/response-formatter";
import useApi from "../../../../hooks/useApi";
import skillsApi from "../../../../api/skills";
import CustomSelect from "../../../Utils/SubComs/CustomSelect/CustomSelect";
import { frequencyTypes } from "../../../Utils/General";
import MultiSelectInput from "components/Utils/SubComs/Inputs/MultiSelectInput/MultiSelectInput";
import useCurrencies from "hooks/useCurrencies";
import { skillsKeys } from "queryKeys/skills-key-factory";

const itemsPerPage = 20;

const CompensationFilter = ({
	onCompensationChange,
	onFrequencyChange,
	onCurrencyChange,
	// onStatusChange,
	// positionStatus,
	skills,
	onAddSkill,
	onRemoveSkill,
	compensation,
	currency,
	frequency,
}) => {
	const { data: currencies, isLoading: isLoadingCurrencies } =
		useCurrencies();

	const getSkills = useApi(skillsApi.searchSkills, true, true);

	const fetchSkills = async ({ pageParam = 0, signal, queryKey }) => {
		const [_, searchInput] = queryKey;

		const response = await getSkills.request(
			pageParam,
			itemsPerPage,
			searchInput
		);
		return response.data;
	};

	return (
		<div className={styles.container}>
			<MultiSelectInput
				queryName={skillsKeys.search}
				queryFn={fetchSkills}
				data={skills}
				itemsPerPage={itemsPerPage}
				formatter={defaultFormatter}
				label="Skills"
				onChange={onAddSkill}
				onRemoveItem={onRemoveSkill}
			/>

			<div className={styles.form_input_wrapper}>
				<label>Compensation</label>
				<TextInput
					onChange={onCompensationChange}
					value={compensation}
					type="number"
					placeholder="Minimum"
				/>
			</div>

			<div className={`${styles.section} ${styles.inline_input}`}>
				<div className={styles.item}>
					<label>Currency</label>
					<CustomSelect
						isLoading={isLoadingCurrencies}
						options={currencies?.map((currency) => ({
							label: currency.name,
							value: currency.uuid,
						}))}
						placeholder="Select"
						height="35px"
						isClearable={true}
						onChange={onCurrencyChange}
						value={currency}
					/>
				</div>
				<div className={styles.item}>
					<label>Payment Frequency</label>
					<CustomSelect
						options={frequencyTypes}
						placeholder="Select"
						height="35px"
						isClearable={true}
						onChange={onFrequencyChange}
						value={frequency}
					/>
				</div>
			</div>

			{/* <div className={styles.section}>
                <label>Status</label>
                <div className={styles.switch_wrapper}>
                    <Switch
                        checked={positionStatus}
                        onColor="#6cc5d1"
                        offColor="#a6b1bc"
                        handleDiameter={18}
                        width={39}
                        height={24}
                        checkedIcon={false}
                        uncheckedIcon={false}
                        boxShadow={"none"}
                        activeBoxShadow={"none"}
                        onChange={onStatusChange}
                    />
                    <span>Show closed jobs</span>
                </div>
            </div> */}
		</div>
	);
};

export default CompensationFilter;
