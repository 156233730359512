import styles from "./ConnectionStatus.module.scss";
import { ReactComponent as WorkBlackIcon } from "images/connection-icons/work.svg";
import { ReactComponent as FriendIcon } from "images/connection-icons/friends.svg";

const ConnectionStatus = ({ friendStatus, inMyWorkCircle }) => {
	return (
		<div className={styles.container}>
			<FriendIcon
				className={styles.statusIcon}
				style={
					friendStatus === "APPROVED" ? undefined : { opacity: "0.2" }
				}
			/>

			<WorkBlackIcon
				className={styles.statusIcon}
				style={
					inMyWorkCircle
						? undefined
						: {
								opacity: "0.2",
						  }
				}
			/>
		</div>
	);
};

export default ConnectionStatus;
