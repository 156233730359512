import { useEffect, useMemo, useState } from "react";
import styles from "./RegistrationForm.module.scss";
import TextInput from "components/Utils/SubComs/Inputs/TextInput/TextInput";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import registrationSchema from "components/Registeration/Schema/registration-schema";
import useFetchLocation from "hooks/useFetchLocation";
import useApi from "hooks/useApi";
import profileApi from "api/profile";
import { toast } from "react-toastify";
import InfiniteSearchInput from "components/Utils/SubComs/Inputs/InfiniteSearchInput/InfiniteSearchInput";
import { locationFormatter } from "components/Utils/SubComs/Inputs/SearchableInput/response-formatter";
import CustomSelect from "components/Utils/SubComs/CustomSelect/CustomSelect";
import PrimaryButton from "components/Utils/Button/PrimaryButton";
import InputWrapper from "components/Utils/SubComs/Inputs/InputWrapper/InputWrapper";
import { useDispatch, useSelector } from "react-redux";
import { updateStep1 } from "store/slices/registration";
import { locationKeys } from "queryKeys/location-key-factory";

const itemsPerPage = 50;

const RegistrationForm = ({ onBack, onNext }) => {
	const dispatch = useDispatch();
	const { step1 } = useSelector((state) => state.registration);

	const {
		register,
		watch,
		formState: { errors },
		handleSubmit,
		control,
		setValue,
	} = useForm({
		resolver: joiResolver(registrationSchema),
		mode: "onBlur",
		defaultValues: step1,
	});

	const watchCity = watch("city");
	const watchDay = watch("day");
	const watchMonth = watch("month");
	const watchYear = watch("year");
	const watchEmail = watch("email", "");
	const watchFirstName = watch("firstName");
	const watchLastName = watch("lastName");

	//! CITY HANDLERS -------------------------------------------------------

	const fetchLocation = useFetchLocation(true, itemsPerPage);

	//! DATES HANDLERS -------------------------------------------------------

	const range = (start, stop, step) =>
		Array.from(
			{ length: (stop - start) / step + 1 },
			(_, i) => start + i * step
		).map((item) => ({
			label: item < 10 ? `0${item.toString()}` : item.toString(),
			value: item,
		}));

	const daysRange = range(1, 31, 1);
	const monthRange = range(1, 12, 1);
	const yearsRange = range(1900, new Date().getFullYear(), 1);

	// ! EMAIL HANDLER -------------------------------------------------------

	const checkEmailApi = useApi(profileApi.checkEmail, true);
	const [emailExists, setEmailExists] = useState(false);

	const handleCheckEmail = async (email) => {
		const response = await checkEmailApi.request(email);
		if (!response.ok) toast.error("Something wrong happened");
		if (response.status === 200) setEmailExists(response.data);
	};

	useEffect(() => {
		let timer;
		if (!errors?.email && watchEmail) {
			timer = setTimeout(() => {
				handleCheckEmail(watchEmail);
			}, 500);
		} else {
			setEmailExists(false);
		}

		return () => {
			clearTimeout(timer);
		};
	}, [watchEmail, errors.email]);

	const handleNext = (data) => {
		if (data.email && !emailExists) {
			onNext({
				firstName: data.firstName,
				lastName: data.lastName,
				locationId: data.city.value,
				email: data.email,
				day: data.day.value,
				month: data.month.value,
				year: data.year.value,
			});

			dispatch(
				updateStep1({
					firstName: data.firstName,
					lastName: data.lastName,
					city: watchCity,
					email: data.email,
					day: watchDay,
					month: watchMonth,
					year: watchYear,
				})
			);
		}
	};

	const customEmailError = useMemo(() => {
		if (errors?.email) {
			return <p>{errors?.email?.message}</p>;
		} else if (emailExists && !errors?.email) {
			return <p>This email is already linked to an account</p>;
		}
	}, [emailExists, errors?.email]);

	return (
		<div className={styles.container}>
			<button className={styles.arrow_btn} type="button" onClick={onBack}>
				<img
					src={require("images/back-arrow-black.svg").default}
					alt="back"
				/>
			</button>
			<div className={styles.logo_wrapper}>
				<img
					src={
						require("images/gaawk-logos/gaawk-default-black.svg")
							.default
					}
					alt="logo"
				/>
			</div>

			<h3>Sign Up</h3>

			<form onSubmit={handleSubmit((data) => handleNext(data))}>
				<InputWrapper
					label="First Name"
					required={true}
					error={errors?.firstName}
					component={
						<TextInput
							{...register("firstName")}
							placeholder="Enter your first name"
							error={errors?.firstName}
							onBlur={() =>
								setValue("firstName", watchFirstName.trim())
							}
						/>
					}
				/>

				<InputWrapper
					label="Last Name"
					required={true}
					error={errors?.lastName}
					component={
						<TextInput
							{...register("lastName")}
							placeholder="Enter your last name"
							error={errors?.lastName}
							onBlur={() =>
								setValue("lastName", watchLastName.trim())
							}
						/>
					}
				/>

				<InputWrapper
					label="Location"
					required={true}
					error={errors?.city}
					component={
						<InfiniteSearchInput
							queryName={locationKeys.cities}
							queryFn={fetchLocation}
							itemsPerPage={itemsPerPage}
							formatter={locationFormatter}
							required={true}
							error={errors?.city}
							control={control}
							name="city"
							customStyle={styles.no_margin}
							errorStyle={styles.no_error}
						/>
					}
				/>

				<InputWrapper
					label="Email"
					required={true}
					error={errors?.email || emailExists}
					customError={customEmailError}
					component={
						<TextInput
							{...register("email")}
							placeholder="Enter your email"
							error={errors?.email || emailExists}
							onBlur={() => setValue("email", watchEmail.trim())}
						/>
					}
				/>

				{/* <div className={styles.form_input_wrapper}>
                    <label>
                        Re-enter Email
                        <span className="required">*</span>
                    </label>
                    <div className={styles.field_wrapper}>
                        <TextInput
                            {...register("confirmEmail")}
                            placeholder="Confirm your email address"
                            error={!!errors.confirmEmail}
                        />
                        {errors?.confirmEmail?.message && (
                            <p>{errors?.confirmEmail?.message}</p>
                        )}
                    </div>
                </div> */}

				<InputWrapper
					label="Date of Birth"
					required={true}
					error={errors.day || errors.month || errors.year}
					customError={"Please enter full date of birth"}
					component={
						<div className={styles.dob_wrapper}>
							<div className={styles.dob_item_wrapper}>
								<CustomSelect
									className="input-field custom-select"
									options={daysRange}
									placeholder="Day"
									fontSize={"15px"}
									control={control}
									name="day"
									error={!!errors.day}
								/>
							</div>

							<div className={styles.dob_item_wrapper}>
								<CustomSelect
									className="input-field custom-select"
									options={monthRange}
									placeholder="Month"
									fontSize={"15px"}
									control={control}
									name="month"
									error={!!errors.month}
								/>
							</div>

							<div className={styles.dob_item_wrapper}>
								<CustomSelect
									className="input-field custom-select"
									options={yearsRange}
									placeholder="Year"
									fontSize={"15px"}
									control={control}
									name="year"
									error={!!errors.year}
								/>
							</div>
						</div>
					}
				/>

				{/* <div className={styles.form_input_wrapper}>
                    <label>
                        Date of Birth<span className="required">*</span>
                    </label>
                    <div className={styles.dob_wrapper}>
                        <div className={styles.dob_item_wrapper}>
                            <CustomSelect
                                className="input-field custom-select"
                                options={daysRange}
                                placeholder="Day"
                                fontSize={"15px"}
                                control={control}
                                name="day"
                                error={!!errors.day}
                            />
                        </div>

                        <div className={styles.dob_item_wrapper}>
                            <CustomSelect
                                className="input-field custom-select"
                                options={monthRange}
                                placeholder="Month"
                                fontSize={"15px"}
                                control={control}
                                name="month"
                                error={!!errors.month}
                            />
                        </div>

                        <div className={styles.dob_item_wrapper}>
                            <CustomSelect
                                className="input-field custom-select"
                                options={yearsRange}
                                placeholder="Year"
                                fontSize={"15px"}
                                control={control}
                                name="year"
                                error={!!errors.year}
                            />
                        </div>
                    </div>
                </div> */}

				<PrimaryButton text="next" className={styles.next_btn} />

				{/* <div className={styles.signup_button_wrapper}>
                    <button type="submit" className={styles.signup_button}>
                        Start <span className="gaawk-text">gaawk</span>
                        ing...
                    </button>
                </div> */}
			</form>
		</div>
	);
};

export default RegistrationForm;
