import { useState, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import ScrollableMenu from "../ScrollableMenu";
import styles from "./ProfileHeader.module.css";
import viewIcon from "images/view-icon-20-x-20-black.svg";
import editIcon from "images/edit-icon-20-x-20-black.svg";
import Popover from "../../Utils/Popover/Popover";
import ProfilePic from "../../Home/ProfilePic";
import LazyImage from "../../Utils/LazyImage/LazyImage";
import FullScreenModal from "../../Utils/GaawkModal/FullScreenModal";
import PostFullScreen from "../../Home/PostFullScreen";
import routes from "components/Routing/routing-keys";
import ConnectionActions from "./ConnectionActions";
import { classNames } from "primereact/utils";
import Tag from "components/Utils/SubComs/Tags/Tag";

const Index = ({
	userInfo: profileInfo,
	canEdit,
	onClickEditCoverPic,
	onClickEditProfilePic,
	onClickEdit,
	onItemClicked,
}) => {
	const userInfo = profileInfo;

	const { userUrl } = useParams();
	const { pathname } = useLocation();

	const [showViewer, setShowViewer] = useState(false);
	const [postId, setPostId] = useState("");

	const {
		firstName = "",
		lastName = "",
		name: companyName = "",
		tagLine = "",
		prime,
		type,
		connection: { blocked, blocking } = {},
		deleted,
		agency,
		hasServices,
		hasProducts,
	} = userInfo;

	const thumbnail = userInfo?.profileImage?.framedImageURL
		? userInfo.profileImage.framedImageURL
		: userInfo?.profileImage?.originalImageURL
		? userInfo.profileImage.originalImageURL
		: undefined;

	const profileCoverImg = userInfo.profileCover
		? userInfo.profileCover.originalImageURL
		: undefined;

	const { name: country } = userInfo.countryDTO || "";
	const { name: city } = userInfo.cityDTO || "";

	const location = city && country ? `${city},  ${country}` : "";
	const fullName = `${firstName} ${lastName}`;

	const popoverRef = useRef(null);

	const handleClosePictureModal = () => {
		setShowViewer(false);
		handleUpdateUrl();
	};

	const handleUpdateUrl = () => {
		window.history.pushState(
			null,
			"",
			pathname === "/chat"
				? "/chat"
				: `/profile${userUrl ? `/${userUrl}` : ""}`
		);
	};

	const getProfileTabs = () => {
		const array = [];

		if (pathname === routes.chat) array.push("info");
		array.push("profile", "contacts");
		if (userInfo.agency) array.push("talent");
		if (hasServices || canEdit) array.push("services");
		if (hasProducts || canEdit) array.push("products");
		array.push("posts", "gallery", "connections");
		if (userInfo.type === "USER") array.push("work status");

		return array;
	};

	return (
		<>
			<div className={styles.container}>
				<div className={styles.cover_container}>
					<Popover
						ref={popoverRef}
						render={
							(profileCoverImg || canEdit) &&
							!blocked &&
							!blocking && (
								<div className="popover_container">
									{profileCoverImg && (
										<button
											className="popover_item"
											onClick={() => {
												setShowViewer(true);
												setPostId(
													userInfo?.profileCover
														.postId
												);
												window.history.pushState(
													null,
													"",
													`/post/${userInfo?.profileCover.postId}`
												);
												popoverRef.current.closePopover();
											}}
											tabIndex={0}
										>
											<span>
												<img
													src={viewIcon}
													alt="view"
												/>
											</span>
											<span>View cover</span>
										</button>
									)}

									{canEdit && (
										<button
											className="popover_item"
											onClick={onClickEditCoverPic}
											tabIndex={0}
										>
											<span>
												<img
													src={editIcon}
													alt="edit"
												/>
											</span>
											<span>Edit cover picture</span>
										</button>
									)}
								</div>
							)
						}
					>
						<div className={styles.cover_wrapper}>
							<LazyImage
								image={
									<img src={profileCoverImg} alt="cover" />
								}
								customWrapper={classNames({
									[styles.disabled]: !profileCoverImg,
								})}
								// placeholder={<LoadingSpinner visible={true} />} //!Not working as per git
								// onAfterLoad={() =>
								//     console.log("FINISHED LOADING")
								// } //! possible solution conditionally hiding spinner onAfterLoad
							/>
						</div>
					</Popover>

					<Popover
						ref={popoverRef}
						render={
							(thumbnail || canEdit) &&
							!blocked &&
							!blocking && (
								<div className="popover_container">
									{thumbnail && (
										<button
											className="popover_item"
											onClick={() => {
												setShowViewer(true);
												setPostId(
													userInfo?.profileImage
														.postId
												);
												window.history.pushState(
													null,
													"",
													`/post/${userInfo?.profileImage.postId}`
												);
												popoverRef.current.closePopover();
											}}
											tabIndex={0}
										>
											<span>
												<img
													src={viewIcon}
													alt="view"
												/>
											</span>
											<span>View photo</span>
										</button>
									)}
									{canEdit && (
										<button
											className="popover_item"
											onClick={onClickEditProfilePic}
											tabIndex={0}
										>
											<span>
												<img
													src={editIcon}
													alt="edit"
												/>
											</span>
											<span>Edit profile picture</span>
										</button>
									)}
								</div>
							)
						}
					>
						<div
							className={`${styles.profile_pic_wrapper} ${
								type === "COMPANY" ? styles.company : undefined
							}`}
						>
							<ProfilePic
								thumbnail={thumbnail}
								w={"100%"}
								h={"100%"}
								type={type}
								noMarginRight={true}
								isPrime={prime}
								name={
									type === "COMPANY" ? companyName : fullName
								}
								enableName={false}
								letterSize={40}
							/>
						</div>
					</Popover>
				</div>

				<div className={styles.info_container}>
					<div className={styles.info_wrapper}>
						<div className={styles.name_wrapper}>
							<p className={styles.name}>
								{type === "COMPANY" ? companyName : fullName}
							</p>
							{/* {agency && <img src={agencyBadge} alt="" />} */}
							{agency && (
								<Tag
									itemName={"Agency"}
									customStyle={styles.tag}
								/>
							)}
						</div>
						{!blocked && !blocking && (
							<>
								{tagLine && (
									<p className={styles.position}>{tagLine}</p>
								)}
								{location && (
									<p className={styles.location}>
										{location}
									</p>
								)}
							</>
						)}
					</div>

					<ConnectionActions
						canEdit={canEdit}
						onClickEdit={onClickEdit}
						userInfo={userInfo}
						pathname={pathname}
					/>
				</div>

				{!deleted && !blocked && !blocking && (
					<div className={styles.scrollableMenu_wrapper}>
						<ScrollableMenu
							items={getProfileTabs()}
							onItemClicked={onItemClicked}
						/>
					</div>
				)}
			</div>

			{deleted && (
				<div className={styles.deleted_container}>
					<h3>OOPS!</h3>
					<p>This profile has been deleted</p>
				</div>
			)}

			<FullScreenModal
				show={showViewer}
				onClose={handleClosePictureModal}
				fullWidth={true}
				children={
					<PostFullScreen
						postId={postId}
						isModal={true}
						onClose={() => setShowViewer(false)}
						onUpdateUrl={handleUpdateUrl}
					/>
				}
			/>
		</>
	);
};

export default Index;
