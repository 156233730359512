import { useState } from "react";
import PrimaryButton from "../../../../../Utils/Button/PrimaryButton";
import styles from "./AdditionalDetailsEntry.module.css";

import Joi from "joi";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import {
	defaultFormatter,
	// unduplicateIds,
} from "../../../../../Utils/SubComs/Inputs/SearchableInput/response-formatter";
// import TagContainer from "../../../../../Utils/SubComs/Tags/TagContainer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useApi from "../../../../../../hooks/useApi";
import skillsApi from "../../../../../../api/skills";
// import Tag from "../../../../../Utils/SubComs/Tags/Tag";
// import CreatableInput from "../../../../../Utils/SubComs/Inputs/CreatableInput/CreatableInput";
import specialProfileApi from "../../../../../../api/special-profile";
import MultiSelectInput from "components/Utils/SubComs/Inputs/MultiSelectInput/MultiSelectInput";
import { specialProfileKeys } from "queryKeys/specialProfile-key-factory";
import { useQueryClient } from "@tanstack/react-query";
import PromptModal from "components/Utils/Prompt/PromptModal";
import useMutate from "hooks/useMutate";
import routes from "components/Routing/routing-keys";
import { skillsKeys } from "queryKeys/skills-key-factory";

const itemsPerPage = 20;

const AdditionalDetailsEntry = ({ profile }) => {
	const navigate = useNavigate();
	const { state } = useLocation();
	// console.log("🚀 ~ state >>", state);

	const searchSkillsApi = useApi(skillsApi.searchSkills, true, true);

	const queryClient = useQueryClient();

	const fetchSkills = async ({ pageParam = 0, signal, queryKey }) => {
		const [_, searchInput] = queryKey;

		const response = await searchSkillsApi.request(
			pageParam,
			itemsPerPage,
			searchInput
		);
		return response.data;
	};

	const { userUrl } = useParams();
	const [shouldBlockSave, setShouldBlockSave] = useState(true);

	const schema = Joi.object({
		info: Joi.string().allow("").label("Additional Info"),
		skills: Joi.array().min(0).label("Skills"),
	});

	const {
		register,
		setValue,
		formState: { isDirty },
		watch,
		handleSubmit,
	} = useForm({
		resolver: joiResolver(schema),
		mode: "onSubmit",
		defaultValues: {
			info: profile.info ?? "",
			skills: profile.skills.map((skill) => ({
				label: skill.name,
				value: skill.uuid,
			})),
		},
	});

	const skillsWatcher = watch("skills");

	const handleSelectSkill = (skill) => {
		setValue("skills", [...skillsWatcher, skill], { shouldDirty: true });
	};

	const handleRemoveSkill = (skillIndex) => {
		const updatedSkills = [...skillsWatcher].filter(
			(_, index) => index !== skillIndex
		);
		setValue("skills", updatedSkills);
	};

	const {
		action: { mutate: updateDetails, isLoading },
	} = useMutate(
		specialProfileApi.updateAdditionalDetails,
		() => {
			queryClient.invalidateQueries(specialProfileKeys.detail(userUrl));
			navigate(
				routes.specialProfile(
					userUrl,
					state?.agencyId ? `?agency=${state?.agencyId}` : ""
				)
			);
		},
		undefined,
		() => setShouldBlockSave(true)
	);

	const handleSave = (data) => {
		setShouldBlockSave(false);

		const { skills, info } = data;

		const body = {
			info,
			skillIds: skills.map((skill) => skill.value),
		};

		updateDetails({ uuid: profile.uuid, body });
	};

	return (
		<form
			className={styles.additional_form}
			onSubmit={handleSubmit((data) => handleSave(data))}
			noValidate
		>
			<h3>Additional Details</h3>
			<div className={styles.section}>
				<MultiSelectInput
					creatable={true}
					queryName={skillsKeys.search}
					queryFn={fetchSkills}
					data={skillsWatcher}
					itemsPerPage={itemsPerPage}
					formatter={defaultFormatter}
					label="Skills"
					// limit={skillsLimit}
					onChange={handleSelectSkill}
					onRemoveItem={handleRemoveSkill}
					// infoText={`You can add up to ${skillsLimit} skills which outline your core
					// skill sets.`}
				/>

				{/* <CreatableInput
                    value=""
                    multiSelect={true}
                    label={"Skills"}
                    required={false}
                    api={searchSkillsApi}
                    formatter={defaultFormatter}
                    control={control}
                    name="skills"
                    cursor={"pointer"}
                    onChange={handleSelectSkill}
                    unDuplicateFn={(passedData) =>
                        unduplicateIds(
                            skillsWatcher.map((skill) => skill.value),
                            passedData
                        )
                    }
                />

                {skillsWatcher.length > 0 && (
                    <TagContainer label="Selected Skills" items={skillsList} />
                )} */}
			</div>

			<div className={styles.form_input_wrapper}>
				<label>Additional Info</label>
				<textarea {...register("info")} />
			</div>

			<div className={styles.button_container}>
				<PrimaryButton
					className={styles.save_btn}
					text={"save"}
					isLoading={isLoading}
				/>
			</div>

			<PromptModal when={isDirty && shouldBlockSave} />
		</form>
	);
};

export default AdditionalDetailsEntry;
