import React, { forwardRef } from "react";
import styles from "./button.module.css";

const SecondaryButton = forwardRef(
	(
		{
			text,
			onClick,
			className,
			type = "button",
			disabled = false,
			isLoading = false,
		},
		ref
	) => {
		return (
			<button
				ref={ref}
				className={`${className} ${styles.secondary} ${
					disabled || isLoading ? styles.disabled : undefined
				}`}
				disabled={disabled}
				onClick={onClick}
				type={type}
			>
				{text}
			</button>
		);
	}
);

export default SecondaryButton;
