import { useState, useEffect } from "react";
import { replaceItemInArray } from "../../../Utils/General";

const SIZE = 15;

//TODO >> redo using react-query

const useConnection = (api, query = "", profileId = "") => {
	const [data, setData] = useState([]);
	const [page, setPage] = useState(0);

	const fetchData = () => {
		if (profileId) api.request(profileId, page, SIZE, query);
		else api.request(page, SIZE, query);
	};

	const updateConnection = (profileId, connectionType) => {
		const updatedProfile = data.find(
			(profile) => profile.uuid === profileId
		);
		if (updatedProfile) {
			updatedProfile.connection[`${connectionType}`] =
				!updatedProfile.connection[`${connectionType}`];
			setData((prevState) =>
				replaceItemInArray(updatedProfile, prevState, "uuid")
			);
		}
	};

	const unBlockConnection = (userData) => {
		setData((prevState) => replaceItemInArray(userData, prevState, "uuid"));
	};

	const deleteItem = (profileId) => {
		setData((prevState) =>
			prevState.filter((item) => item.uuid !== profileId)
		);
	};

	useEffect(() => {
		let timer;

		if (query) {
			timer = setTimeout(() => {
				if (profileId) api.request(profileId, 0, SIZE, query);
				else api.request(0, SIZE, query);
			}, 500);
		} else {
			if (profileId) api.request(profileId, 0, SIZE);
			else api.request(0, SIZE);
		}

		return () => {
			clearTimeout(timer);
			setData([]);
		};
	}, [query]);

	useEffect(() => {
		if (
			!api.loading &&
			api.responseCode === 200 &&
			api.data &&
			Object.keys(api.data).length > 0
		) {
			if (!query) {
				if (data.length === 0) {
					setData(api.data);
				} else {
					setData((prevState) => [...prevState, ...api.data]);
				}
				setPage((prevState) => prevState + 1);
			} else {
				setData(api.data);
				setPage(0);
			}
		}
	}, [api.data]);

	return { data, fetchData, deleteItem, updateConnection, unBlockConnection };
};

export default useConnection;
