import Storage from "components/Storage/Storage";
import DefaultFullPage from "components/Utils/PageArchs/DefaultFullPage/DefaultFullPage";

const StorageView = () => {
	return (
		<DefaultFullPage
			content={<Storage />}
			showBackButton={true}
			backLabel={"Manage Storage"}
		/>
	);
};

export default StorageView;
