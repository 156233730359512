import heic2any from "heic2any";

export default async function convertHEICToImageURL(input) {
	let blob;

	if (input instanceof File) {
		// If input is a File object
		if (input.type !== "image/heic" && input.type !== "image/heif") {
			throw new Error("The provided file is not a HEIC/HEIF image.");
		}
		blob = input;
	} else if (typeof input === "string") {
		// If input is a URL string
		try {
			const response = await fetch(input);
			if (!response.ok) throw new Error("Network response was not ok");
			blob = await response.blob();

			// Note: We're not checking the blob type here as the Content-Type might not be set correctly for HEIC files
		} catch (error) {
			console.error("Error fetching the image:", error);
			throw error;
		}
	} else {
		throw new Error("Input must be either a File object or a URL string.");
	}

	try {
		const convertedBlob = await heic2any({
			blob: blob,
			toType: "image/jpeg",
			quality: 0.8,
		});

		return URL.createObjectURL(convertedBlob);
	} catch (error) {
		console.error("Error converting HEIC image:", error);
		throw error;
	}
}
